/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp, mdiCheck,mdiAlert } from '@mdi/js';
import GBButton from '../../components/GBButton/GBButton';
import GBConfirm from '../../components/GBConfirm/GBConfirm';
import {getZoneMetrics} from '../../api/zone';
import { getBlockMetrics } from '../../api/zone';
import { confirmAlert } from 'react-confirm-alert';
import {plans} from './plans';

const PlanInformation = ({ zoneInfo, updateZonePlan, toggleShowInfo, billingDate }) => {

  /* 1-15-2021 We need to verify the plan they are selecting is allowed based on their current
  plan and whether the new plan would exceed any new plan metrics.  Items to check
  1. Storage space of new plan versus current usage.
  2. Number of new plan blocks versus current blocks total
  3. Number of new plan rows per block versus current rows per block.
  4. Number of new allowed phone numbers versus current numbers in use.

  */
    const checkPlanRequirements = async (newplan) => {
      
      // current metrics.
      let isValidPlan = true;
      const selectedPlan = plans.filter(itm=>itm.type===newplan)[0];

      const data= await getZoneMetrics(zoneInfo.zoneid,zoneInfo.plan)
      const blockdata = await getBlockMetrics();

      // Check that max records in a block don't exceed new plan limits.
      blockdata.forEach(el => {
        if(el.rows > selectedPlan.records){
          isValidPlan=false;
        }
      })

      // check they don't have more blocks than is allowed in new plan.
      if(parseInt(data.blockCount) > selectedPlan.blocks && selectedPlan.blocks !==-1)
      {
        isValidPlan=false;
      }

      // Check that the amoutn of data used so far does not exceed new plan limits.
      if(data.dataSize > selectedPlan.data) {
        isValidPlan=false;
      }

      // Check that they aren't useing more Twilio phone numbers than is allowed by new plan.
      if(zoneInfo.attributes.PhoneNumbers && zoneInfo.attributes.PhoneNumbers.length > selectedPlan.phonenumbers) {
        isValidPlan=false;
      }


      if(!isValidPlan) {
       PlanMismatch()
      } else {
        updateZonePlan(newplan)
      }
    }
    const PlanMismatch = (newplan) => {

      const message=<div>This Zone exceeds the feature/limits set by the {newplan} plan. <p></p>
      <p>To downgrade to this plan you must first ensure the Zone is under the limits specified
        for the plan you wish to change to.</p><p/>
      <p>Instead, if you wish to stop payment by deactivating the Zone, this can be done by
        navigating to the My Profile page. In the Zone information section, choose Deactivate for the
      desired Zone in the list.</p></div>

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <GBConfirm
              title="Plan selection mismatch"
              action={onClose}
              showCancelButton={false}
              buttonLabel="OK"
              message={message}
              showInput={false}
              height="350px"
              width-="400px"
            />
          );
        },
      });
  }


  return (
    <div style={{ overflow: 'auto', height: 'calc(100vh - 180px)' }}>
      <div
        style={{
          marginLeft: '20px',
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        <div style={{ width: '100px' }}></div>
        <div
          style={{
            width: '255px',
            height: '255px',
            minWidth: '255px',
            backgroundColor: '#F1EFEF',
            border: '1px solid #FFBE24',
            borderRadius: '10px',
          }}
        >
          <div
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              marginLeft: '20px',
              marginTop: '20px',
              height: '20px',
            }}
          >
            $0
          </div>
          <div style={{ marginLeft: '20px', marginTop: '10px', fontSize: '11px', height: '30px' }}>
            Free
          </div>

          <hr style={{ border: '1px solid #000000', width: '85%' }} />

          <div
            style={{
              fontWeight: 'bold',
              marginLeft: '20px',
              fontSize: '20px',
              height: '20px',
              marginBottom: '10px',
            }}
          >
            Free
          </div>
          <div style={{ marginLeft: '20px', marginRight: '20px', height: '6s0px' }}>
            Anyone can build GraceBlocks, you can get started for free. It might be all you need!
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: '7px',
            }}
          >
            <GBButton
              Action={checkPlanRequirements}
              ActionValue="Free"
              text={zoneInfo.plan !== 'Free' ? 'Select' : 'Current Plan'}
              color={zoneInfo.plan !== 'Free' ? '#AE007B' : '#828282'}
              borderRadius="15px"
            />
          </div>
        </div>
        <div
          style={{
            width: '255px',
            minWidth: '255px',
            height: '255px',
            backgroundColor: '#F1EFEF',
            border: '1px solid #FFBE24',
            borderRadius: '10px',
          }}
        >
          <div
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              marginLeft: '20px',
              marginTop: '20px',
              height: '20px',
            }}
          >
            $14.99 user/month
          </div>
          <div style={{ marginLeft: '20px', marginTop: '10px', fontSize: '11px', height: '30px' }}>
            $14.99 per user per month if billed monthly, or{' '}
            <p>$11.99 per user per month if billed annually.</p>
          </div>
          <hr style={{ border: '1px solid #000000', width: '85%' }} />
          <div
            style={{
              fontWeight: 'bold',
              marginLeft: '20px',
              fontSize: '20px',
              height: '20px',
              marginBottom: '10px',
            }}
          >
            Starter
          </div>
          <div style={{ marginLeft: '20px', marginRight: '20px', height: '35px' }}>
            Increase your limits and unleash your potential.
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: '25px',
            }}
          >
            <GBButton
              text={zoneInfo.plan !== 'Starter' ? 'Select' : 'Current Plan'}
              Action={checkPlanRequirements}
              ActionValue="Starter"
              color={zoneInfo.plan !== 'Starter' ? '#AE007B' : '#828282'}
              borderRadius="15px"
            />
          </div>
          {zoneInfo.plan === 'Starter' ? (
            <div
              style={{
                marginLeft: '10px',
                marginTop: '5px',
                marginRight: '10px',
                fontSize: '10px',
              }}
            >
              <i>Your {zoneInfo.stripeInfo.interval} plan will auto-renew on {billingDate}</i>
            </div>
          ) : null}
        </div>
        <div
          style={{
            width: '255px',
            minWidth: '255px',
            height: '255px',
            backgroundColor: '#F1EFEF',
            border: '1px solid #FFBE24',
            borderRadius: '10px',
          }}
        >
          <div
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              marginLeft: '20px',
              marginTop: '20px',
              height: '20px',
            }}
          >
            $24.99 user/month
          </div>
          <div style={{ marginLeft: '20px', marginTop: '10px', fontSize: '11px', height: '30px' }}>
            $24.99 per user per month if billed monthly, or{' '}
            <p>$20.99 per user per month if billed annually.</p>
          </div>
          <hr style={{ border: '1px solid #000000', width: '85%' }} />
          <div
            style={{
              fontWeight: 'bold',
              marginLeft: '20px',
              fontSize: '20px',
              height: '20px',
              marginBottom: '10px',
            }}
          >
            Pro
          </div>
          <div style={{ marginLeft: '20px', marginRight: '20px', height: '35px' }}>
            Build systems you can rely on with unprecedented speed.
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: '25px',
            }}
          >
            <GBButton
              Action={checkPlanRequirements}
              ActionValue="Pro"
              text={zoneInfo.plan !== 'Pro' ? 'Select' : 'Current Plan'}
              color={zoneInfo.plan !== 'Pro' ? '#AE007B' : '#828282'}
              borderRadius="15px"
            />
          </div>

          {zoneInfo.plan === 'Pro' ? (
            <div
              style={{
                marginLeft: '10px',
                marginTop: '5px',
                marginRight: '10px',
                fontSize: '10px',
              }}
            >
              <i>Your {zoneInfo.stripeInfo.interval} plan will auto-renew on {billingDate}</i>
            </div>
          ) : null}
        </div>
        <div
          style={{
            width: '255px',
            minWidth: '255px',
            height: '255px',
            backgroundColor: '#F1EFEF',
            border: '1px solid #FFBE24',
            borderRadius: '10px',
          }}
        >
          <div
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              marginLeft: '20px',
              marginTop: '20px',
              height: '20px',
            }}
          >
            ...
          </div>
          <div style={{ marginLeft: '20px', marginTop: '10px', fontSize: '11px', height: '30px' }}>
            Let's talk
          </div>
          <hr style={{ border: '1px solid #000000', width: '85%' }} />
          <div
            style={{
              fontWeight: 'bold',
              marginLeft: '20px',
              fontSize: '20px',
              height: '20px',
              marginBottom: '10px',
            }}
          >
            Enterprise
          </div>
          <div style={{ marginLeft: '20px', marginRight: '20px', height: '35px' }}>
            Have enterprise needs? Let us know a bit more and we'll be in touch!
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: '25px',
            }}
          >
            <GBButton
              text="Contact us"
              color="#AE007B"
              borderRadius="15px"
              Action={() =>
                window.open('https://www.graceblocks.com/graceblocks-enterprise-inquiry-form')
              }
            />
          </div>
        </div>
      </div>

      <div
        style={{
          marginLeft: '20px',
          marginTop: '15px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >

      </div>

      <div
        style={{
          marginLeft: '20px',
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          marginRight:'20px'
        }}
      >
        <div style={{ width: '125px', height: '20px' }}>Blocks</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          10
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          20
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          100
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          Unlimited
        </div>
      </div>

     

      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
         
          border:'1px solid #F5E5C3',
          justifyContent: 'space-evenly',
          padding:'5px',
          borderRadius:'7px',
          backgroundColor:'#F1EFEF',
          marginRight:'20px'
          
        }}
      >
        <div style={{ width: '125px', height: '20px' }}>Data storage</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          2 GB
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          5 GB
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          20 GB
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          TBD
        </div>
      </div>

      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          borderRadius:'7px',
          marginRight:'20px'
        }}
      >
        <div style={{ width: '125px', height: '20px',whiteSpace:'nowrap' }}>Workflow actions/month</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          100
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          25,000
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
         250,000
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          TBD
        </div>
      </div>

      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          marginRight:'20px',
          backgroundColor:'#F1EFEF',
          border:'1px solid #F5E5C3',
        }}
      >
        <div style={{ width: '125px', height: '20px',whiteSpace:'nowrap' }}>Text Messaging Numbers*</div>
        <div style={{ display: 'flex', justifyContent: 'center', whiteSpace:'nowrap', height: '20px', width: '255px' }}>
          1 month (US & Canada Only)
        </div>
        <div style={{ display: 'flex', whiteSpace:'nowrap', justifyContent: 'center', height: '20px', width: '255px' }}>
          More in 20+ countries can be purchased
        </div>
        <div style={{ display: 'flex', whiteSpace:'nowrap', justifyContent: 'center', height: '20px', width: '255px' }}>
          More in 20+ countries can be purchased
        </div>
        <div style={{ display: 'flex', whiteSpace:'nowrap', justifyContent: 'center', height: '20px', width: '255px' }}>
          More in 20+ countries can be purchased
        </div>
      </div>

      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          borderRadius:'7px',
          marginRight:'20px',
        }}
      >
        <div style={{ width: '125px', height: '20px', whiteSpace: 'nowrap' }}>Text Messaging**</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
        1 month (US & Canada Only)
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
      </div>


      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          marginRight:'20px',
          backgroundColor:'#F1EFEF',
          border:'1px solid #F5E5C3',
        }}
      >
        <div style={{ width: '125px', height: '20px', whiteSpace: 'nowrap' }}>Email messages**</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
      </div>

      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          borderRadius:'7px',
          marginRight:'20px',
        }}
      >
        <div style={{ width: '125px', height: '20px', whiteSpace: 'nowrap' }}>
          Records per Block
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          1,000
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          10,000
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          100,000
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          TBD
        </div>
      </div>

      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          marginRight:'20px',
          backgroundColor:'#F1EFEF',
          border:'1px solid #F5E5C3',
        }}
      >
        <div style={{ width: '125px', height: '20px', whiteSpace: 'nowrap' }}>Tabs per Block</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          10
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          20
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          30
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          TBD
        </div>
      </div>

      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          borderRadius:'7px',
          marginRight:'20px',
        }}
      >
        <div style={{ width: '125px', height: '20px', whiteSpace: 'nowrap' }}>Active users</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          Unlimited
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          Licensed
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          Licensed
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          Licensed
        </div>
      </div>

      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          marginRight:'20px',
          backgroundColor:'#F1EFEF',
          border:'1px solid #F5E5C3',
        }}
      >
        <div style={{ width: '125px', height: '20px', whiteSpace: 'nowrap' }}>Spreadsheet view</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
      </div>

      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          borderRadius:'7px',
          marginRight:'20px',
        }}
      >
        <div style={{ width: '125px', height: '20px', whiteSpace: 'nowrap' }}>Messaging view</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
      </div>

      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          marginRight:'20px',
          backgroundColor:'#F1EFEF',
          border:'1px solid #F5E5C3',
        }}
      >
        <div style={{ width: '125px', height: '20px', whiteSpace: 'nowrap' }}>Collaboration support</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
      </div>

      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          borderRadius:'7px',
          marginRight:'20px',
        }}
      >
        <div style={{ width: '125px', height: '20px', whiteSpace: 'nowrap' }}>Bookmarks</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
      </div>

      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          marginRight:'20px',
          backgroundColor:'#F1EFEF',
          border:'1px solid #F5E5C3',
        }}
      >
        <div style={{ width: '125px', height: '20px', whiteSpace: 'nowrap' }}>Web Forms</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
      </div>

      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          borderRadius:'7px',
          marginRight:'20px',
        }}
      >
        <div style={{ width: '125px', height: '20px' }}>Core field types</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
      </div>
      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          marginRight:'20px',
          backgroundColor:'#F1EFEF',
          border:'1px solid #F5E5C3',
        }}
      >
        <div style={{ width: '125px', height: '20px' }}>RTF field type</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
        &nbsp;
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
      </div>
      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          borderRadius:'7px',
          marginRight:'20px',
        }}
      >
        <div style={{ width: '125px', height: '20px',whiteSpace:'nowrap' }}>Masked text field type</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
        &nbsp;
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
        &nbsp;
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
      </div>
      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          marginRight:'20px',
          backgroundColor:'#F1EFEF',
          border:'1px solid #F5E5C3',
        }}
      >
        <div style={{ width: '125px', height: '20px', whiteSpace: 'nowrap' }}>Private fields</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
         
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
        
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
      </div>
      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          borderRadius:'7px',
          marginRight:'20px',
        }}
      >
        <div style={{ width: '125px', height: '20px', whiteSpace: 'nowrap' }}>API access</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
      </div>
      <div
         style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          marginRight:'20px',
          backgroundColor:'#F1EFEF',
          border:'1px solid #F5E5C3',
        }}
      >
        <div style={{ width: '125px', height: '20px', whiteSpace: 'nowrap' }}>History tracking on tab records</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          2 weeks
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
         6 months
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          3 years
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
        3+ years
        </div>
      </div>

      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          borderRadius:'7px',
          marginRight:'20px',
        }}
      >
        <div style={{ width: '125px', height: '20px' }}>Record sharing***</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
         Yes, but with limits
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
      </div>

      {/* <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px'
        }}
      >
        <div style={{ width: '125px', height: '20px', whiteSpace: 'nowrap' }}>Record sharing</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
        <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
        <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
        <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
        <Icon path={mdiCheck} size="20px" />
        </div>
      </div> */}

      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          marginRight:'20px',
          backgroundColor:'#F1EFEF',
          border:'1px solid #F5E5C3',
        }}
      >
        <div style={{ width: '125px', height: '20px', whiteSpace: 'nowrap' }}>Notifications</div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
         @ message only
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
         All options included
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
        All options included
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
        All options included
        </div>
      </div>      

      <div
       style={{
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        padding:'5px',
        borderRadius:'7px',
        marginRight:'20px',
      }}
      >
        <div style={{ width: '125px', height: '20px',whiteSpace:'nowrap' }}>Cross-Block relational fields</div>
        <div
          style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}
        ></div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
      </div>

      <div
         style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          marginRight:'20px',
          backgroundColor:'#F1EFEF',
          border:'1px solid #F5E5C3',
        }}
      >
        <div style={{ width: '125px', height: '20px' }}>
          Custom colors 
        </div>
        <div
          style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}
        ></div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
      </div>

      <div
       style={{
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        padding:'5px',
        borderRadius:'7px',
        marginRight:'20px',
      }}
      >
        <div style={{ width: '125px', height: '20px' }}>
       Data security for tabs
        </div>
        <div
          style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}
        ></div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          {/* <Icon path={mdiCheck} size="20px" /> */}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
      </div>
      <div
         style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding:'5px',
          marginRight:'20px',
          backgroundColor:'#F1EFEF',
          border:'1px solid #F5E5C3',
        }}
      >
        <div style={{ width: '125px', height: '20px' }}>
       Nested field support
        </div>
        <div
          style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}
        ></div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '20px', width: '255px' }}>
          <Icon path={mdiCheck} size="20px" />
        </div>
      </div>

      <div
          style={{
            display: 'flex',
            flexDirection:'column',
            // border: '1px solid #757575',
            borderRadius:'7px',
            justifyContent: 'center',
            padding:'5px',
            // height: '20px',
            // width: '100%',
            marginTop:'20px',
            marginRight: '50px',
            marginLeft: '50px',
          }}
        >
       <div> *The free plan only grants access to a single auto-procured phone number for one month if the user who creates the
        Zone validates their phone number during account creation using a US or Canadian phone number. Numbers in other countries must be purchased along with a GraceBlocks paid plan before text messaging and the $2 complementary funds can be accessed. Click here to <a href="https://www.graceblocks.com/messaging-pricing" target="_blank">learn more</a> about messaging pricing and rates across the 20+ countries GraceBlocks supports for text messaging.</div>
       <div style={{marginTop:'10px'}}>
       **GraceBlocks provides messaging funds of $2 to allow sending/receiving of initial text and email messages during this one-month period. Once depleted, to continue sending messages, more funds must be purchased in addition to upgrading to a GraceBlocks paid plan. 
        </div>
        <div style={{marginTop:'10px'}}>
        ***The free plan only has limited sharing settings. It does not offer the ability to: set the security level of the share, allow recipients to add collaboration notes, add records from the share, or set a password on the share. 
        </div>
        </div>
        

    </div>
  );
};

export default PlanInformation;
