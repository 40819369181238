/* eslint-disable */
import React,{Component} from 'react';
import ActionButton from '../ActionBar/ActionButton';
import Messages from '../MessageView/Messages';
import EmailMessages from '../MessageView/EmailMessages';
import { AccountContext } from '../../../../providers/AccountProvider';
import { mdiTransitConnectionVariant,mdiChatProcessing,mdiAccountMultiple,mdiHistory,mdiPaperclip,mdiEmail,mdiRefresh } from '@mdi/js';
import { updateRelationalRecords } from '../../../../api/GetData';
import { GetRelatedTablesInfo } from '../../../../api/tables';
import {addrelationalvalue} from '../../../../api/crud';
import {saveAttachments} from '../../../../api/images'
import DataList from '../DataList/DataList';
import AttachmentViewer from '../Attachments/AttachmentViewer';
import AddAttachmentsContainer from '../Attachments/AddAttachmentsContainer'
import CollaboratorMessages from '../MessageView/CollaboratorMessages';
import History from './History';
import TextButton from '../../../../components/TextButton/TextButton';
import Global from '../../../../clients/global'

class ActionPanel extends Component {

    static contextType = AccountContext;

    /* 12-30-2020 In order to change UI dynamcially for attachments, we need to maintain/update state in this
        Container. DataList is different because it maintains state internaly for better/worse.
        So upon component load/update, I will copy dataItem prop into this object in state and use it to 
        control attachments. As they are added, removed, it will update DB, but update state locally.
    */

    state = {
        isLoading:false,
        items: [],
        itemSelected: 0,
        displayMode: 'review',
        relationalItems: [],
        control:'datalist',
        zoneid:0,
        height:'800px',
        expandRelatedRecord: false,
        hasAttachments:false,
        attachmentTabs:[],
        attachmentField:'',
        attachmentDisplayMode:'review',
        attachmentData:{},
        lastUpdate:'',
        secondaryInitialSize:0,
        layout:'horizontal',
        isPublicZoneBlock:false,
        currentNestedFields:undefined,
        messageControl:null,  //used to determine whether to show text messages or email message control
        toAddress:null, // added 6-15-2023 we'll check if field has emaildlievery defined, if yes, get email from selectedRow.
        phoneNumber:null //added 9-13-23 to support ability to use lookup phone number fields. As such, we want to get that value when record is loaded so we can pass into messages component.
    }

//     update=()=> {
//     // test item
//     const newItem={id:55,name:'bob',school:'umass'}
//     this.props.updateSelectedItem(newItem)
//    }

   async componentDidMount () {
    const {dataItem,isRoot,secondaryInitialSize,userTabSettings,shareControl,layout,role}=this.props;
    const { userInfo} = this.context;
    const { REACT_APP_PUBLIC_ZONE_ID} = process.env;
 
    //6-15-23 check of table has email field setup for email delivery. If yes, get the current
    //to address from row, which is needed to pass to EmailMessages component. 
    let emailValue=null;
    let msgControl=null;
    let phoneValue=null;

    const emailIdx = dataItem.tableinfo.columns.findIndex(col=>col.uitype===10 && col.enableEmailMessages);
    const textIdx = dataItem.tableinfo.columns.findIndex(col=>col.uitype===12);
    if(emailIdx !==-1) {
        emailValue = dataItem.selectedRow[dataItem.tableinfo.columns[emailIdx].data];
    }

    if(textIdx !==-1) {
        const col = dataItem.tableinfo.columns[textIdx];
        let lookupNumber =dataItem.selectedRow[col.phonefield] ?? dataItem.selectedRow[col.altPhoneField] 
        if(Array.isArray(lookupNumber)){
            phoneValue = lookupNumber[0].data;
        } else {
            phoneValue = lookupNumber;
        }
    }

    if(emailIdx !==-1) {
        if(dataItem.selectedRow[dataItem.tableinfo.columns[emailIdx].data] !==null) {
            emailValue = dataItem.selectedRow[dataItem.tableinfo.columns[emailIdx].data];
        } else if(dataItem.tableinfo.columns[emailIdx].alternateEmailField !==undefined) {
            emailValue = dataItem.selectedRow[dataItem.tableinfo.columns[emailIdx].alternateEmailField];
            if(typeof emailValue ==='object' && emailValue !==null) {
                emailValue =emailValue[0].data;
            }
        }
    }


    if(userInfo.defaultMessageControl==='email' && emailIdx !==-1) {
        msgControl='email'
    } else if(userInfo.defaultMessageControl==='text' && textIdx !==-1) {
        msgControl='text'
    } else if(emailIdx !==-1){
        msgControl='email'
    } else if(textIdx !==-1) {
        msgControl='text'
    }
    
   
    const currentZoneRole = userInfo.zones.filter(z=>z.id===parseInt(Global.zoneid))[0].role;
    let isPublicBlock=false;
    if(parseInt(Global.zoneid) === parseInt(REACT_APP_PUBLIC_ZONE_ID) && currentZoneRole.toLowerCase() ==='zone user') {
        isPublicBlock=true;
    }

    //3-29-23 Need to verify if field isPrivate and if so, only show attachment tab if user has access to at least one attachment field.
    const canViewPrivate = dataItem.tableinfo.security ==undefined || dataItem.tableinfo.security.viewPrivate ==undefined || dataItem.tableinfo.security.viewPrivate <= role;
    const attachmentIndex = dataItem.tableinfo.columns.findIndex(el=>((el.uitype===4 || el.source?.lookupuitype===4) && (el.isPrivate===undefined || canViewPrivate)) )
    const hasAttachments = attachmentIndex !==-1;

    //data returns array of all relational tables with the following fields
    const data = await this.fetchData()
   
    let tempControl='';
    if(hasAttachments){
        tempControl='attachments'
    } else if(data.length>0) {
        tempControl='datalist'
    } else if(dataItem.hasMessages) {
        tempControl='messages'
    } else {
        tempControl='collaborators'
    }

    if(shareControl !==undefined && shareControl !=='details') {
        tempControl=shareControl
    } 

    if(attachmentIndex !== -1)
    {
        this.BuildAttachmentTabs()
    }

    // 12-2-2021 Add logic to make relational tab "sticky"
    let tmpindex=0
    if(userTabSettings.relationalDefaultTab !==undefined && isRoot){
       const idx = data.findIndex(el=>el.tableid==userTabSettings.relationalDefaultTab);
       if(idx !==-1) tmpindex=idx;
    }
    

    if(userTabSettings.detailDefaultControl !==undefined && isRoot){
        tempControl= userTabSettings.detailDefaultControl;
    }

    /* 8-1-23 for non-root records, we save a separate setting thst keeps track of
    // which default control. It's a single setting for all non-root records. We 
    // need to check if the saved control exists in the related record .ie, they may click into
    one related record that has attachments and this is what is saved, then navigate back and click
    on another related record that does not have attachments. In that case, we should not update
    the control. We would need to check if messages, attachments, and relateed records. 
    */
    if(!isRoot && userTabSettings.nonRootDefaultControl !==undefined) {
        if(userTabSettings.nonRootDefaultControl==='datalist' && data.length>0) {
            tempControl='datalist'
        } else if(userTabSettings.nonRootDefaultControl==='messages' && dataItem.hasMessages) {
            tempControl='messages'
        } else if(userTabSettings.nonRootDefaultControl==='attachments' && hasAttachments) {
            tempControl='attachments'
        } else if (userTabSettings.nonRootDefaultControl==='collaborators' || userTabSettings.nonRootDefaultControl==='history' ) {
            tempControl=userTabSettings.nonRootDefaultControl;
        }
    }

    //8-4-23 Adding support for nonRootRelationalTab sticky. If they drill into related record
    //and then select an associated relational table, we'll record it. Here we check it and load index appropriartely.
    
    if(userTabSettings.nonRootRelationalDefaultTab !==undefined && !isRoot){
        const idx = data.findIndex(el=>el.tableid==userTabSettings.nonRootRelationalDefaultTab);
        if(idx !==-1) tmpindex=idx;
     }

    let nfields=null;
    if(data.length>0) {
        nfields = dataItem.tableinfo.columns.filter(el=>el.data===data[tmpindex].field)[0].source.nestedFields;
    }

    this.setState({secondaryInitialSize:secondaryInitialSize,layout,attachmentData: dataItem, zoneid:parseInt(Global.zoneid),items: data,itemSelected:tmpindex,height: `${window.innerHeight-340}px`, hasAttachments:hasAttachments,control: tempControl,isPublicZoneBlock:isPublicBlock,currentNestedFields:nfields,toAddress:emailValue,messageControl:msgControl,phoneNumber:phoneValue })
   }

    async componentDidUpdate(prevProps) { 
    if(this.props.layout !==prevProps.layout || this.props.secondaryInitialSize !==prevProps.secondaryInitialSize ) {
        this.setState({layout:this.props.layout,secondaryInitialSize:this.props.secondaryInitialSize})
    }

    if(this.props.index !== prevProps.index) {
        const{dataItem,isRoot,userTabSettings,shareControl,role,selectedRow} = this.props; 
         //6-20-23 Need to change toAddress when record is changed. 
        const emailIdx = dataItem.tableinfo.columns.findIndex(col=>col.uitype===10 && col.enableEmailMessages);
        const textIdx = dataItem.tableinfo.columns.findIndex(col=>col.uitype===12);

        let emailValue=null;
        let phoneValue=null;

        if(emailIdx !==-1) {
            if(dataItem.selectedRow[dataItem.tableinfo.columns[emailIdx].data] !==null) {
                emailValue = dataItem.selectedRow[dataItem.tableinfo.columns[emailIdx].data];
            } else if(dataItem.tableinfo.columns[emailIdx].alternateEmailField !==undefined) {
                emailValue = dataItem.selectedRow[dataItem.tableinfo.columns[emailIdx].alternateEmailField];
                if(typeof emailValue ==='object' && emailValue !==null) {
                    emailValue=emailValue[0].data;
                }
            }
        }

        if(textIdx !==-1) {
            const col = dataItem.tableinfo.columns[textIdx];
            const lookupNumber =dataItem.selectedRow[col.phonefield] ?? dataItem.selectedRow[col.altPhoneField] 
                if(Array.isArray(lookupNumber)){
                    phoneValue = lookupNumber[0].data;
                } else {
                    phoneValue = lookupNumber;
                }
        }
        
        const data = await this.fetchData();
        this.setState({toAddress:emailValue,phoneNumber:phoneValue,items:data})
    }

    //|| this.props.dataItem !== prevProps.dataItem || this.props.shareControl !==prevProps.shareControl
    if (this.props.id !== prevProps.id || this.props.shareControl !==prevProps.shareControl) {
      const{dataItem,isRoot,userTabSettings,shareControl,role,selectedRow} = this.props;  // 2-17-2021 added "isRoot" param so that I can determine when to refresh table rows. It should only refresh grid if we are on root record.
      // 12-31-2020 Different records have different props in regards to whether they have attachments, messages
      // or related records. As a user navigates into records, we need to check if the current control exists
      // for this new record. This is done separately in render() method below to determine if the control should render
      // as updating the "control" state in this update does not get reflected for some reason.

      // this logic determines which control should be set based on new dataItem props.
      let tempControl='';
      const {control} = this.state;

      //3-29-23 Need to verify if field isPrivate and if so, only show attachment tab if user has access to at least one attachment field.
      const canViewPrivate = dataItem.tableinfo.security ==undefined || dataItem.tableinfo.security.viewPrivate ==undefined || dataItem.tableinfo.security.viewPrivate <= role;
      const attachmentIndex = dataItem.tableinfo.columns.findIndex(el=>((el.uitype===4 || el.source?.lookupuitype===4) && (el.isPrivate===undefined || canViewPrivate)) )
      const hasAttachments = attachmentIndex !==-1;
        
      this.setState({isLoading:true,attachmentField:''})
      const data= await  this.fetchData();

      if(control==='messages' && !dataItem.hasMessages && hasAttachments){
          tempControl='attachments'
      } else if (control==='messages' && !dataItem.hasMessages && !hasAttachments) {
          tempControl='datalist';
      } else if (control==='attachments' && !hasAttachments) {
          tempControl='datalist';
      } else {
          tempControl=control; 
      }

        if(shareControl !==undefined && shareControl !=='details') {
            tempControl=shareControl
        } 
        else if (userTabSettings.detailDefaultControl !==undefined && isRoot)
        {
            tempControl= userTabSettings.detailDefaultControl;
        }

    
      if(attachmentIndex !== -1)
      {
        this.BuildAttachmentTabs()
      }

    // 12-2-2021 Add logic to make relational tab "sticky"
    let tmpindex=0
    if(userTabSettings.relationalDefaultTab !==undefined && isRoot){
        const idx = data.findIndex(el=>el.tableid==userTabSettings.relationalDefaultTab);
        if(idx !==-1) tmpindex=idx;
    }
   
    //6-20-23 Need to change toAddress when record is changed. 
    const emailIdx = dataItem.tableinfo.columns.findIndex(col=>col.uitype===10 && col.enableEmailMessages);
    const textIdx = dataItem.tableinfo.columns.findIndex(col=>col.uitype===12);

    let emailValue=null;
    let phoneValue=null;

    if(emailIdx !==-1) {
        if(dataItem.selectedRow[dataItem.tableinfo.columns[emailIdx].data] !==null) {
            emailValue = dataItem.selectedRow[dataItem.tableinfo.columns[emailIdx].data];
        } else if(dataItem.tableinfo.columns[emailIdx].alternateEmailField !==undefined) {
            emailValue = dataItem.selectedRow[dataItem.tableinfo.columns[emailIdx].alternateEmailField];
            if(typeof emailValue ==='object' && emailValue !==null) {
                emailValue=emailValue[0].data;
            }
        }
    }

    if(textIdx !==-1) {
        const col = dataItem.tableinfo.columns[textIdx];
        const lookupNumber =dataItem.selectedRow[col.phonefield] ?? dataItem.selectedRow[col.altPhoneField] 
            if(Array.isArray(lookupNumber)){
                phoneValue = lookupNumber[0].data;
            } else {
                phoneValue = lookupNumber;
            }
    }
    
      this.setState({attachmentData: this.props.dataItem,items: data,isLoading:false,itemSelected:tmpindex,hasAttachments:attachmentIndex !== -1,control:tempControl,toAddress:emailValue,phoneNumber:phoneValue})
    }
  }


  changeRelatedTable = async (index) => {
      const {userTabSettings,updateSettingsFromDetailView,isRoot,dataItem} = this.props;
      const {items}=this.state;

     
      if(isRoot){
        userTabSettings.relationalDefaultTab=items[index].tableid;
        await updateSettingsFromDetailView(userTabSettings);
      }

      //8-5-23 adding sticky support for related tabs of loaded relational record.
      if(!isRoot) {
        userTabSettings.nonRootRelationalDefaultTab=items[index].tableid;
      }

      const field = items[index].field
      this.setState({itemSelected: index,displayMode:'review'})
  }
  
  changeAttachmentTab =(field) =>{
      this.setState({attachmentField: field,attachmentDisplayMode:'review'})
  }

  changeControl = async (control) => {
    const {userTabSettings,updateSettingsFromDetailView,isRoot} = this.props;
    if(isRoot){
        userTabSettings.detailDefaultControl=control;
        await updateSettingsFromDetailView(userTabSettings);
    } else if(!isRoot) {
        userTabSettings.nonRootDefaultControl=control;
    }
      this.setState({control})
  }

  RemoveItem = async (id,mode,field)  => {

    const {relationalItems,items,itemSelected,zoneid,displayMode} = this.state;

    // const idx = items.findIndex(el=>el.id===id);
    // if(idx!==-1) {
    //     console.log(items[idx]);
    // }

    const {dataItem,setUpdateGrid,RefreshRow,SelectedRowIndex,isRoot,itemIndex,updateSelectedRow,updateLoadedRecord,hasMessages} = this.props;
    const mainItem = items[itemSelected];
    let tempRelItems=[...relationalItems]

    if(items[itemSelected].lookup ==='multiple' && mode=='addition')
    {
        tempRelItems= tempRelItems.filter(itm=>itm.id !==id)
        this.setState({relationalItems:tempRelItems})
    } else {
        // await updateRelationalRecords(dataItem.tableid,mainItem.jointable,mainItem.tableid,null,dataItem.selectedRow.id,id,mainItem,mainItem.field,'delete')
        const item=[{id:id,data:field,type:-1}];
        await addrelationalvalue(dataItem.selectedRow.id,`tbl_${dataItem.tableid}`,mainItem.field,item,'',`tbl_${mainItem.tableid}`,mainItem.jointable,mainItem.name,mainItem.returnfield,this.props.dataItem.tableinfo)

        const tempItems = [...items];
        tempItems[itemSelected].count = tempItems[itemSelected].count -1;

        setUpdateGrid(true)
        if(isRoot && RefreshRow !==null){
            const updatedRow = await RefreshRow(dataItem.selectedRow.id,'id',SelectedRowIndex)
            await updateSelectedRow(itemIndex,updatedRow)
        } else {
            await updateLoadedRecord(dataItem,itemIndex)
        }

        this.setState({items:tempItems})
    }
  }

  AddItem = async (id, field) => {
    
    const {relationalItems,items,itemSelected} = this.state;
    const tempitems=[...relationalItems]

    //only dealing with multiple in actionpanel. 
    if(items[itemSelected].lookup ==='multiple') {
       
        tempitems.push({id:parseInt(id),data:field,type:0})
        this.setState({relationalItems:tempitems})
    } 
     
  }

  ApplyChanges = async () => {
   
    const {dataItem,setUpdateGrid,RefreshRow,SelectedRowIndex,isRoot,updateSelectedRow,updateLoadedRecord,itemIndex} = this.props;
    const {items,itemSelected,relationalItems} = this.state;
    const mainItem = items[itemSelected];

    const tempItems = [...items];
    tempItems[itemSelected].count = parseInt(tempItems[itemSelected].count) + parseInt(relationalItems.length)

    // await updateRelationalRecords(dataItem.tableid,mainItem.jointable,mainItem.tableid,relationalItems,dataItem.selectedRow.id,null,mainItem,mainItem.field,'insert')
    await addrelationalvalue(dataItem.selectedRow.id,`tbl_${dataItem.tableid}`,mainItem.field,relationalItems,'',`tbl_${mainItem.tableid}`,mainItem.jointable,mainItem.name,mainItem.returnfield,this.props.dataItem.tableinfo);


    this.setState({relationalItems:[], items:tempItems})
    if(isRoot && RefreshRow !==null) {
        const updatedRow = await RefreshRow(dataItem.selectedRow.id,'id',SelectedRowIndex)
        updateSelectedRow(itemIndex,updatedRow)
    } else {
        await updateLoadedRecord(dataItem,itemIndex)
    }

    // setUpdateGrid(true)
   
  }

  ChangeDisplayMode = () => {
      this.setState({displayMode:'review'})
  }

  ChangeMessageMode =(control)=> {
    const { userInfo } = this.context;
    this.setState({messageControl:control})
    userInfo.defaultMessageControl = control;
  }

  BuildAttachmentTabs = () => {
      const {dataItem,role} = this.props;
      const {attachmentField}=this.state;
     
      let attachmentTabs =[];
      //3-29-23 Adding the logic to check if attachment field is marked as Private and if so, if security for viewing is set and user role is greater than or equal to setting.
      const canViewPrivate = dataItem.tableinfo.security ==undefined || dataItem.tableinfo.security.viewPrivate ==undefined || dataItem.tableinfo.security.viewPrivate <= role;
       
      dataItem.tableinfo.columns.filter(el=>(el.uitype===4 && (el.isPrivate===undefined || canViewPrivate)) || (el.source?.lookupuitype===4 && (el.isPrivate===undefined || canViewPrivate))).map(itm => {
        let count=0;
        if(dataItem.selectedRow[itm.data] !==null && dataItem.selectedRow[itm.data] !==undefined)  {
            count = dataItem.selectedRow[itm.data].filter(att=>att.optionid !==null).length
        }
          attachmentTabs.push({header:itm.header,field:itm.data,count:count})

      })

      this.setState({attachmentTabs:attachmentTabs,attachmentField:attachmentField==='' ? attachmentTabs[0].field :attachmentField })
  }

  UpdateExpandRelatedRecord =(isExpand) => {
    //   const {expandRelatedRecord} = this.state;
      this.setState({expandRelatedRecord:isExpand})
  }

  switchAttachmentMode = () => {
      const {attachmentDisplayMode} = this.state;
      this.setState({attachmentDisplayMode: attachmentDisplayMode==='review' ? 'addition' : 'review'})
  }
  
  updateAttachment = async (url,category,name) => {
    const {RefreshRow,SelectedRowIndex,dataItem} = this.props;
    const {attachmentField,attachmentData,attachmentTabs} = this.state;
    const { userInfo } = this.context;

    let currentCategory={
        optionid: '1',
        option: 'Uncategorized',
        attributes: { color: '#757575', text: '#FFFFFF' },
      }
  
      if(category !==undefined) {
        currentCategory=category;
      }

    // const idx = selectedItems.findIndex(itm=>itm.url===url)
    const items = attachmentData.selectedRow[attachmentField]

    const idx = items.findIndex(itm=>itm.url===url)
    if(idx !== -1) {
        items[idx].filename=name;
        items[idx].optionid=currentCategory.optionid;
        items[idx].data=currentCategory.option;
        items[idx].attributes=currentCategory.attributes;

        const table = `tbl_${attachmentData.tableid}`;
        attachmentData.selectedRow[attachmentField] = items;
        await saveAttachments(attachmentData.id,table,attachmentField,items,userInfo.id,dataItem.tableinfo)
        await RefreshRow(attachmentData.id,'id',SelectedRowIndex)
        this.setState({attachmentDisplayMode:'review',attachmentData:attachmentData,lastUpdate:Date.now()})
    }
  }

  removeAttachment = async (url) => {
    
    const {RefreshRow,SelectedRowIndex,isRoot,dataItem} = this.props;
    const {attachmentField,attachmentData,attachmentTabs} = this.state;
    const { userInfo } = this.context;
    
    const items = [...attachmentData.selectedRow[attachmentField].filter(itm=>itm.optionid !==null)]
    
    const idx = items.findIndex((el) => el.url.toLowerCase() === url.toLowerCase());
    if(idx !== -1) {
        items.splice(idx,1)
    }

    const table = `tbl_${attachmentData.tableid}`;
    attachmentData.selectedRow[attachmentField] = items;
    await saveAttachments(attachmentData.id,table,attachmentField,items,userInfo.id,dataItem.tableinfo)
    
    if(isRoot) {
        //5-23-24 commented out for same reason as addattachment. seems it gets updated by default
        //this was causing issue.
        // await RefreshRow(attachmentData.id,'id',SelectedRowIndex)
    }
    
    // Get current attachment tab, so we can update the count
    const currentTab = attachmentTabs.filter(el=>el.field===attachmentField)[0]
    currentTab.count = items.length;

    this.setState({lastUpdate: Date.now(), attachmentTabs:attachmentTabs,attachmentDisplayMode:'review',attachmentData:attachmentData})
    
  }

  addAttachment = async (newItems) => {
      const {RefreshRow,SelectedRowIndex,isRoot,dataItem} = this.props;
      const {attachmentField,attachmentData,attachmentTabs} = this.state;
      const { userInfo } = this.context;
      let items=[];
      
      if(attachmentData.selectedRow[attachmentField] !==null){
        items = [...attachmentData.selectedRow[attachmentField].filter(itm=>itm.optionid !==null)]
      }
      
      items.push.apply(items, newItems);

      const table = `tbl_${attachmentData.tableid}`;
      attachmentData.selectedRow[attachmentField] = items;
      await saveAttachments(attachmentData.id,table,attachmentField,items,userInfo.id,dataItem.tableinfo)
      if(isRoot) {
        //3-28-23 commented out due to issue if you made attachment change, then updated detail page
        //then navigated forward/back...you would not see updated record. The logic already appears
        // to update the grid without needing to call this refreshrow. 
        // await RefreshRow(attachmentData.id,'id',SelectedRowIndex)
      }
      
      // Get current attachment tab, so we can update the count
      const currentTab = attachmentTabs.filter(el=>el.field===attachmentField)[0]
      currentTab.count = items.length;

      this.setState({attachmentTabs:attachmentTabs,attachmentDisplayMode:'review',attachmentData:attachmentData})
      
  }

   fetchData = async () => {
      
    const {id} = this.props
    const {dataItem,role} = this.props;
    const {displayMode}= this.state;

    const {userInfo } = this.context;
    const schema=`schema_${Global.zoneid}`;
    // console.log(id)
    // console.log(dataItem)
    // console.log(displayMode)
    const t = await GetRelatedTablesInfo(schema,id,dataItem,displayMode,role,userInfo.blocks);
    return t

  };



    render() {
        const {color,dataItem,loadRelatedRecord,selectedRow,role,blockid,canMessage,canCollaborate,canViewComments,isMobileShare,isShare,shareControl,hasMessages} = this.props;
        const {lastUpdate,zoneid,items,itemSelected,displayMode,isLoading,expandRelatedRecord,control,hasAttachments,attachmentTabs,attachmentField,attachmentDisplayMode,attachmentData,isPublicZoneBlock,layout,secondaryInitialSize, currentNestedFields,toAddress,messageControl,phoneNumber} = this.state;

        const currentAttachmentField = attachmentData.tableinfo !== undefined ? attachmentData.tableinfo.columns.filter(ii=>ii.data===attachmentField)[0] : null;

        // 12-31-2020 even thought this is set on componentdidupdate, it is not recognized by control logic below
        // therefore I determine if this current record has attachments here which is use to display/not display the attachmentviewer.
        const canViewPrivate = dataItem.tableinfo.security ==undefined || dataItem.tableinfo.security.viewPrivate ==undefined || dataItem.tableinfo.security.viewPrivate <= role;
        const attachmentIndex = dataItem.tableinfo.columns.findIndex(el=>((el.uitype===4 || el.source?.lookupuitype===4) && (el.isPrivate===undefined || canViewPrivate)) )
    
        // const attachmentIndex =dataItem.tableinfo.columns.findIndex(el=>el.uitype===4 || el.source && el.source.lookupuitype===4)

        return (
            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', width:'100%',overflow:'hidden'}}>
             {!isMobileShare ? (
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',width:'100%',justifyContent:'space-between'}}>
                
            <div style={{display:'flex', marginTop:'10px', marginLeft:'10px',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid #eee',width:'100%'}}>
                    {(isShare && hasMessages) || (!isShare && dataItem.hasMessages) ? (
                    <ActionButton marginBottom="0px" label="Messages" actionIcon={mdiChatProcessing} Action={this.changeControl} ActionValue="messages" showBottom={true} color={color} isSelected={control==='messages'} />
                    ): null}
                    {hasAttachments ? (
                     <ActionButton marginBottom="0px" label="Attachments" actionIcon={mdiPaperclip} Action={this.changeControl} ActionValue="attachments" showBottom={true} color={color} isSelected={control==='attachments'} />
                    ): null}
                   {items.length > 0 ? (
                   <ActionButton marginBottom="0px" label="Related records" actionIcon={mdiTransitConnectionVariant} Action={this.changeControl} ActionValue="datalist" showBottom={true} color={color} isSelected={control==='datalist'} />
                   ): null}

                    {!isPublicZoneBlock && (canCollaborate || canViewComments) ? (
                   <ActionButton marginBottom="0px" label="Collaboration"  actionIcon={mdiAccountMultiple} Action={this.changeControl} ActionValue="collaborators" showBottom={true} color={color} isSelected={control==='collaborators'} />
                   ): null}
                   {/* changed to use shareControl =undefined instead of isshare, because we use isShare with Record viewer and want history to appear. */}
                   {shareControl===undefined ? (
                   <ActionButton marginBottom="0px" label="History" tooltip="Data history" actionIcon={mdiHistory} Action={this.changeControl} ActionValue="history" color={color}  showBottom={true} isSelected={control==='history'} />
                   ):null}
            </div>
                <div style={{marginRight:'20px'}}><TextButton text="Refresh" textColor='black' hoverColor={'#00000080'} icon={mdiRefresh} Action={()=>this.props.refreshRecord(this.props.dataItem,items[itemSelected]?.blockid ?? 0)} /></div>
            </div>

            ):null}
   
                {items.length >0 && control==='datalist' ? (
                    <div style={{display:'flex',flexDirection:'row', paddingLeft:'10px',paddingTop:'10px',alignItems:'flex-start', backgroundColor:`${color}1A`, minHeight:'60px',width:'100%', overflowX:'auto',overflowY:'hidden'}}>
                        {items.length > 0 ? items.map((itm,index) => (
                            <ActionButton tooltip={itm.tooltip} height="50px" key={index} label={`${itm.name} (${itm.count})`} actionIcon={itm.icon} Action={this.changeRelatedTable} ActionValue={index} color={color} showBottom={true} isSelected={index===itemSelected} isMobile={isMobileShare}/>
                        )) : null}
                    </div>
                ): null}

                {control==='messages' ? (
                    <div style={{display:'flex',flexDirection:'row',paddingLeft:'10px',paddingTop:'10px',alignItems:'flex-start', backgroundColor:`${color}1A`, height:'60px',width:'100%'}}>
                        {dataItem.tableinfo.columns.findIndex(col=>col.uitype===12) !==-1 ?(
                            <ActionButton height="50px" key={'text'} label={'Text'} actionIcon={mdiChatProcessing} Action={this.ChangeMessageMode} ActionValue={'text'} color={color} showBottom={true} isSelected={messageControl==='text'} isMobile={isMobileShare}/>
                        ):null}
                        {dataItem.tableinfo.columns.findIndex(col=>col.uitype===10 && col.enableEmailMessages) !==-1 ? (
                            <ActionButton height="50px" key={'email'} label={'Email'} actionIcon={mdiEmail} Action={this.ChangeMessageMode} ActionValue={'email'} color={color} showBottom={true} isSelected={messageControl==='email'} isMobile={isMobileShare}/>
                        ):null}
                    </div>
                ): null}

                {attachmentTabs.length >0 && control==='attachments' ? (
                    <div style={{display:'flex',flexDirection:'row',paddingLeft:'10px',paddingTop:'10px',alignItems:'flex-start', backgroundColor:`${color}1A`, height:'60px',width:'100%'}}>
                        {attachmentTabs.length > 0 ? attachmentTabs.map((itm,index) => (
                            <ActionButton height="50px" key={index} label={`${itm.header}`} BottomText={itm.count} actionIcon={itm.icon} Action={this.changeAttachmentTab} ActionValue={itm.field} color={color} showBottom={true} isSelected={itm.field===attachmentField} />
                        )) : null}
                    </div>
                ): null}

                {/* Note: Datalist controls state inside itself (for now) so it refreshes UI as changes are made.
                The Attachments control has items passed in,therefore we need to maintain state in this container;
                 */}
                <div style={{width:'100%',overflow:'hidden', height: layout==='vertical' ? `${Math.round(secondaryInitialSize-100)}px` : `${window.innerHeight}px`  }}>
                {items.length > 0 && !isLoading && control==='datalist' ? (
                <DataList
                    displayMode={displayMode}
                    tableid={items[itemSelected].tableid}
                    sourcetableid={dataItem.tableid}
                    id={dataItem.id}
                    renderfield={items[itemSelected].lookupfield}
                    nestedFields={items[itemSelected].nestedFields ?? undefined} //items[itemSelected].nestedFields ?? null
                    blockid={items[itemSelected].blockid}
                    field={items[itemSelected].field}
                    filters={items[itemSelected].filters}
                    tableinfo={items[itemSelected].tableinfo}
                    color={color}
                    AddItem={this.AddItem}
                    RemoveItem={this.RemoveItem}
                    Apply={this.ApplyChanges}
                    role={role}
                    count={items[itemSelected].count}
                    Close={this.ChangeDisplayMode}
                    selectMode={items[itemSelected].lookup}
                    callingComponent='detailview'
                    expandRelatedRecord={expandRelatedRecord}
                    UpdateExpandRelatedRecord={this.UpdateExpandRelatedRecord}
                    loadRelatedRecord={loadRelatedRecord}
                    height={this.state.height}
                    canEdit={dataItem.selectedRow.canEdit}
                    relationalSecurity={items[itemSelected].useSecurity}
                    newRecordOnly={items[itemSelected].isSubTable}
                     // 12-12-2021. This is set on relational definition and controls whether we apply security when fetching the records for this relational table.
                />
                ): null}

                {!isLoading && control==='messages' && dataItem.hasMessages && messageControl==='email' ? (
              
                     <EmailMessages
                        color={color}
                        tableid={dataItem.tableid}
                        dataID={dataItem.id}
                        tableinfo={dataItem.tableinfo}
                        RID={dataItem.selectedRow.CustomID}
                        callingComponent="detailview"
                        toAddress={toAddress}
                        canMessage={canMessage}
                        role={role}
                        isShare={isShare}
                    />
                ): null}

                {!isLoading && control==='messages' && dataItem.hasMessages && messageControl==='text' ? (
                    <Messages
                        color={color}
                        tableid={dataItem.tableid}
                        dataID={dataItem.id}
                        number={phoneNumber}
                        tableinfo={dataItem.tableinfo}
                        RID={dataItem.selectedRow.CustomID}
                        callingComponent="detailview"
                        canMessage={canMessage}
                        isShare={isShare}
                        role={role}
                        />
                ) : null}

                {/* We copy the prop dataItem into attachmentData so that we can dynamically update the details
                view when attachments are added/remove, items changed, etc. This is because it's controlled in top
                level container, unlike datalist.
                The items, tableid, field expressions are complex because we need to support attachment field
                AND attachment lookup field and attachment lookup lookup.
                */}
                {!isLoading && control==="attachments" && attachmentDisplayMode==="review" &&  attachmentIndex !== -1 ? (
                    <AttachmentViewer
                    tablename='test'
                    caller='detail'
                    lastUpdate={lastUpdate}
                    isReadOnly={attachmentData.tableinfo.columns.filter(ii=>ii.data===attachmentField)[0].uitype===18}
                    zoneid={zoneid}
                    lastUpdatedItem=''
                    updateItem={this.updateAttachment}
                    switchMode={this.switchAttachmentMode}
                    recordname={attachmentData.selectedRow.CustomID}
                    color={color}
                    removeItem={this.removeAttachment}
                    // items={attachmentData.selectedRow[attachmentField] !==null ? attachmentData.selectedRow[attachmentField].filter(el=>el.optionid!==null) : [{optionid:null}] }
                    items={attachmentData.selectedRow[attachmentField] !==null ? attachmentData.selectedRow[attachmentField].filter(el=>el.optionid!==null) : [] }
                    tableid={currentAttachmentField.jointype==='relational-lookup' ? currentAttachmentField.source && currentAttachmentField.source.lookuptableid : currentAttachmentField.jointype==="relational-lookup-lookup" ? currentAttachmentField.source.grouptable.replace('tbl_','') : attachmentData.tableid}
                    field={currentAttachmentField.jointype==='relational-lookup' ? currentAttachmentField.source && currentAttachmentField.source.lookupfield.replace('_options','') : currentAttachmentField.jointype==="relational-lookup-lookup" ? currentAttachmentField.source.finallookupfield.replace('_options','') : attachmentField}
                    selectmode={attachmentData.tableinfo.columns.filter(ii=>ii.data===attachmentField)[0].lookup}
                    width={!isMobileShare ? secondaryInitialSize :  400}
                    canEdit={selectedRow.canEdit}
                    allowCategories={currentAttachmentField.allowCategories}
                    />
                ): null}

                {!isLoading && control==="attachments" && attachmentDisplayMode==="addition" ? (
                    <AddAttachmentsContainer
                      zoneid={zoneid}
                      color={color}
                      AddItems={this.addAttachment}
                      tableid={dataItem.tableid}
                      field={attachmentField}
                      close={this.switchAttachmentMode}
                      selectmode={attachmentData.tableinfo.columns.filter(ii=>ii.data===attachmentField)[0].lookup}
                      switchMode={this.switchAttachmentMode}
                      caller='detail'
                      allowCategories={currentAttachmentField.allowCategories}
                    />
                ): null}

                  {!isLoading && control==="collaborators" ? (
                    <CollaboratorMessages
                       color={color}
                       tableid={dataItem.tableid}
                       RID={dataItem.selectedRow.CustomID}
                       recordName={dataItem.tableinfo.recordname}
                       dataID={dataItem.id}
                       blockid={blockid}
                       canCollaborate={canCollaborate}
                       canViewComments={canViewComments}
                       canEdit={selectedRow.canEdit} // 6-8-2022 added so that we can turn off auto-focus viewing from share.
                    />
                ): null}
                {!isLoading && control==='history' ? (
                    <History 
                        canUnmask={dataItem.tableinfo.security ==undefined || dataItem.tableinfo.security.unmask===undefined || dataItem.tableinfo.security.unmask < role}
                        color={color}
                        tableid={dataItem.tableid}
                        tableinfo={dataItem.tableinfo}
                        role={role}
                        id={dataItem.id} />
                ):null}

                </div>
            </div>
        )
    }
}

export default ActionPanel