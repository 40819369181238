/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {  Input } from 'semantic-ui-react';
import FilterOperators from './FilterOperators';

const TextFilter = ({
  filter,
  field,
  UpdateFilter,
  filterData,
  displayfield,
  header,
  uitype,
  remove,
  placeholder,
  color,
  node
}) => {
  const [operator, setOperator] = useState('contains');
  const [fieldvalue, setFieldvalue] = useState('');
  const [filterOption, setFilterOption] = useState([]);

  useEffect(() => {
    if (filter.value != null) {
      setFieldvalue(filter.value);
      setOperator(filter.operator);
    }

    let filterOptionTemp = [
      { key: 'contains', text: 'Contains', value: 'contains' },
      { key: 'notcontains', text: 'Does not contain', value: 'notcontains' },
      { key: 'startswith', text: 'Starts with', value: 'startswith' },
      { key: 'equalto', text: 'Equal to', value: 'equalto' },
      { key: 'notequalto', text: 'Does not equal', value: 'notequalto' },
      { key: 'isnull', text: 'Is empty (null)', value: 'isnull' },
      { key: 'isnotnull', text: 'Is not empty (not null)', value: 'isnotnull' },
    ];

    if(uitype==13 || node?.source?.lookupuitype===13) {
      filterOptionTemp = filterOptionTemp.filter(
        (option) =>
          option.key !== 'startswith' &&
          option.key !== 'equalto' &&
          option.key !== 'notequalto'
      );
    }
    setFilterOption(filterOptionTemp);
  }, []);


  const handleEnter = e => {
    if (e.keyCode === 13) {
      filterData()
    }
  };

  const updatefilter = (data, tmpoperator) => {

    setFieldvalue(data);

    if (tmpoperator !== undefined) {
      const filterObject = {
        field,
        header,
        value: data,
        operator: tmpoperator,
        displayfield,
        uitype,
      };
      UpdateFilter(filterObject);
    } else {
      const filterObject = { field, header, value: data, operator, displayfield, uitype };
      UpdateFilter(filterObject);
    }
  };

  const changeOperator = (newoperator) => {
    setOperator(newoperator);
    updatefilter(fieldvalue, newoperator);
  };

  const removeFilter = (newfield) => {
    remove(newfield);
  };

  



  const displayvalue = operator === 'isnull' ? 'Empty (is null)' : 'Not empty (is not null)';

  return (
    <div style={{ marginBottom: '23px', width: '100%' }}>
      <FilterOperators
        header={header}
        operator={operator}
        filterOption={filterOption}
        RemoveAction={removeFilter}
        RemoveActionValue={field}
        ChangeOperator={changeOperator}
        color={color}
      />

      {operator === 'isnull' || operator === 'isnotnull' ? (
        <Input style={{ width: '100%' }} value={displayvalue} />
      ) : (
        <Input
          value={fieldvalue}
          onKeyDown={handleEnter}
          onChange={(e, data) => updatefilter(data.value)}
          style={{ width: '100%' }}
          placeholder={placeholder}
        />
      )}
    </div>
  );
};

TextFilter.propTypes = {
  field: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  uitype: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  displayfield: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filter: PropTypes.object.isRequired,
  UpdateFilter: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default TextFilter;
