/* eslint-disable */
import React, { useEffect } from 'react';
import { Label } from 'semantic-ui-react';
import Moment from 'react-moment';
import numbro from 'numbro';

const DisplayMultiple = ({ value, field, specialfunction, cellWidth, cellHeight,numericFormat,dateFormat,UTC,enableColors,paddingTop="3px" }) => {
  // The avaiable renderer-related props are:
  // - row (row index)
  // - col (column index)
  // - prop (column property name)
  // - TD (the HTML cell element)
  // - cellProperties (the cellProperties object for the edited cell)

  // useEffect(() => {
  //   console.log(cellWidth)
  // },[cellHeight,cellWidth])

  const formatValue = (value) => {
    if (dateFormat !== undefined) {
      return (
        <Moment utc={UTC} format={dateFormat}>
          {value}
        </Moment>
      );
    } else if (numericFormat !== undefined) {
      numbro.setLanguage(numericFormat.culture);
      return value ? numbro(value).format(numericFormat.pattern) : null;
    }
  };

  const createtags = () => {
    //  console.log('render running')
    if (value != null && value) {
      return (
        value &&
        value.map &&
        value.map((el) =>
          // el.data && el.data.attributes ? <Label>yes</Label> : <Label>no</Label>
          // <Label size='tiny' key={i} style={{margin:'0x', color:'white', backgroundColor: el.data && el.data.attributes ? el.data.attributes.color : 'teal'}} >{el.data && el.data.option ? el.data.option : null}</Label>
          el && el.data ? (
            // <div key={el.optionid} style={{width:'max-content',marginRight:'3px', marginTop:'3px'}}>
            <div
              key={el.optionid}
              style={{
                width: 'max-content',
                backgroundColor: enableColors && el.attributes && el.attributes.color,
                color: enableColors && el.attributes && el.attributes.text,
                paddingLeft: '5px',
                paddingRight: '5px',
                height: '17px',
                marginRight: '3px',
                display: 'inline-flex',
                paddingTop: '2px',
                paddingBottom:'2px',
                alignItems: 'center',
                alignContent:'center',
               
                borderRadius: '7px',
              }}
            >
              {el.data}
            </div>
          ) : // </div>
          null,
        )
      );
    }
    return null;
  };

  const createSpecialLookupTag = () => {
    if (typeof value !== 'object' && value) {
      return value ? (
        <Label size="13px" style={{ margin: '2px', backgroundColor: '#FFF8E1' }}>
        {dateFormat !==undefined  || numericFormat !==undefined ? formatValue(value) : value}
        </Label>
      ) : null;
    } else if (specialfunction === 'count') {
      return (
        <Label size="13px" style={{ margin: '2px', backgroundColor: '#FFF8E1' }}>
          0
        </Label>
      );
    }
    return null;
  };

  const DisplayResults = () => {
  
    if (specialfunction !== 'all' && specialfunction !== undefined) {
      return createSpecialLookupTag();
    }
    return createtags();
  };

  const TagStyle = {
    width: cellWidth ? cellWidth : null,
    // display:'block',
    // flexDirection:'row',
    height: cellHeight ? cellHeight - 8 : null,
    float: 'left',
    zIndex: 0,
    paddingTop: paddingTop,
    // marginRight: '5px',
    overflow: 'hidden',
    whiteSpace: 'wrap',
    textOverflow: 'ellipsis',
  };

  return <div style={TagStyle}>{DisplayResults()}</div>;
};

export default DisplayMultiple;
