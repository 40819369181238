/* eslint-disable */
import React,{useEffect, useRef,useState} from 'react';
import { Label } from 'semantic-ui-react';
import Moment from 'react-moment';
import numbro from 'numbro';
import DOMPurify from 'dompurify';
import { isString } from 'lodash';

 const DisplayLookupValues = ({
  value,
  specialfunction,
  dateFormat,
  UTC,
  color,
  numericFormat,
  cellHeight,
  cellWidth,
  uitype,
  fieldType,
  useButtonDisplay,
  buttonLabel,
  callingComponent="grid",
  showForm
 }) => {

  const buttonRef = useRef(null);

  useEffect(() => { 
    if(uitype===16 && useButtonDisplay ) {
    // Add onclick event to the button after the component is mounted
    if (buttonRef.current) {
      buttonRef.current.addEventListener('click', handleButtonClick);
    }

    // Clean up the event listener when the component is unmounted
    return () => {
      if (buttonRef.current) {
        buttonRef.current.removeEventListener('click', handleButtonClick);
      }
    };
  }

  }, []);

  const handleButtonClick = () => {
  
    if(value !==null) {
      const url = new URL(value[0].data);
      const query = new URLSearchParams(url.search);
      const params = {};
        query.forEach((value, key) => {
          params[key] = value;
        });
        
        showForm(1,params) //first param is not used here, will be ignored. just need params to be passed.
    }
  };


  const colorboxStyle = {
      width: cellWidth !== undefined ? cellWidth : null,
      height: cellHeight !== undefined ? cellHeight - 8 : null,
      whiteSpace: 'wrap',
      textOverflow: 'ellipsis',
      float: 'left',
      marginRight: '15px',
    };

  const isNumeric= (value) =>{
    return !isNaN(value) && !isNaN(parseFloat(value));
  }

  const formatValue = (value) => {

    if (dateFormat !== undefined) {
      return (
        <Moment utc={false} format={dateFormat}>
          {value}
        </Moment>
      )
    } else if (numericFormat !== undefined && !['count','countunique'].includes(specialfunction)) {
      numbro.setLanguage(numericFormat.culture);
      return value !== null && (typeof value === 'number') ? numbro(value.toFixed(2)).format(numericFormat.pattern) : null;
    } else if(uitype===11 && useButtonDisplay===undefined) {
      return  (<a style={{ color: 'black',width:'100%',wordBreak:'break-all' }} href={value} target="_newtab">
             {value}
            </a>)
    } else if(uitype===11 && useButtonDisplay !=undefined) {
    
      const leftPosition = (screen.width - 800) / 2;
      const topPosition = (screen.height - 600) / 2;
      const windowFeatures = `resizable,height=600,width=800,left=${leftPosition},top=${topPosition}`;
      const url = `javascript:window.open('${value}', 'pagename', '${windowFeatures}');`;

      return  (
      <a title={value} href={url}>
      {/* <div style={{width:'max-content', fontWeight:'bold', marginTop:'3px', backgroundColor:color,color:'white',paddingLeft:'10px',paddingRight:'10px', paddingBottom : callingComponent==='grid' ? '3px' : null,borderRadius:'5px'}}>{buttonLabel}</div> */}
      {/* remoiving padding for dev/prod paddingTop:'5px', paddingBottom:'4px', */}
      <button style={{border:`1px solid ${color}`,outline:'none',  backgroundColor:color,color:'white',borderRadius:'5px'}} >{buttonLabel}</button>
     </a>)
    }
    
     //added check on whether value is numeric, if yes, truncate to 2 places.
     //5-14-24 added check specialfunction is not count. with count/countunique, we jsut want to show raw number.
    else if(isNumeric(value) && !['count','countunique'].includes(specialfunction) ) { 
      return value.toFixed(2);
    } else {
      return value
    }
  };

  const createMarkup = (html) => {
    DOMPurify.setConfig({ ADD_ATTR: ['target'] });
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  const displayLineBreaks =(control) =>{
    if((isString(control) && !control.includes('\n')) || (!isString(control))) {
      return control
    } else {
      return (
      control.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index < control.split('\n').length - 1 && <br />}
        </React.Fragment>
      ))
      )
    }
  }

  const showDisplayValue = (el, i) => {
    let control = null;

    
    if(uitype===16) {
      if(fieldType==='date') {
        control = <Moment utc={false} format={dateFormat}>{el.data}</Moment>
      } else if(fieldType==='numeric') {
        numbro.setLanguage(numericFormat.culture);
        control = el.data !==null ? numbro(el.data).format(numericFormat.pattern) : null
      } else if(fieldType==='boolean') {
        control = el.data.toString();
      } else if(fieldType==='text' && el.data.startsWith('http') && useButtonDisplay===undefined) {
        control= (<a style={{ color: 'black',width:'100%',wordBreak:'break-all'}} href={el.data} target="_newtab">
            {el.data}
          </a>)
      } else if(fieldType==='text' && el.data.includes('form?x=') && useButtonDisplay!==undefined && showForm !==undefined) {
        //This indicates its a webform link, so use internal webform via formwrapper.
        //until we have a better mechanism, if the url contains form?x= will indicate to use the webform.
        {/* remoiving padding for dev/prod paddingTop:'5px', paddingBottom:'4px', */}
        control = <div style={{paddingTop: callingComponent==='grid' ? '4px' : null}}>
            <button 
              style={{
                paddingTop: callingComponent!=='grid' ? '5px' : null,
                paddingBottom: callingComponent!=='grid' ? '4px' : null,  
                border:`1px solid ${color}`,
                outline:'none', 
                backgroundColor:color,color:'white',
                borderRadius:'5px'}} 
                ref={buttonRef } 
                onClick={handleButtonClick}>
                {buttonLabel}
            </button>
          </div>
      } else if(fieldType==='text' && el.data.startsWith('http') && useButtonDisplay!==undefined) {
        const leftPosition = (screen.width - 800) / 2;
        const topPosition = (screen.height - 600) / 2;
        const windowFeatures = `resizable,height=600,width=800,left=${leftPosition},top=${topPosition}`;
        const url = `javascript:window.open('${el.data}', 'pagename', '${windowFeatures}');`;

        control = (
        <a title={el.data} href={url}>
          <div style={{width:'max-content', marginTop:'3px', backgroundColor:color,color:'white',paddingLeft:'10px',paddingRight:'10px',paddingTop:callingComponent!=='grid' ? '4px' : null,paddingTop:'2px', paddingBottom:'2px',borderRadius:'5px'}}>{buttonLabel}</div>
        </a>
        )
      }
      else {
        control = el.data
      }
    }
    else if (
      dateFormat !== undefined ||
      numericFormat !== undefined ||
      specialfunction === 'avg' ||
      uitype ===11  // 8-10-23 adding url type for special formatting. Formula field is already taken care of for root/lookup of formulas above.
    ) {
      control = formatValue(el.data);
    } else if (uitype === 24 || uitype == 25) {
      control = `${el.firstname} ${el.lastname}`;
    } else if (uitype === 13) {
      control = (
        <div
              style={{ minHeight: '30px', width: '100%' }}
              dangerouslySetInnerHTML={createMarkup(el.data)}
            />
      );
    } else {
      control = el.data;
    }

    if (uitype === 13) {
      return <div style={{ border: '3px solid #eee', padding: '10px' }}>{control}</div>;
    }  else {
      return (
        useButtonDisplay ? control : (
        <Label
          basic
          style={{
            paddingTop: '2px',
            paddingBottom: '2px',
            fontSize: '12px',
            alignSelf: 'center',
            backgroundColor: uitype===16 ? '#E5EEFF' : specialfunction !== 'all' ? '#FFF8E1' : '#E8E8E8',
          }}
          key={i}
        >
          {uitype ===14 ? '******' :  displayLineBreaks(control)}
        
        </Label>
        )
      );
    }
  };

  const CreateText = () => {
    if (value !== null) {
      if (specialfunction !== 'unique') {
        return (
         value &&
         value.map &&
         value.map((el, i) =>
            el.data !== null
              ?  showDisplayValue(el, i)
              :  null,
          )
        );
      }
      if (specialfunction === 'unique') {
        const items = [];

         value &&
          value.map &&
          value.length > 0 &&
         value.forEach((el) => {
            if (uitype === 24 || uitype === 25) {
              if (el.userid != null) {
                if (!items.includes(`${el.firstname} ${el.lastname}`)) {
                  items.push(`${el.firstname} ${el.lastname}`);
                }
              }
            } else if(uitype===14) {
              if (el.data != null) {
                if (!items.includes(el.data)) {
                  items.push('******');
                }
              }
            }
            else {
              if (el.data != null) {
                if (!items.includes(el.data)) {
                  items.push(el.data);
                }
              }
            }
          });

        return items.map((el, i) => (
          // <Label style={{marginTop:'5px', marginBottom:'5px'}} key={el.tbl_1.id}>{el.tbl_1[this.props.lookupfield]}</Label>
          // eslint-disable-next-line react/no-array-index-key
          <Label
            style={{
              paddingTop: '2px',
              paddingBottom: '2px',
              fontSize: '12px',
              alignSelf: 'center',
              backgroundColor: uitype===16 ? '#E5EEFF' : '#E8E8E8',
            }}
            key={i}
          >
            {(dateFormat !== undefined || numericFormat !== undefined) && fieldType!=='text'
              ? formatValue(el)
              : el}
          </Label>
        ));
      }
    }
    return null;
  };

    return (
      <div style={colorboxStyle}>
        {CreateText()}
      </div>
    );
}

export default DisplayLookupValues;
