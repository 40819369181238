/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { memo,useEffect } from 'react';

import { Handle, Position } from 'reactflow';
import Icon from '@mdi/react';

const TriggerNode = ({ id, data }) => {

  return (
    <div
      css={{
        '&:hover':  {
            // transition: 'all .3s ease',
            '& .inner': {
                backgroundColor:'white',
                transition: 'all .3s ease',
            },
            '& .nodrag': {
                color:'#0D9953',
                transition: 'all .3s ease',
            },
            '& .custom-node__body': {
                transition: 'all .3s ease',
                svg: {
                    color:'#0D9953',
                  },
            }
        },
        border: '1px solid #0D9953',
        padding: '10px',
        borderRadius: '70px',
        backgroundColor: '#CFF5E2',
        width:'211px',
        height:'130px'
      }}
    >
      <div
        className='inner'
        key={id}
        css={{
          display: 'flex',
          color:'white',
          flexDirection: 'column',
          alignItems: 'center',
          height:'100%',
          justifyContent: 'center',
          border: '1px solid #0D9953',
          borderRadius: '70px',
          backgroundColor: id=data.selectedNodeId ? '#0D995380' : '#0D9953',
        }}
      >
        <div className="custom-node__body" style={{textAlign:'center'}}>
            <Icon path={data.tableData.icon} size="35px" />
            <div
              className="nodrag"
            //   style={{ color: 'white', fontSize: '13px', fontWeight: 'bold',textOverflow:'ellipsis' }}
              css={{ 
                color: 'white', 
                fontSize: '13px', 
                fontWeight: 'bold',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: '2', // Number of lines you want to display
                WebkitBoxOrient: 'vertical',
                width: '100%', // Ensure the div has a set width to properly apply ellipses
                textAlign: 'center', // Replaces <center> as it's obsolete, and applies centering text
                padding: '0 10px', // Optional, adds padding to ensure text does not touch the div edges
              }}
            >
              {' '}
             {data.label}
            </div>

        </div>
        <Handle type="source" position={Position.Bottom} />
      </div>
    </div>
  );
};

export default TriggerNode;
