/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { Dropdown,Input,Form,TextArea } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import moment from 'moment';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import { AccountContext } from '../../../../providers/AccountProvider';
import { getBookmarks } from '../../../../api/bookmarks';
import { createShare } from '../../../../api/shares';
import {getForms} from '../../../../api/forms';
import TextButton from '../../../../components/TextButton/TextButton';
import GBButton from '../../../../components/GBButton/GBButton';

const CreateShare = ({ color, tableid, tablename,tableinfo,sharedRecordIds,close,showShareDetails,RID,statement,role }) => {
  
  const { userInfo} = useContext(AccountContext);
  const [security, setSecurity] = useState(0);
  const [bookmarks, setBookmarks] = useState([]);
  const [selectedBookmark, setSelectedBookmark] = useState(null);
  const [includeAttachments,setIncludeAttachments] = useState(false);
  const [includeMessages,setIncludeMessages] = useState(false);
  const [includeSendMessage,setIncludeSendMessage] = useState(false);
  const [includeComments, setIncludeComments] =useState(false);
  const [includeAddComments, setIncludeAddComments]=useState(false);
  const [includeDownload, setIncludeDownload] =useState(false);
  const [includeWebform, setIncludeWebform] =useState(false);
  const [protectLink, setProtectLink] = useState(false);
  const [password, setPassword] =useState(null);
  const [instructions, setInstructions] = useState('');
  const [forms, setForms] = useState([]);
  const [selectedForm,setSelectedForm] =useState();
  const [includeDetails, setIncludeDetails] =useState(false);
  const [hideHeader, setHideHeader] =useState(false);
  const [hideFooter, setHideFooter] = useState(false);
  const [useExternalDetailLink, setUseExternalDetailLink] =useState(false)
  const [externalDetailUrl, setExternalDetailUrl] = useState('');
  const [appendSystemID, setAppendSystemID] =useState(false);
  const [encodeSystemID,setEncodeSystemID]=useState(true);
  const [usePopup,setUsePopup]=useState(false);
  const [popupWidth, setPopupWidth]=useState(800)
  const [popupHeight, setPopupHeight]=useState(600);


  const hasAttachments = tableinfo.columns.findIndex(el=>el.uitype===4 || (el.source && el.source.lookupuitype===4)) !==-1
  const hasMessages = tableinfo.columns.findIndex(el=>el.uitype===12 || (el.uitype===10 && el.enableEmailMessages===true) ) !==-1

  const toggleAttachments =() =>{
      setIncludeAttachments(!includeAttachments)
  }

  const toggleDetails =() =>{
    setIncludeDetails(!includeDetails)
  }

  const toggleMessages=() =>{
    if(userInfo.plan ==='Free') {
      toast.info(<div style={{margin:'10px'}}>You must upgrade your plan in order to use this feature.</div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 7000,
      })
      return;
    } else {
      setIncludeMessages(!includeMessages)
    }
  }
  const toggleSendMessages=()=>{
      setIncludeSendMessage(!includeSendMessage)
  }

  const toggleHideHeader=() =>{
    if(userInfo.plan ==='Free') {
      toast.info(<div style={{margin:'10px'}}>You must upgrade your plan in order to use this feature.</div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 7000,
      })
      return;
    } else {
     setHideHeader(!hideHeader)
    }
  }

  const toggleHideFooter=() =>{
    if(userInfo.plan ==='Free') {
      toast.info(<div style={{margin:'10px'}}>You must upgrade your plan in order to use this feature.</div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 7000,
      })
      return;
    } else {
     setHideFooter(!hideFooter)
    }
  }

  const toggleIncludeWebform = () =>{
    if(userInfo.plan ==='Free') {
      toast.info(<div style={{margin:'10px'}}>You must upgrade your plan in order to use this feature.</div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 7000,
      })
      return;
    } else {
      setIncludeWebform(!includeWebform);
      if(!includeWebform && forms.length===0){
          loadForms();
      }
    }
  }

  const toggleComments =() =>{
      setIncludeComments(!includeComments);
  }

  const toggleExternalUrl =() =>{
    setUseExternalDetailLink(!useExternalDetailLink)
    if(!useExternalDetailLink) {
      setIncludeDetails(true);
    }
  }

  const toggleAddComments =() =>{
    if(userInfo.plan ==='Free') {
      toast.info(<div style={{margin:'10px'}}>You must upgrade your plan in order to use this feature.</div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 7000,
      })
      return;
    }  else {
      setIncludeAddComments(!includeAddComments);
    }
  }
  const toggleProtectLink =() =>{
    if(userInfo.plan ==='Free') {
      toast.info(<div style={{margin:'10px'}}>You must upgrade your plan in order to use this feature.</div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 7000,
      })
      return;
    } else {
      setProtectLink(!protectLink);
    }
  }
  const toggleDownload=() =>{
      setIncludeDownload(!includeDownload);
  }


  useEffect(() => {
    loadBookmarks();
  }, []);

  const loadBookmarks = async () => {
    const bm = await getBookmarks(tableid);
    const bmArray = [];
    if (bm.length > 0) {
      bm.map((el) => {
        bmArray.push({ key: el.bookmarkid, value: el.bookmarkid, text: el.name });
      });
      setBookmarks(bmArray);
    }
  };

  const loadForms = async () =>{
    const options=[];
    const forms = await getForms(tableid,role);
    if(forms.length>0) {
      forms.map(el=>{
        options.push({key:el.formid,value:el.formid,text:el.name})
      })
      setForms(options);
    }
  }

  const generateShareLink = async () => {
    
    if(sharedRecordIds===null && (selectedBookmark===null || selectedBookmark==='')) {
      toast.info('You must either create a bookmark to use or select individual records to share. ', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 7000,
      })
      return;
    }

    const settings={
      security:security, 
      includeDetails,
      includeAttachments,
      includeComments,
      includeAddComments,
      includeMessages,
      includeSendMessage,
      includeDownload,
      includeWebform,
      protectLink,
      password,
      color,
      statement,
      hideHeader,
      hideFooter,
      useExternalDetailLink,
      externalDetailUrl,
      appendSystemID,
      encodeSystemID,
      usePopup,
      popupWidth,
      popupHeight
    }
    const t = moment().format('MMMM Do YYYY, h:mm:ss a')
  
     let bookmarkname='';
     let name='';
     if(selectedBookmark !==null) {
       bookmarkname = bookmarks.filter(el=>el.value===selectedBookmark)[0].text;
       name=`${bookmarkname} - ${t}`
     } else if (RID===undefined) {
       bookmarkname=`${sharedRecordIds.length} records`
       name=`(${sharedRecordIds.length}) - ${t}`
     } else {
       bookmarkname=RID;
       name= RID;
     }

     if(selectedForm !==undefined) {
      const formName = forms.filter(el=>el.value===selectedForm)[0].text;
      settings.formid=selectedForm;
      settings.formName = formName;

     }

     const addedby={userid:userInfo.id,firstname:userInfo.firstName,lastname:userInfo.lastName,attributes:userInfo.attributes}
    
     const shareResult = await createShare(name,tableid,selectedBookmark,sharedRecordIds,instructions,settings);
     if(!shareResult.error) {
        const id= shareResult.id;
        const trackingcode = shareResult.trackingcode;
        const shorturl = shareResult.shorturl;
        const history=[{date: moment().format('DD-MMM-YY h:mm a z'),type:0,addedby:addedby,activity:shorturl}]

        const shareDetails= {name,isActive:true,isvalid:true,shorturl,settings,trackingcode,id,instructions,history,bookmarkname,addedby,addedon:t}

        showShareDetails(shareDetails);
     } else {
      toast.info(<div style={{margin:'10px'}}>An error occurred: {shareResult.message}</div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 7000,
      })
     }

  }

  return (
    <div style={{ margin: '10px' }}>
        {sharedRecordIds===null ? (<>
      <span style={{ color: color,marginLeft:'20px',fontWeight:'bold' }}>New share link details</span>
      
        <div style={{margin:'20px'}}> 
        SELECT SHARE BOOKMARK FOR: {tablename.toUpperCase()}
        <Dropdown selectOnBlur={false} options={bookmarks} selection clearable fluid onChange={(e,data)=>setSelectedBookmark(data.value)} />
      </div>
      </>) : RID !== undefined ?
      (
        <div style={{ color: color,marginLeft:'20px',marginBottom:'20px',fontWeight:'bold' }}>SHARE LINK FOR <u>{RID}</u></div>
      )
      : <div>
         <div style={{ color: color,marginLeft:'20px',marginBottom:'20px',fontWeight:'bold' }}>New share link details ({sharedRecordIds.length} records selected)</div>
      </div>
      }
      {tableinfo.security !==undefined && userInfo.plan !=='Free' && role > 0 ? (
      <div style={{marginLeft:'20px', marginBottom:'20px',display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'flex-start' }}>
        <div style={{ marginRight: '10px', }}>
          Share security level
        </div>
        <div
          onClick={() => setSecurity(0)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: security === 0 ? color : 'white',
            color: security === 0 ? 'white' : 'black',
          }}
        >
          General
        </div>
        <div
          onClick={() => setSecurity(1)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: security === 1 ? color : 'white',
            color: security === 1 ? 'white' : 'black',
          }}
        >
          Level 1
        </div>
        {role > 1 ? (
        <div
          onClick={() => setSecurity(2)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: security === 2 ? color : 'white',
            color: security === 2 ? 'white' : 'black',
          }}
        >
          Level 2
        </div>
        ): null}
      </div>
        ): null}

      <div style={{marginLeft:'20px',marginTop:'10px',marginRight:'20px'}}>
      {hasAttachments ? (
      <div style={{marginTop:'10px',marginBottom:'10px'}}>
          <GBSwitch color={color} text="Include attachments" isChecked={includeAttachments} Action={toggleAttachments} />
      </div>
      ): null}
       {hasMessages ? (
      <div style={{marginTop:'10px',marginBottom:'10px'}}>
          <GBSwitch  color={color}  text="Include messages" isChecked={includeMessages} Action={toggleMessages} />
      </div>
      ): null}
      {includeMessages ? (
        <div style={{marginTop:'10px',marginBottom:'10px',marginLeft:'20px'}}>
            <GBSwitch  color={color}  text="Allow recipient to send messages" isChecked={includeSendMessage} Action={toggleSendMessages} />
        </div>
      ): null}
 
      <div style={{marginTop:'10px',marginBottom:'10px'}}>
      <GBSwitch color={color} text="Include record details" isChecked={includeDetails} Action={toggleDetails} />
      </div>
      <div style={{marginTop:'10px',marginBottom:'10px'}}>
      <GBSwitch  color={color}  text="Include collaboration notes" isChecked={includeComments} Action={toggleComments} />
      </div>
      <div style={{marginTop:'10px',marginBottom:'10px'}}>
      <GBSwitch  color={color}  text="Allow recipient to add collaboration notes" isChecked={includeAddComments} Action={toggleAddComments} />
      </div>
      {RID===undefined && (tableinfo.security===undefined || role >= tableinfo.security.downloadRecords) ? (
      <div style={{marginTop:'10px',marginBottom:'10px'}}>
      <GBSwitch  color={color} text="Enable download to CSV" isChecked={includeDownload} Action={toggleDownload} />
      </div>
      ): null}
      {sharedRecordIds ===null || sharedRecordIds.length>1 ? (
      <div style={{marginTop:'10px',marginBottom:'10px'}}>
      <GBSwitch  color={color} text="Enable option to include a new record" isChecked={includeWebform} Action={toggleIncludeWebform} />
      </div>
      ): null}
      {includeWebform && forms.length>0 ? (
        <Dropdown selectOnBlur={false} value={selectedForm} onChange={(e,data)=>setSelectedForm(data.value)} selection placeholder="Select a web form" clearable fluid options={forms} />
      ): null}
      <div style={{marginTop:'10px',marginBottom:'10px'}}>
      <GBSwitch  color={color} text="Hide header" isChecked={hideHeader} Action={toggleHideHeader} />
      </div>
      <div style={{marginTop:'10px',marginBottom:'10px'}}>
      <GBSwitch  color={color} text="Hide footer" isChecked={hideFooter} Action={toggleHideFooter} />
      </div>
      <div style={{marginTop:'10px',marginBottom:'10px'}}>
      <GBSwitch  color={color} text="Use external detail URL" isChecked={useExternalDetailLink} Action={()=>toggleExternalUrl()} />
      </div>
      {useExternalDetailLink ? (
          <div style={{marginLeft:'20px'}}>
          <Input label="External URL" fluid value={externalDetailUrl} onChange={(e,data)=>setExternalDetailUrl(data.value)} />
          <GBSwitch  color={color} text="Append record ID" isChecked={appendSystemID} Action={()=>setAppendSystemID(!appendSystemID)} />
          {appendSystemID ? (
          <GBSwitch  color={color} text="Form Encode Record ID" isChecked={encodeSystemID} Action={()=>setEncodeSystemID(!encodeSystemID)} />
          ) : null}
          
          <GBSwitch  color={color} text="Use popup window" isChecked={usePopup} Action={()=>setUsePopup(!usePopup)} />
          {usePopup ? (
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',width:'100%'}}>
              <Input style={{width:'200px'}} label="Popup width" fluid value={popupWidth} onChange={(e,data)=>setPopupWidth(data.value)} />
              <Input style={{width:'200px'}} label="Popup height" fluid value={popupHeight} onChange={(e,data)=>setPopupHeight(data.value)} />
          </div>
          ) :null}
          </div>
      ): null}

      <div style={{marginTop:'10px',marginBottom:'10px'}}>
      <GBSwitch  color={color} text="Password protect link" isChecked={protectLink} Action={toggleProtectLink} />
      </div>
     

      {protectLink ? (
          <div style={{marginRight:'20px'}}>
          <Input label="password" fluid value={password} onChange={(e,data)=>setPassword(data.value)} />
          </div>
      ): null}
        {!hideHeader ? (
          <>
           <div>Include these instructional notes at the top of the share page.</div>
        <Form>
        <TextArea value={instructions} onChange={(e,data)=>setInstructions(data.value)} placeholder='Enter explainer text to help users understand the information in this view.' />
        </Form>
          </>
        ): null}
       

        <div style={{display:'flex', flexDirection:'row', alignItems:'center',justifyContent:'flex-end',marginTop:'20px'}}>
        <TextButton
              textColor="black"
              text="Cancel"
              hoverColor="#75757580"
              fontSize="13px"
              Action={close}
            />
        <div style={{width:'20px'}} />
        <GBButton text="Save" Action={generateShareLink} color={color} width="60px" />
        </div>
      </div>

    </div>
  );
};

export default CreateShare;
