/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import Icon from '@mdi/react';
import moment from 'moment';
import { css, jsx } from '@emotion/core'
import {
  mdiCloudDownloadOutline,
  mdiEye,
  mdiEyeOff,
  mdiShareVariant,
  mdiPlus,
  mdiPrinter,
  mdiFilter,
  mdiRefresh,
mdiLightningBoltCircle,
  mdiChat,
  mdiGrid,
  mdiBookmarkMultipleOutline,
} from '@mdi/js';
import PropTypes from 'prop-types';
import { Label, Input } from 'semantic-ui-react';
import styles from './index.module.css';
import ActionButton from './ActionButton';
import RowHeightButton from './RowHeightButton';
import MultiActionButton from './MultiActionButton';
import ColumnsButton from './ColumnsButton';
import GBButton from '../../../../components/GBButton/GBButton';
import cloudrun from '../../../../clients/httpcloudrun';

const ActionBar = ({
  name,
  isSystemTable,
  filters,
  recordCount,
  color,
  icon,
  Refresh,
  showError,
  exportData,
  hasMessages,
  viewMode,
  updateRowHeight,
  defaultRowHeight,
  showFilter,
  updateViewMode,
  toggleBookMarks,
  toggleColumnManager,
  toggleShareManager,
  toggleAddUsers,
  hiddenColumnCount,
  showDownloadButton,
  showShareButton,
  showPrintButton,
  showSpreadSheet,
  role,
  cacheResults,
  lastCache
}) => {

  useEffect(() => {
    setSelectedHeightIcon(defaultRowHeight);
  }, [defaultRowHeight]);


  const Tablename = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {name}   {isSystemTable} <Label style={{ marginLeft: '10px' }}>Total records: {parseInt(recordCount).toLocaleString('en')}</Label>
         {isSystemTable !==undefined && role===3? (
        <div style={{marginLeft:'10px'}}><GBButton color={color} text="Add user(s)" textColor="white" Action={toggleAddUsers} width="100px" /></div>
         ):null}
        <div style={{ width: '25px' }} />
      </div>
    );
  };

  const [heightIcon, setHeightIcon] = useState(0);
  const [selectedHeightIcon, setSelectedHeightIcon] = useState(defaultRowHeight);
  const [lastCacheLocal, setLastCacheLocal] = useState(lastCache ?? Date.now())

  useEffect(()=>{
    setLastCacheLocal(lastCache)
  },[lastCache])

  const getCacheTime = () => {
    const localDateTime = moment(lastCacheLocal);
    return localDateTime.format('M/D/YY hh:mm:ss A');
  }

  const refrechLocal = async ()=>{
    setLastCacheLocal(Date.now())
    await Refresh();
  }

  const buttons=[];
  if(!isSystemTable) {
   buttons.push({param:'quick',icon:mdiLightningBoltCircle,toolTip:'Quick view'});
  }
  if(showSpreadSheet){
    buttons.push({param:'grid',icon:mdiGrid,toolTip:'Spreadsheet view'})
  }
  if(hasMessages && recordCount>1) {
    buttons.push({param:'chat',icon:mdiChat,toolTip:'Chat view'})
  }

  const ToastTest = () => {
    showError(
      <div onClick={() => window.open('http://www.google.com')} style={{ color: 'blue' }}>
        Testing!
      </div>,
    );
  };

  const internalUpdateRowHeight = (height, item) => {
    updateRowHeight(height);
    setSelectedHeightIcon(item);
  };

  const internalUpdateGridView = (view) => {
    updateViewMode(view);
  };

  return (
    <div style={{ marginRight: '45px'}}>
     
      {name !== '' ? (
        <div className={styles.container}>
          <div className={styles.wrapper} style={{ color }}>
            <div className={styles.innerWrapper}>
              <Icon path={icon} size="30px" color={color} />
            </div>
            {name !== '' ? Tablename() : null}
          </div>
          
          <div className={styles.wrapper1}>
            {viewMode === 'grid' || viewMode==='quick' ? (
              <>
                {cacheResults ? (
                <div style={{width:'150px',fontSize:'11px',color:'#7C7C7C'}}>Formula and calculated lookup values last cached at {getCacheTime()}</div>
                ): null}

                <ActionButton
                  label="Refresh"
                  actionIcon={mdiRefresh}
                  height="47px"
                  Action={refrechLocal}
                  color={color}
                  isSelected={false}
                />
                {showDownloadButton ? (
                <ActionButton
                  label="Download"
                  actionIcon={mdiCloudDownloadOutline}
                  Action={exportData}
                  color={color}
                  isSelected={false}
                />): null}
                {showShareButton ? (
                <ActionButton
                  label="Share"
                  tooltip="Share records with anyone!"
                  actionIcon={mdiShareVariant}
                  Action={toggleShareManager}
                  color={color}
                  isSelected={false}
                />):null}
                {/* {showPrintButton ? (
                <ActionButton
                  label="Print"
                  tooltip="This feature is under development"
                  actionIcon={mdiPrinter}
                  Action={null}
                  color={color}
                  isSelected={false}
                />):null} */}
                <RowHeightButton
                  color={color}
                  label="Height"
                  labelPosition="top"
                  showBar3
                  selectedHeightIcon={selectedHeightIcon}
                  Action={internalUpdateRowHeight}
                />
                {!isSystemTable ? (
                <MultiActionButton
                  viewMode={viewMode !=='grid' ? viewMode : !showSpreadSheet && viewMode=='grid' ? 'quick' : viewMode}
                  label="View"
                  buttons={buttons}
                  Action={internalUpdateGridView}
                  color={color}
                />
                ): null}
                <ColumnsButton
                  label="Columns"
                  hiddenColumnCount={hiddenColumnCount}
                  height="30px"
                  actionIcon={mdiEye}
                  actionIcon2={mdiEyeOff}
                  Action={toggleColumnManager}
                  color={color}
                  isSelected={false}
                />
                <ActionButton
                  label="Bookmarks"
                  actionIcon={mdiBookmarkMultipleOutline}
                  Action={toggleBookMarks}
                  color={color}
                  isSelected={false}
                />
                <ActionButton
                  label="Filter"
                  actionIcon={mdiFilter}
                  Action={showFilter}
                  color={color}
                  isSelected={filters.length > 0 && filters[0].header !== undefined}
                />
              </>
            ) : (
              <>
                <ActionButton
                  label="Refresh"
                  actionIcon={mdiRefresh}
                  height="47px"
                  Action={Refresh}
                  color={color}
                  isSelected={false}
                />
                <ActionButton
                  label="Print"
                  actionIcon={mdiPrinter}
                  Action={null}
                  color={color}
                  isSelected={false}
                />
                <MultiActionButton
                  viewMode={viewMode}
                  label="View"
                  buttons={buttons}
                  Action={internalUpdateGridView}
                  color={color}
                />
                
                <ActionButton
                  label="Bookmarks"
                  actionIcon={mdiBookmarkMultipleOutline}
                  Action={toggleBookMarks}
                  color={color}
                  isSelected={false}
                />

                <ActionButton
                  label="Filter"
                  actionIcon={mdiFilter}
                  Action={showFilter}
                  color={color}
                  isSelected={filters.length > 0 && filters[0].header !== undefined}
                />
              </>
            )}
          </div>


        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

ActionBar.propTypes = {
  name: PropTypes.string.isRequired,
  recordCount: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  Refresh: PropTypes.func.isRequired,
  exportData: PropTypes.func.isRequired,
  // showExport: PropTypes.bool.isRequired,
  updateRowHeight: PropTypes.func.isRequired,
  showFilter: PropTypes.func.isRequired,
};

export default ActionBar;
