/* eslint-disable */
import React, { Component } from 'react';
import Icon from '@mdi/react';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TextButton from '../../../../components/TextButton/TextButton';
import {
  mdiBookmarkMultipleOutline,
  mdiClose,
  mdiInformationOutline,
  mdiDragVertical,
} from '@mdi/js';
import { Input, Popup } from 'semantic-ui-react';
import GBButton from '../../../../components/GBButton/GBButton';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import {
  getBookmarks,
  updateBookmark,
  deleteBookmark,
  getBookmarkByName,
} from '../../../../api/bookmarks';
import Bookmark from './Bookmark';

class Bookmarks extends Component {
  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  state = {
    name: '',
    items: [],
    isLoading: false,
    changes: false,
  };

  async componentDidMount() {
    await this.loadCurrentBookMarks();
  }

  loadCurrentBookMarks = async () => {
    this.setState({ isLoading: true });
    const { tableid, userTabSettings,role,spreadSheetPriv} = this.props;
    let bmarks = await getBookmarks(tableid, userTabSettings.bookmarks);
    if(role < spreadSheetPriv) {
      bmarks=bmarks.filter(itm=>itm.tabsettings.gridView !=='grid')
    }

    this.setState({ items: bmarks, isLoading: false });
  };

  deleteBookmarkLocal = async (bookmarkid) => {
    const { tableid } = this.props;
    await deleteBookmark(bookmarkid, tableid);
    await this.loadCurrentBookMarks();
    this.setState({ changes: true });
  };

  updateBookMarkLocal = async (name, bookmarkid, ispublic, isdefault, tableid) => {
    await updateBookmark(name, bookmarkid, ispublic, isdefault, tableid);
    await this.loadCurrentBookMarks();
    this.setState({ changes: true });
  };

  updateBookmarkContentLocal = async (name,oldname,bookmarkid,tableid) => {
    const {updateBookmarkContent} = this.props;

    let checkName=[];
    if(name !==oldname) {
      checkName = await getBookmarkByName(tableid, name);
    }

    if (checkName.length > 0) {
      toast.info('This name is already in use on this tab. Name must be unique.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 7000,
      });
    } else {
      await updateBookmarkContent(bookmarkid,name);
      await this.loadCurrentBookMarks();
      this.setState({ name: '', changes: true });
    }
  }

  reOrderBookMarks = async () => {
    const { items } = this.state;
    const { updateBookMarkOrder } = this.props;
    const bmarks = items.map((itm) => itm.bookmarkid);
    await updateBookMarkOrder(bmarks);
    this.setState({ changes: true });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(this.state.items, result.source.index, result.destination.index);

    this.setState(
      {
        items,
      },
      () => this.reOrderBookMarks(),
    );
  }

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'white' : 'white',
    padding: 10,
    width: 250,
  });

  grid = 8;

  getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer

    userSelect: 'none',
    width: '850px',
    padding: 2,
    marginLeft: '20px',

    background: isDragging ? `${this.props.color}80` : 'white',
    ...draggableStyle,
  });

  saveBookmarkLocal = async (name) => {
    const { saveBookMark, tableid } = this.props;

    const checkName = await getBookmarkByName(tableid, name);
    if (checkName.length > 0) {
      toast.info('This name is already in use on this tab. Name must be unique.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 7000,
      });
    } else {
      await saveBookMark(name);
      await this.loadCurrentBookMarks();
      this.setState({ name: '', changes: true });
    }
  };

  render() {
    const {
      color,
      close,
      saveBookMark,
      loadBookmark,
      tableid,
      role,
      isPublicZoneBlock,
      toggleShowBookmarkBar,
      closeAndRefresh,
      showBookmarkBar,
      updateBookmarkContent,
    } = this.props;
    const { name, isLoading, items, changes } = this.state;
    return (
      <div>
        <div
          style={{
            display: 'flex',
            padding: '10px',
            flexDirection: 'row',
            height: '40px',
            backgroundColor: color,
            color: 'white',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              marginLeft: '10px',
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Icon path={mdiBookmarkMultipleOutline} size="25px" />
            <div style={{ marginLeft: '5px', fontSize: '15px' }}>Bookmarks </div>
          </div>
          <TextButton
            text="Hide bookmarks panel"
            Action={changes ? closeAndRefresh : close}
            ActionValue={items}
            textColor="white"
            icon={mdiClose}
            hoverColor="#FFFFFF80"
          />
        </div>
        <div>
          <div
            style={{
              marginLeft: '10px',
              marginTop: '10px',
              display: 'flex',
              justifyContent: 'space-evenly',
              marginRight: '30px',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ marginRight: '5px' }}>Create new bookmark</div>
              <Popup
                content={
                  <div>
                    Bookmarks will save your view which includes the fields displayed, their order
                    and desired width, as well as any filters applied at the time when the bookmark
                    is created. <p></p>
                    <p>
                      All users can create private bookmarks, but users with builder rights inside a
                      Block can also make a bookmark public for other users to access as well as set
                      the public default, which is the "fallback starting point" for all users when
                      they access the tab.
                    </p>
                  </div>
                }
                trigger={<Icon path={mdiInformationOutline} size="20px" />}
              />
            </div>

            <div style={{ width: '300px' }}>
              <Input
                fluid
                value={name}
                onChange={(e, data) => this.setState({ name: data.value })}
              />
            </div>
            <div>
              <GBButton
                text="Save"
                Action={name !== '' && !isPublicZoneBlock ? this.saveBookmarkLocal : null}
                ActionValue={name}
                color={name != '' && !isPublicZoneBlock ? color : `${color}80`}
              />{' '}
            </div>
          </div>
          {/* <hr style={{ borderTop: `1px solid ${color}` }} /> */}
          <div
            style={{
              paddingLeft: '10px',
              marginTop: '10px',
              marginBottom: '10px',
              backgroundColor: `${color}1A`,
              height: '25px',
              borderTop: `1px solid ${color}`,
              alignSelf: 'center',
            }}
          >
            <GBSwitch
              color={color}
              text="Show bookmarks bar"
              isChecked={showBookmarkBar}
              Action={toggleShowBookmarkBar}
            />
          </div>

          <div
            style={{
              marginLeft: '30px',
              marginRight: '40px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '220px' }}>Bookmark (click to load)</div>
            <div style={{ width: '130px' }}>Availability</div>
            <div style={{ width: '200px' }}>Added by</div>
            <div style={{ width: '50px' }}>Actions</div>
          </div>

          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={this.getListStyle(snapshot.isDraggingOver)}
                >
                  {items.length > 0 &&
                    !isLoading &&
                    this.state.items.map((item, index) => (
                      <Draggable
                        key={item.bookmarkid.toString()}
                        draggableId={item.bookmarkid.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={this.getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            )}
                          >
                            <Bookmark
                              role={role}
                              color={color}
                              tableid={tableid}
                              deleteMark={this.deleteBookmarkLocal}
                              item={item}
                              loadBookmark={loadBookmark}
                              update={this.updateBookMarkLocal}
                              updateBookmarkContent={this.updateBookmarkContentLocal}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    );
  }
}

export default Bookmarks;
