/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import Icon from '@mdi/react';
import DOMPurify from 'dompurify';
import {
  mdiCheckCircle,
  mdiDragVertical,
  mdiCheck,
  mdiClose,
  mdiInformationOutline,
  mdiAccountOutline,
} from '@mdi/js';
import { Checkbox, Popup, Input, Dropdown } from 'semantic-ui-react';
import TextareaAutosize from 'react-autosize-textarea';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Moment from 'react-moment';
import moment from 'moment';
import numbro from 'numbro';
import Modal from 'react-modal';

import { AccountContext } from '../../providers/AccountProvider';
import DataList from '../..//containers/Block/components/DataList/DataList';
import UserRender from '../../containers/Block/components/Renderers/UserRender';
import DisplayLookupValues from '../../containers/Block/components/Renderers/DisplayLookupValues';
import SelectedUserItem from '../UserDropdown/SelectedUserItem';
import UserDropdown from '../UserDropdown/UserDropdown';
import GBPhone from '../GBPhone/GBPhone';
import GBButton from '../GBButton/GBButton';
import { getTableAPI, getUserTable } from '../../api/tables';
import {getOptionsAPI} from '../../api/options'
import MaskedText from '../MaskedText/MaskedText';
import CustomEditor from '../CustomEditor/CustomEditor';
import FormWrapper from '../../containers//Block/components/Forms/FormWrapper';


class TextControl extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.editorRef = React.createRef();
  }
  static contextType = AccountContext;

  state = {
    edit: false,
    valid: true,
    startDate:
      this.props.value !== undefined && this.props.value !== null
        ? moment(this.props.value).toDate()
        : null,
    tableData: null, //thse 3 fields are just used for single relational to pass to Datalist.
    filters: [],
    showDatalist: false, //10-5-22 adding to support using datalist while staying in edit mode
    updatedValue: null, //10-5-22 adding to support showing updated value while still in edit mode.
    isChecked: this.props.value && this.props.value.toString().toLowerCase() === 'true',
    users: [],
    unmaskValue: false,
    searchParams: null, //11-2-23 adding support for updating a web form using formwrapper
    optionData: [],
    optionValue: this.props.value !== undefined && this.props.value !== null ? null : '|Select an option',
    dateZIndex:0,
  };


  // 11-7-2023 We don't need this since we dont' do mentions in tinymce
  async componentDidMount() {
    // 11-9-23 commented out, no need for users as we don't implement mentions yet.
    // if (this.props.colInfo.uitype === 13 && this.props.editMode) {
    //   await this.fetchUsers();
    // }
    
    if(this.props.colInfo.uitype===6 || this.props.colInfo.uitype===7) {
      await this.fetchOptionData();
    }
  }

  getDefaultOptionValue =() =>{
    const {colInfo,value} = this.props;

   if(value !==null && value !==undefined && value.length===0) {
    return null
   }

    let val =null;
    if(colInfo.uitype===7 && value !==null && value !==undefined) {
       val = value[0].optionid;
    } else if (colInfo.uitype===6 && value !==null && value !==undefined) {
      val = value.map(itm=>itm.optionid)
    }
    return val;
  }

  getDropDownText =() =>{
    const {optionValue} = this.state;
    const {value} = this.props;

    if(value !==null && value.length>0) {
       return null
    } else {
      return 'Select option'
    }
  }

  searchItems =(options, query) =>{
    let textOptions=[];
    const {optionData} = this.state;

    optionData.filter(el=>el.option.toLowerCase().startsWith(query)).map(itm=>{
      const control=<div style={{backgroundColor:itm.attributes.color,padding:'5px',color:itm.attributes.text,borderRadius:'7px',width:'max-Content'}}>{itm.option}</div>
      textOptions.push({key:itm.optionid, value:itm.optionid,text:control})
    })
 
    return textOptions
  }

  // fetchUsers = async () => {
  //   const usr = await getUsersAPI([], false);
  //   let userMentions = [];
  //   usr.map((u) => {
  //     userMentions.push({
  //       key: u.userid,
  //       value: `${u.email}`,
  //       text: `${u.email} (${u.firstname} ${u.lastname})`,
  //       url: '#',
  //     });
  //   });
  //   this.setState({ users: userMentions });
  // };

  fetchOptionData = async () =>{
    const {colInfo,tableid} = this.props;
    const result = await getOptionsAPI(colInfo.data,tableid,'list');
    this.setState({optionData:result});
  }



  renderLabel = (label) => {
    const { value, text } = label;

    const customLabel = (
      <div 
      style={{
        // padding: '1px',
        // border:'1px solid #1a1a1a',
        marginLeft:'5px',
        // backgroundColor:'#000000',
        color: 'white',
        borderRadius: '4px',
        display: 'inline-flex', // Use 'inline-flex' to make sure the label and the remove icon are in the same line
        alignItems: 'center', // Center items vertically
        marginRight: '8px', // Add some space between labels
      }}>
        {text}
      </div>
    );

    return {
      color: '#eee',
      content: customLabel
    };

  }

  displayOptions = () => {
    const {optionData} = this.state;
    let textOptions=[];

    optionData?.map(itm=>{
      const control=<div style={{backgroundColor:itm.attributes.color,padding:'5px',color:itm.attributes.text,borderRadius:'7px',whiteSpace:'wrap', maxWidth:'max-Content'}}>{itm.option}</div>
      textOptions.push({key:itm.optionid, value:itm.optionid,text:control})
    })
    return textOptions
  }

  displayListOptions =(items) => {

    if(items !== null) {
      return (
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
          {items?.map((itm, index) => (
            <div 
              key={index}
              style={{
                display: 'inline-block', // or just 'block' if you don't need inline-level properties
                backgroundColor: itm.attributes.color,
                paddingLeft: '5px',
                paddingRight:'5px',
                paddingTop:'2px',
                paddingBottom:'2px',
                color: itm.attributes.text,
                borderRadius: '7px',
                margin: '5px', // Add some space between the items
              }}>
              {itm.data}
            </div>
          ))}
        </div>
      );
    }
    return null; 
    // if(items !==null) {
    //   return (
    //     <div style={{display:'inline-flex', flexWrap: 'wrap'}}>
    //     {items.map((itm,index)=>(
    //     <div key={index} style={{backgroundColor:itm.attributes.color,padding:'5px',color:itm.attributes.text,borderRadius:'7px',width:'max-content'}}>{itm.data}</div>
    //   ))}
    //   </div>
    //   )
    // }
    // return null;
  }


  handleChange = (e) => {
    const { valid, isChecked } = this.state;
    this.props.updateValue(e.target.id, e.target.value);
  };

  handleTextArea = (id, value) => {
    this.props.updateValue(id, value);
  };

  handleCheckBox = (id, value) => {
    const { isChecked } = this.state;
    this.setState({ isChecked: !isChecked });
    this.props.updateValue(id, value.toString());
  };

  handlePhoneChange = (id, value) => {
    this.props.updateValue(id, value);
  };

  handleOptionChange =(newvalues) => {
    const { colInfo, updateValue } = this.props;
    const {optionData} = this.state;
    let tempValue=newvalues;

    const tempData = structuredClone(optionData)
    /*  11-8-23 moving to semantic Dropdown, it keeps selectedItems internally with the value
    however, when saving items, we need to create/save the full option {data:'test',optionid:'1',attributes:{}}
    this format is expected when saving options and also provides the attribute data needed to create the history entry
    */

    //This logic supports that when they hit clear option, it will set the selected text to "Selec optin",
    //otherwise, we set this to null (text property uses this) and when text={}, then it displays the selected item
    this.setState({optionValue:tempValue==='' ? 'Select option' : null})

    // if newvalues='', this means they clicked the "clear" button.
    // this should set the value=[], which the updateRecord is expecting. 
    if(tempValue==='') {
      tempValue=[];
      updateValue(colInfo.data, []);
      return false
    }

    

    if(colInfo.lookup==='single') {
      const idx = tempData.findIndex(itm=>itm.optionid===newvalues);
      if(idx !==-1) {
        const item =tempData[idx]
        item.data=item.option;
        updateValue(colInfo.data, [item]);
      }
    } else if(colInfo.lookup==='multiple') {
      const updatedValues = tempData.filter(itm=>newvalues.includes(itm.optionid))
      updatedValues.map(el=>{
        el.data = el.option
        delete el.option
      })
      updateValue(colInfo.data, updatedValues);
    }
  };

  toggleShowForm = (val, params) => { 
    
    if(this.props.editMode) {
      return false;
    }
    
    const {searchParams } = this.state;
    this.setState({searchParams: searchParams===null ? params : null})
  }

  closeModalandRefresh = async () =>{
    console.log('call update to refresh detail view.')
    await this.props.submitChanges(true);
    this.setState({searchParams:null})
  }

  handleCollaboratorChange = async (newvalues) => {
    const { colInfo, updateValue } = this.props;
    await updateValue(colInfo.data, newvalues);
  };

  handleDateChange = async (newdate) => {
    const { colInfo, updateValue } = this.props;

    if(newdate !==null && newdate.getHours()===0 && newdate.getMinutes()===0 && colInfo.includeTime) {
      const d = new Date();
      newdate.setHours(d.getHours())
      newdate.setMinutes(d.getMinutes())
    } 

    this.setState({ startDate: newdate });
    updateValue(colInfo.data, newdate !==null ? newdate.toISOString() : null);
  };

  handleDateSelected = async (newdate) => {
    const { colInfo, updateValue } = this.props;
    if (this.state.startDate && newdate.toISOString() == this.state.startDate.toISOString()) {
      this.setState({ startDate: null });
      await updateValue(colInfo.data, null);
    }
  };

  handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      await this.props.submitChanges();
      this.setState({ edit: false });
    }
  };

  editorChange=(id,content) => {
    this.props.updateValue(id, content);
  }

  handleDataListClose = () => {
    this.setState({ edit: false, showDatalist: false });
  };

  toggleEditMode = async () => {
    const { edit, showDatalist } = this.state;
    const { colInfo } = this.props;

    if (colInfo.uitype === 1) {
      await this.handelGetRelationalTable();
    }
    this.setState({ edit: !edit, showDatalist: !showDatalist });
  };

  handleFocus = () => {
    this.setState({ dateZIndex:100 });
  };

  handleBlur = () => {
    this.setState({ dateZIndex:0 });
  };

  handelGetRelationalTable = async () => {
    const { colInfo, rowId, tableid, value,role } = this.props;
    const { userInfo, signOut,} = this.context;
    const { relValue } = this.state;
    // const tableData = await getTableAPI(colInfo.source.lookuptableid);
    const tableData = await getUserTable(colInfo.source.lookuptableid,colInfo.bookmarkName,role,userInfo.blocks,false);

    let filters = [
      {
        field: 'id',
        header: 'id',
        type: 'relation',
        displayfield: 'id',
        sourcefield: colInfo.data,
        selectmode: 'single',
        renderlookup: colInfo.source.lookup,
        operator: value === null || value.length === 0 ? 'not' : 'and',
        value: rowId,
        uitype: 100, // set to non system number. Used in buildSqlQuery to process the id filter.
        lookupfield: `${colInfo.source.returnfield}_join`,
        originalTableId: tableid,
      },
    ];

    this.setState({ tableData: tableData, filters });
  };

  AddItem = async (id, field) => {
    const { colInfo, updateValue } = this.props;
    const newValue = [{ id: id, data: field, type: 2 }];
    await updateValue(colInfo.data, newValue, colInfo.uitype);
    // await this.ApplyChanges()
    this.setState({ edit: false, showDatalist: false, updatedValue: newValue });
  };

  RemoveItem = async (id, mode, field) => {
    const { colInfo, updateValue, value, rowId, tableid } = this.props;
    const newValue = [{ id: id, data: field, type: -1 }];

    await updateValue(colInfo.data, newValue, colInfo.uitype);

    let filters = [
      {
        field: 'id',
        header: 'id',
        type: 'relation',
        displayfield: 'id',
        sourcefield: colInfo.data,
        selectmode: 'single',
        renderlookup: colInfo.source.lookup,
        operator: 'not',
        value: rowId,
        uitype: 100, // set to non system number. Used in buildSqlQuery to process the id filter.
        lookupfield: `${colInfo.source.returnfield}_join`,
        originalTableId: tableid,
      },
    ];

    //10-5-22, when they remove a single select item and click done, I need a way to have the detail page
    //show no value, before the changes are saved. it needs to be different than null, because there is logic
    //that is based upon the user udpating value and using that value. Here we set it to -1, so the render
    //will display "Choose one".

    this.setState({ filters, updatedValue: -1 });
    // this.props.submitChanges();
  };

  ApplyChanges = async () => {
    // this.props.submitChanges();
    this.setState({ edit: false, showDatalist: false });
  };


  Gettype = (uitype) => {
    switch (uitype) {
      case 2:
        return 'text';
        break;
      case 23:
        return 'number';
        break;
      case 10:
        return 'text';
        break;
      case 11:
        return 'url';
        break;
      default:
        break;
    }
  };

  formatNumber = (number, numericFormat) => {
    numbro.setLanguage(numericFormat.culture);
    return number ? numbro(number).format(numericFormat.pattern) : null;
  };

  createRelationaltags = (value, colInfo) => {
    const { loadRelatedRecord, blockid, canEdit, editMode } = this.props;

    if (value !== null && value?.length > 0) {
      return (
        value.length > 0 &&
        value.map((el) =>
          el.data ? (
            <>
              <div
                key={el.id !== null ? el.id : el.optionid}
                css={{
                  cursor: 'pointer',
                  display: 'inline-flex',
                  width: 'max-content',
                  alignItems: 'flex-start',
                  margin: '3px',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                  borderRadius: '7px',
                  backgroundColor: `${this.props.color}0D`,
                  // color: 'white',
                  '&:hover': {
                    backgroundColor: this.props.color,
                    transition: 'all .3s ease',
                    color:'white'
                  },
                }}
              >
                <div
                  onClick={
                    !editMode
                      ? () =>
                          loadRelatedRecord(
                            el.id !== undefined ? el.id : el.optionid,
                            colInfo.source.lookuptableid,
                            blockid,
                            'add',
                          )
                      : this.toggleEditMode
                  }
                >
                  {el.data}{' '}
                </div>
                {/* <div
                  onClick={canEdit ? this.toggleEditMode : null}
                  css={{
                    marginLeft: '10px',
                    svg: { color: 'white' },
                    '&:hover': {
                      svg: {
                        backgroundColor: 'white',
                        borderRadius: '100%',
                        color: this.props.color,
                        transition: 'all .3s ease',
                      },
                    },
                  }}
                >
                  <Icon path={mdiClose} size="15px" />
                </div> */}
              </div>
            </>
          ) : null,
        )
      );
    }
    return null;
  };

  toggleMask = () => {
    const { unmaskValue } = this.state;
    this.setState({ unmaskValue: !unmaskValue });
  };

  DisplayInputControl = () => {
    const {
      colInfo,
      value,
      color,
      updateValue,
      tableid,
      blockid,
      rowId,
      role,
      canEdit,
      editMode,
      index,
    } = this.props;
    const {
      valid,
      isChecked,
      tableData,
      filters,
      showDatalist,
      updatedValue,
      unmaskValue,
      searchParams
    } = this.state;

    const { userInfo } = this.context;
    if (
      colInfo.uitype === 2 ||
      colInfo.uitype === 23 ||
      colInfo.uitype === 10 ||
      colInfo.uitype === 11
    ) {
      return (
        <input
          tabIndex={index}
          ref={this.input}
          style={{
            border: valid ? '1px solid #E5E5E6' : '1px solid red',
            borderRadius: '7px',
            height: '30px',
            minWidth: '100%',
            outline: 'none',
            width: '90%',
          }}
          onKeyPress={this.handleKeyPress}
          id={colInfo.data}
          type={this.Gettype(colInfo.uitype)}
          defaultValue={value}
          onChange={this.handleChange}
        />
      );
    }

    if (colInfo.uitype === 17) return value;

    if (colInfo.uitype === 3) {
      return (
        <TextareaAutosize
          style={{ border: valid ? '1px solid #E5E5E6' : '1px solid red', borderRadius: '7px' }}
          tabIndex={index}
          // cols={100}
          rows={5}
          defaultValue={value}
          id={colInfo.data}
          onChange={(e) => this.handleTextArea(colInfo.data, e.target.value)}
        />
      );
    }
    if (colInfo.uitype === 14) {
      const { unmaskRole, role } = this.props;
      const canView = unmaskRole === undefined || unmaskRole < role;
      return (
        <Input
          tabIndex={index}
          action={
            canView ? { icon: !unmaskValue ? 'eye' : 'eye slash', onClick: this.toggleMask } : null
          }
          type={!unmaskValue ? 'password' : 'text'}
          fluid
          id={colInfo.data}
          onChange={this.handleChange}
          // onChange={(e, data) => this.setState({ value: data.value })}
          // value={value !== null ? value : null}
          defaultValue={value}
          placeholder="Enter Text"
        />
      );
    }
    if (colInfo.uitype === 13) {
      
      return (
        <div style={{position:'relative', zIndex:this.state.dateZIndex}}>
          <CustomEditor handleFocus={this.handleFocus} handleBlur={this.handleBlur}  field={colInfo.data} initValue={value} updateContent={this.editorChange} callingComponent="textcontrol" />
          </div>
      );
    }

    if (colInfo.uitype === 5) {
      return (
        <Checkbox
          tabIndex={index}
          checked={isChecked}
          onChange={(e, data) => this.handleCheckBox(colInfo.data, data.checked)}
        />
      );
    }
    if (colInfo.uitype === 9) {
      return (
        <GBPhone
          focus={false}
          tabIndex={index}
          id={colInfo.data}
          value={value}
          handleChange={this.handlePhoneChange}
        />
      );
    }
    if (colInfo.uitype === 6 || colInfo.uitype === 7 ) {
      return (
        <div 
          css={{ 
            position:'relative', 
            height: '100%',
            overflow:'visible',
            margin:'10px'}}>
          
        <Dropdown 
            placeholder="Select item"
            // text={this.props.value !== null  && this.props.value.length>0 ? null : 'Select option'}
            text={this.state.optionValue}
            key={colInfo.data}
            scrolling={colInfo.lookup==='multiple'}
            multiple={colInfo.lookup==='multiple'}
            defaultValue={this.getDefaultOptionValue()}
            search={this.searchItems} 
            // open={true}
            onOpen={this.handleOpen}
            onClose={this.handleClose}
            renderLabel={this.renderLabel}
            fluid 
            clearable={colInfo.lookup==='single'}
            selection 
            options={this.displayOptions()} 
            onChange={(e,data)=>this.handleOptionChange(data.value)} 
            />
          </div>
   

        // <GBDropdownComponent
        //   tabIndex={index}
        //   id={colInfo.data}
        //   field={colInfo.data}
        //   tableid={tableid}
        //   enableColors={colInfo.enableColors}
        //   gridExpandState={false}
        //   source={colInfo.source}
        //   color={color}
        //   width="100%"
        //   optionValues={value}
        //   uitype={colInfo.uitype}
        //   lookup={colInfo.lookup}
        //   updateValue={this.handleOptionChange}
        //   callingComponent="Details"
        // />
      );
    }
    if (colInfo.uitype === 8) {
      return (
        <div style={{height:'100%',margin:'10px'}}>

        <UserDropdown
          field={colInfo.data}
          tabIndex={index}
          uitype={8}
          blockid={blockid}
          updateFilter={this.handleCollaboratorChange}
          selectedUsers={value}
          color={color}
          lookup={colInfo.lookup}
          width="100%"
          type={'restricted'}
          userType={colInfo.userType ?? 'zone'}
          definedUsers={colInfo.definedUsers ?? null}
          callingComponent={"detailview"}
        />
        </div>
      );
    }
    if (colInfo.source && colInfo.source.lookupuitype === 8) {
      return (
        value !== null && value !==undefined &&
        value.map((usr) => (
          <SelectedUserItem
            item={usr}
            backgroundColor={null}
            color={usr.attributes.text}
            Action={null}
          />
        ))
      );
    }
    if (colInfo.uitype === 22) {
      return (
        // <div style={{position:'relative',zIndex:this.state.dateZIndex}}>
        <DatePicker
          className="custom-datepicker"  
          // tabIndex={index}
          // inline
          selected={this.state.startDate}
          onChange={(date) => this.handleDateChange(date)}
          // onChange={(date) => this.handleDateChange(date)}
          // onFocus={this.handleFocus} 
          // onBlur={this.handleBlur}
          timeInputLabel="Time:"
          dateFormat= {colInfo.includeTime ? 'Pp' : 'P'}
          scrollableYearDropdown
          minDate={moment().subtract(80, 'years')._d}
          dropdownMode="select"
          showYearDropdown
          showTimeInput={colInfo.includeTime}
          shouldCloseOnSelect={!colInfo.includeTime}
        />
      //  </div>
      );
    }
    if (colInfo.uitype === 1 && tableData !== null && showDatalist) {
      return (
        <Modal
          shouldCloseOnOverlayClick={false}
          isOpen={true}
          // onAfterOpen={this.afterOpenModal}
          // onRequestClose={this.closeModal}
          style={{
            overlay: {
              position: 'fixed',
              // zIndex:1,
              top: 50,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0)',
            },
            content: {
              width: '830px',
              height: 'calc(100vh - 145px)',
              top: 10,
              left: window.innerWidth / 4,
              border: `.3px solid ${color}`,
              background: '#fff',
              overflow: 'hidden',
              zIndex: 5010,
              WebkitOverflowScrolling: 'touch',
              borderRadius: '10px',
              outline: 'none',
              padding: '0px',
            },
          }}
          contentLabel="Example Modal"
        >
          <div>
            <div
              style={{
                height: '42px',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '20px',
                backgroundColor: color,
              }}
            >
              <Icon path={mdiAccountOutline} size="20px" color="white" />
              <div style={{ color: 'white', paddingLeft: '10px' }}>
                Relate new record(s) to {tableData.name}: {tableData.recordname}
              </div>
            </div>

            <DataList
              displayMode={value === null ? 'addition' : 'review'}
              tableid={colInfo.source.lookuptableid}
              sourcetableid={tableid}
              renderfield={colInfo.source.lookupfield}
              nestedFields={colInfo.source.nestedFields}
              blockid={colInfo.source.blockid}
              field={colInfo.data}
              filters={filters}
              tableinfo={tableData.tableinfo}
              color={color}
              AddItem={this.AddItem}
              RemoveItem={this.RemoveItem}
              Apply={this.ApplyChanges}
              role={role}
              Close={this.handleDataListClose}
              selectMode={colInfo.lookup}
              callingComponent="SingleDetail"
              canEdit={canEdit}
              relationalSecurity={colInfo.useSecurity}
            />
          </div>
        </Modal>
      );
    }
    if (
      colInfo.uitype === 1 &&
      !showDatalist &&
      (value !== null || updatedValue !== null) &&
      updatedValue !== -1
    ) {
      return this.createRelationaltags(updatedValue !== null ? updatedValue : value, colInfo);
    } else if (
      colInfo.uitype === 1 &&
      !showDatalist &&
      (value === null || updatedValue === null || updatedValue === -1)
    ) {
      return (
        <div style={{height:'35px'}}>
        <GBButton text="Choose one" textColor={color} color="white" border={`1px solid ${color}`} textHoverColor={`${color}80`} hoverBorder={`1px solid ${color}80`}  Action={this.toggleEditMode} />
        </div>

        // <div style={{ cursor: 'pointer',border:`1px solid ${color}`, borderRadius:'10px',padding:'3px',paddingLeft:'5px',paddingRight:'5px',width:'90px' }} onClick={canEdit ? this.toggleEditMode : null}>
        //   Choose one
        // </div>
      );
    }

    if ([16,18].includes(colInfo.uitype) && colInfo.renderandedit === 'LookupValues' && searchParams ===null ) {
      return (
        <DisplayLookupValues
          value={value}
          isEditor={false}
          isRenderer={true}
          uitype={colInfo.uitype===16 ? colInfo.uitype : colInfo.source.lookupuitype}
          numericFormat={colInfo.numericFormat}
          dateFormat={colInfo.dateFormat}
          UTC={colInfo.UTC}
          color={color}
          fieldType={colInfo.fieldType} //added 4-24-23 to support formula fields.
          specialfunction={colInfo.specialfunction}
          useButtonDisplay={colInfo.useButtonDisplay}
          buttonLabel={colInfo.buttonLabel}
          callingComponent="detail"
          showForm={this.toggleShowForm}
        />
      );
    }
    if (colInfo.uitype === 24 || colInfo.uitype === 25) {
      return (
        <UserRender Action={null} uitype={colInfo.uitype} field={colInfo.data} value={value} />
      );
    }
    if ([19, 20, 22].includes(colInfo.uitype)) {
      if (value !== null) {
        return (
          <Moment local format={colInfo.dateFormat}>
            {value}
          </Moment>
        );
      } else {
        return <div>test</div>;
      }
    }
    // show webform 
    if(colInfo.uitype===16 && searchParams !==null) {   
      return (
        <Modal
          shouldCloseOnOverlayClick={false}
          isOpen={true}
          // onAfterOpen={this.afterOpenModal}
          // onRequestClose={this.closeModal}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0)',
            },
            content: {
              // position: 'fixed',
              width: '800px',
              // height: this.state.modalHeight,
              height: 'calc(100vh -100px)',
              // maxHeight:'500px',
              top: 10,
              left: window.innerWidth / 4,
              // right: '140px',
              // bottom: '140px',
              border: `.3px solid ${color}`,
              background: '#fff',
              overflow: 'auto',
              zIndex: 5010,
              WebkitOverflowScrolling: 'touch',
              borderRadius: '10px',
              outline: 'none',
              padding: '0px',
              // boxShadow: '0px 3px 6px #707070',
            },
          }}
          contentLabel="Example Modal"
        >
          <div>
          <FormWrapper
              token={searchParams.x}
              searchParams={searchParams}
              close={this.toggleShowForm}
              executeAndClose={this.closeModalandRefresh}
              color={color}
              addedby={userInfo.id}
              role={role}
            />
          </div>
        </Modal>
      )
    }

  };

  DisplayValue = () => {
    const { colInfo, value, blockid, tableData, role, unmaskRole,color } = this.props;
    const {searchParams} = this.state;
    const { userInfo } = this.context;

    if (colInfo.uitype === 2 || colInfo.uitype === 9 || colInfo.uitype === 17) return value;

    if (colInfo.uitype === 10) {
      return (
        <a href={'mailto:' + value} target="_newtab">
          {value}
        </a>
      );
    }
    if (colInfo.uitype === 5) {
      if (value !==null && value?.toString().toLowerCase() === 'true') {
        return <div style={{ color: 'green', fontSize: '14px' }}>✔</div>;
      } else {
        return '';
      }
    }

    if (colInfo.uitype === 11) {
      if(value !== null && value !==undefined && colInfo.useButtonDisplay !==undefined) {
        const leftPosition = (screen.width - 800) / 2;
        const topPosition = (screen.height - 600) / 2;
        const windowFeatures = `resizable,height=600,width=800,left=${leftPosition},top=${topPosition}`;

        return(
          <button style={{border:`1px solid ${this.props.color}`,outline:'none',paddingTop:'5px', paddingBottom:'4px',backgroundColor:this.props.color,color:'white',borderRadius:'5px'}} onClick={()=> window.open(`${value}`, 'pagename',windowFeatures)}>{colInfo.buttonLabel}</button>
        )
      }
      else if (value !== null && value !==undefined) {
        const url = value.toString().startsWith('http') ? value : `http://${value}`;
        
        return (
          <a style={{wordBreak:'break-all',width:'100%'}} href={url} target="_newtab">
            {value}
          </a>
        );
      }
    }
    if (colInfo.uitype === 23) return this.formatNumber(value, colInfo.numericFormat);

    if (colInfo.uitype === 3) {
      if (value !== null && value !== undefined) {
        return value.split('\n').map((item, index) => (
          <span key={index}>
            {item}
            <br />
          </span>
        ));
      }
    }
    if (colInfo.uitype === 14) {
      if (value !== null && value !== undefined) {
        return (
          <MaskedText
            value={value}
            maskType={colInfo.maskType ?? 'full'}
            uniformLength={colInfo.uniformLength ?? '8'}
            maskCharacters={colInfo.maskCharacters ?? '0'}
            canView={unmaskRole === undefined || unmaskRole < role}
          />
        );
      }
    }
    if (colInfo.source && colInfo.source.lookupuitype === 14) {
      if (value !== null && value !== undefined) {
        return value.map((el) => (
          el.data !==null &&
          <MaskedText
            value={el.data}
            maskType={colInfo.maskType}
            uniformLength={colInfo.uniformLength}
            maskCharacters={colInfo.maskCharacters}
            canView={unmaskRole === undefined || unmaskRole < role}
          />
        ));
      }
    }

    if (colInfo.uitype === 13 && value !== null && value !== undefined) {      
  
      return (
        <div style={{ height:'100%', width: '100%',  }} dangerouslySetInnerHTML={{ __html: value}} />
      );
    }

    if (colInfo.uitype === 24 || colInfo.uitype === 25) {
      return (
        <UserRender Action={null} uitype={colInfo.uitype} field={colInfo.data} value={value} />
      );
    }
    if ([19, 20, 22].includes(colInfo.uitype)) {
      if (value !== null) {
        return (
          <Moment local format={colInfo.dateFormat}>
            {value}
          </Moment>
        );
      } else {
        return <div></div>;
      }
    }
    if (
      [16,18].includes(colInfo.uitype) &&
      colInfo.renderandedit === 'LookupValues' &&
      colInfo.source?.lookupuitype !== 14
      && searchParams===null
    ) {
      return (
        <DisplayLookupValues
          value={value}
          isEditor={false}
          isRenderer={true}
          uitype={colInfo.uitype===16 ? colInfo.uitype : colInfo.source.lookupuitype}
          numericFormat={colInfo.numericFormat}
          dateFormat={colInfo.dateFormat}
          UTC={colInfo.UTC}
          color={color}
          fieldType={colInfo.fieldType}
          specialfunction={colInfo.specialfunction}
          useButtonDisplay={colInfo.useButtonDisplay}
          buttonLabel={colInfo.buttonLabel}
          callingComponent="detail"
          showForm={this.toggleShowForm}
        />
      );
    }
    if (colInfo.uitype === 6 || colInfo.uitype === 7) {
      return this.displayListOptions(value);
    }
    if (colInfo.uitype === 8 || (colInfo.source && colInfo.source.lookupuitype === 8)) {
      return (
        value &&
        value.map((usr) => (
          <SelectedUserItem
            item={usr}
            backgroundColor={null}
            color={usr.attributes.text}
            Action={null}
          />
        ))
      );
    }
    if (colInfo.uitype === 1 && colInfo.lookup === 'single') {
      return this.createRelationaltags(value, colInfo);
    }

     // show webform 
     if(colInfo.uitype===16 && searchParams !==null) {
      return (
        <Modal
          shouldCloseOnOverlayClick={false}
          isOpen={true}
          // onAfterOpen={this.afterOpenModal}
          // onRequestClose={this.closeModal}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0)',
            },
            content: {
              // position: 'fixed',
              width: '800px',
              // height: this.state.modalHeight,
              height: 'calc(100vh -100px)',
              // maxHeight:'500px',
              top: 10,
              left: window.innerWidth / 4,
              // right: '140px',
              // bottom: '140px',
              border: `.3px solid ${color}`,
              background: '#fff',
              overflow: 'auto',
              // zIndex: 5010,
              WebkitOverflowScrolling: 'touch',
              borderRadius: '10px',
              outline: 'none',
              padding: '0px',
              // boxShadow: '0px 3px 6px #707070',
            },
          }}
          contentLabel="Example Modal"
        >
          <div>
            <FormWrapper
              token={searchParams.x}
              searchParams={searchParams}
              close={this.toggleShowForm}
              executeAndClose={this.closeModalandRefresh}
              color={color}
              addedby={userInfo.id}
              role={role}
            />
          </div>
        </Modal>
      )
    }

    return '';
  };

  render() {
    const {
      value,
      colInfo,
      updateValue,
      role,
      submitChanges,
      alignControl = false, // 9-16-2020  - AlignControl =true is used from userProfile page and other plaecs that need to display simple fields without the formatting. Trying to strip out formatting so appears like normal field
      fontWeight = 'bold',
      showLabel=true,
      loadRelatedRecord,
      canEdit,
      editMode,
    } = this.props;
    const { edit } = this.state;
  
    return (
      <div
        css={{
          display:
            editMode ||
            ([16,18].includes(colInfo.uitype) && Array.isArray(value) && value !== null && value.findIndex(itm=>itm.data !==null) !== -1)  ||
            (![5,18,16].includes(colInfo.uitype) && value !== null) ||
            (colInfo.uitype === 5 && value !==null && value?.toString().toLowerCase() === 'true')
              ? 'flex'
              : 'none',
          color: '#312602',
          flexDirection: 'row',
          position:'relative',
          alignItems: [2,3,13,16].includes(colInfo.uitype) ||
            [2,3,13,16].includes(colInfo?.source?.lookupuitype)
              ? 'flex-start'
              : 'center',
          justifyContent: 'center',
          // alignItems: 'flex-start',
          // width:'calc(100vw - 700px)',
          marginBottom: '5px',
          marginLeft: !alignControl ? '20px' : '0px',
          marginRight: !alignControl ? '35px' : '0px',
        }}
      >
        <div
          style={{
            display: 'flex',
            // alignItems: colInfo.uitype === 3 ? 'flex-start' : 'center',
            justifyContent: !alignControl ? 'flex-end' : null,
            // minWidth: !alignControl ? '200px' : null,
            width: !alignControl ? '200px' : null, // 5-24-2022 changed to width, instead of minWidth to ensure formatting stays consistence.
            whiteSpace: alignControl ? 'nowrap' : null,
            fontWeight: fontWeight,
            textAlign: 'right',
            marginLeft: !alignControl ? '10px' : '0px',
            // border: '1px solid transparent',
            marginRight: '0px',
            // backgroundColor:'#aaa',
          }}
        > 
        {showLabel ? (
        <span>
          {colInfo.callout !== undefined && colInfo.callout !== '' ? (
            <>
              <Popup
                content={colInfo.callout}
                trigger={<Icon path={mdiInformationOutline} size="20px" />}
              />
              <span style={{ width: '3px' }}></span>
            </>
          ) : null}

          {colInfo.header}
          </span>
        ): null}

        </div>
        <div
          css={{
            width: '100%',
            position:'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            overflow: 'visible',
            minWidth: !alignControl ? null : '200px',
            justifyContent: !editMode ? 'space-between' : 'flex-start',
            border: '1px solid transparent',
            // backgroundColor:
            //   colInfo.uitype === 18 && colInfo.source.lookupuitype !== 13 ? '#F1EFEF' : null,
            // border:'1px solid yellow',
            minHeight: '20px',
            paddingLeft: '5px',
          }}
        >
          {!editMode ? (
            <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
              {this.DisplayValue()}
            </div>
          ) : null}
          {editMode ? (
            <div
              style={{overflow:'visible', display: 'flex', flexDirection: 'column', width: '100%',position:'relative',height:'100%'}}
            >
              {this.DisplayInputControl()}
            </div>
          ) : null}

          {role === 3 && editMode ? (
            <div>
              <Icon path={mdiDragVertical} size="20px" />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default TextControl;
