/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {getSimpleRows} from '../../api/tables'
import CreateRecordUrl from '../../utils/CreateRecordUrl';
import Global from '../../clients/global';

const MultiRowView = ({blockid, tableid, ids}) => {

    /***** 10-7-24 Created to show multi-insert records via a Popup tooltip  ********** */

    const [data,setData] = useState([]);

    useEffect(() =>{
        fetchRows();
    },[])

    const fetchRows = async () =>{
        const rows = await getSimpleRows(tableid,ids);
        setData(rows);
    }

    const url = (id) =>{
        const tempurl  = CreateRecordUrl(
            Global.zoneid,
            blockid,
            tableid,
            id,
            null,
          );
        return tempurl;
    }

    return (
        <div>
            <div>Action records</div>
            {data && data.map(itm=>(<>
                <a href={url(itm.id)} target="new_Window">{itm.fullname}</a>
                <br/>
            </>))}
        </div>
    )
}

export default MultiRowView