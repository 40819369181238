/* eslint-disable */

import React, { useEffect, useState, useContext } from 'react';
import { Dropdown, Icon, Input } from 'semantic-ui-react';
import UserDropdown from '../../../../components/UserDropdown/UserDropdown';
import FilterOperators from './FilterOperators';

const UserFilter = ({ tablename, fieldnode, color, UpdateFilter, remove, filter,blockid }) => {
  
  const [operator, setOperator] = useState(filter.operator ?? 'searchvalues');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [items, setItems] = useState([]);
  const [localFilter, setLocalFilter] = useState(filter);
  const [fOptions ,setFOptions] = useState(null)


useEffect(() =>{
  const FObject = {
    field: fieldnode.data,
    header: fieldnode.header,
    value: [],
    uitype: fieldnode.uitype,
    textvalues:[],
    displayfield: fieldnode.displayfield,
    operator:filter.operator ?? 'searchvalues' }

    if(filter.value !==undefined){
      FObject.value=filter.value;
      FObject.operator=filter.operator;
      FObject.textvalues=filter.textvalues;

    }

    setLocalFilter(FObject)

    let filterOption = [
      { key: 'searchvalues', text: 'Includes (or)', value: 'searchvalues' },
      { key: 'doesnotinclude', text: 'Does not include (or)', value: 'doesnotinclude' },
    ];
  
    if (fieldnode.lookup==='multiple' || (fieldnode.uitype===18 && fieldnode.source.lookup==='multiple')) {
      filterOption.splice(1, 0, { key: 'searchvalues_and', text: 'Includes (and)', value: 'searchvalues_and' });
      filterOption.splice(3, 0, { key: 'doesnotinclude_and', text: 'Does not include (and)', value: 'doesnotinclude_and' });
    }
  
  
    // 6-8-23 we only add these null options to collab fields. the addedby/updatedby will never be non null.
    if(fieldnode.uitype===8 || fieldnode.source.lookupuitype===8) {
      filterOption.push( { key: 'isnull', text: 'Empty (null)', value: 'isnull' })
      filterOption.push( { key: 'isnotnull', text: 'Not empty (not null)', value: 'isnotnull' })
    }

    setFOptions(filterOption);

},[])

  const removeFilter = (newfield) => {
    remove(newfield);
  };

  const updateFilter = (users, tmpoperator) => {

      const textvalues = users.map(el=>{
          return `${el.firstname} ${el.lastname}`
      })

      const uservalues = users.map(el=> {
          return el.userid
      })

      localFilter.value=uservalues;
      localFilter.textvalues=textvalues;
      // localFilter.operator=tmpoperator;

      const filterObject = {
           field: fieldnode.data,
           header: fieldnode.header,
           value: uservalues,
           uitype: fieldnode.uitype,
           textvalues,
           displayfield: fieldnode.displayfield,
           operator: tmpoperator===undefined ? operator : tmpoperator }

           UpdateFilter(localFilter);
    }

    const changeOperator = (newoperator) => {
      setOperator(newoperator)
      localFilter.operator=newoperator;
       UpdateFilter(localFilter);
      // setOperator(newoperator);
      // updateFilter(selectedUsers, newoperator);


    };

  const displayvalue = operator === 'isnull' ? 'Empty (is null)' : 'Not empty (is not null)';

  return (
    
    <div style={{ marginBottom: '23px', width: '100%' }}>
      {fOptions ? (<>
      <FilterOperators
        header={fieldnode.header}
        operator={operator}
        filterOption={fOptions}
        RemoveAction={removeFilter}
        RemoveActionValue={fieldnode.data}
        ChangeOperator={changeOperator}
        color={color}
      />
      {operator === 'isnull' || operator === 'isnotnull' ? (
        <Input
          // label={<div style={{ backgroundColor: color,}} />}
          style={{ width: '100%' }}
          value={displayvalue}
        />
      ) : (
        <UserDropdown
        key={fieldnode.data}
        uitype={fieldnode.uitype!==18 ? fieldnode.uitype : fieldnode.source.lookupuitype}
        selectedUsers={selectedUsers}
        updateFilter={updateFilter}
        color={color}
        lookup="multiple"
        filter={filter}
        tablename={tablename}
        blockid={blockid}
        type={fieldnode.filterType !== undefined ? fieldnode.filterType : "filter"} // 5-20-2020 . Added this param to distiguish whether userdrop is coming from filter or from grid. Different queries are needed depending on action.
      />                                                                           // 6-2-2020. Added "filterType" concept within node so for the "Users in Block" table, the "users" column will specify "zone" indicating to retrieve all users from zone, instead of just from the block(default query)
      )}
      </>): null}
    </div>
  );
};

export default UserFilter;
