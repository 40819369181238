/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Input, Dropdown } from 'semantic-ui-react';

const ActionSchedule = ({ localData, updateData, editMode, sourceNodes }) => {
  const [schedule, setSchedule] = useState({
    number: 0,
    interval: 'days',
    direction: 'after',
    timeField: 'now',
  });
  const [timeFields, setTimeFields] = useState([]);

  useEffect(() => {
    const tFields = [{ key: 'now', value: 'now', text: 'Trigger date', tableid: 0 }];
    sourceNodes.map((node) => {
      node.data?.tableData?.tableinfo?.columns
        .filter(
          (col) => col.uitype === 22 || (col.source?.lookupuitype === 22 && col.source?.lookup==='single') || col.fieldType === 'date',
        )
        .map((el) => {
          const table = node.data.tableData;
          tFields.push({
            key: `${table.id}|${el.data}`,
            value: `${table.id}|${el.data}`,
            text: `${table.name} - ${el.header}`,
            tableid: table.id,
            name: table.name,
            field: el.data,
            header: el.header,
          });
        });
    });
    setTimeFields(tFields);

    if (localData.schedule !== undefined && localData.schedule !==null) {
      console.log('has schedule')
      setSchedule(localData.schedule);
    }
  }, []);

  const updateSchedule = (param, value) => {
    const tempSchedule = structuredClone(schedule);

    tempSchedule[param] = value;
    setSchedule(tempSchedule);

    //If they set number=0, this means remove any schedule.
    if (param === 'number' && value === '0') {
      localData.schedule ={number: 0,interval: 'days', direction: 'after',timeField: 'now'};
    } else {
      localData.schedule = tempSchedule;
    }
    updateData(localData);
  };

  const getValue = (param) => {
    return schedule[param];
  };

  const intervalOptions = [
    { key: 'minutes', value: 'minutes', text: 'Minutes' },
    { key: 'hours', value: 'hours', text: 'Hours' },
    { key: 'days', value: 'days', text: 'Days' },
    { key: 'years', value: 'years', text: 'Years' },
  ];

  const directionOptions = [
    { key: 'before', value: 'before', text: 'Before' },
    { key: 'after', value: 'after', text: 'After' },
  ];

  return (
    <div style={{ border: '1px solid #0D99FF', padding: '20px', backgroundColor: 'white' }}>
      <div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '5px' }}>Timing</div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
        <div style={{ marginRight: '5px', whiteSpace: 'nowrap' }}>Start action</div>
        <div style={{ width: '50px', marginRight: '5px' }}>
          <Input
            fluid
            value={getValue('number')}
            onChange={(e, data) => updateSchedule('number', data.value)}
          />
        </div>
        <div style={{ width: '70px', marginRight: '5px' }}>
          <Dropdown
            selectOnBlur={false}
            value={getValue('interval')}
            options={intervalOptions}
            onChange={(e, data) => updateSchedule('interval', data.value)}
          />
        </div>
        <div style={{ width: '60px', marginRight: '5px' }}>
          <Dropdown
            selectOnBlur={false}
            value={getValue('direction')}
            options={directionOptions}
            onChange={(e, data) => updateSchedule('direction', data.value)}
          />
        </div>
        <div style={{ width: '180px' }}>
          <Dropdown
            selectOnBlur={false}
            value={getValue('timeField')}
            options={timeFields}
            onChange={(e, data) => updateSchedule('timeField', data.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default ActionSchedule;
