/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {Input} from 'semantic-ui-react';
import FilterOperators from './FilterOperators';

const NumberFilter = ({fieldnode,uitype,UpdateFilter,filter,remove,color,isLookup}) => {

    const [operator, setOperator] = useState('minmax');
    const [startNumber, setStartNumber] = useState('');
    const [endNumber, setEndNumber] = useState('');
    const [duration, setDuration] =useState('Interval in hours');

    React.useEffect(() => {

        const filterObject = {
            field: fieldnode.data,
            header: fieldnode.header,
            uitype,
            startNumber,
            endNumber,
            displayfield: fieldnode.displayfield,
            operator,
            isLookup,
        }

        UpdateFilter(filterObject);
        // eslint-disable-next-line
        if(fieldnode.fieldType==='interval' && fieldnode.includeTime) {
            setDuration('Interval in hours')
        } else if(fieldnode.fieldType==='interval' && !fieldnode.includeTime) {
            setDuration('Interval in days');
        }
    }, [operator, startNumber, endNumber]);

    useEffect(() => {
        if (filter.operator !== undefined) {
            setOperator(filter.operator)
            setStartNumber(filter.startNumber)
            if (filter.endNumber !== undefined) {
                setEndNumber(filter.endNumber)
            }
        }
        // eslint-disable-next-line
    }, [])

    let filterOptions = [
        { key: 'minmax', text: 'Min - Max', value: 'minmax' },
        { key: 'gt', text: '> bigger than', value: 'gt' },
        { key: 'lt', text: '< smaller than', value: 'lt' },
        { key: 'eq', text: 'Equals', value: 'eq' },
        { key: 'isnull', text: 'Empty (null)', value: 'isnull' },
        { key: 'isnotnull', text: 'Not empty (not null)', value: 'isnotnull' },
    ]

    if(fieldnode.fieldType==='interval') {
        filterOptions = filterOptions.filter(fld=>fld.value !=='eq');
    }

    const DisplayDateControl = () => {

        if (operator === 'minmax') {
            return (
                <div style={{ display: 'flex', justifyContent: 'space-between',width:'100%' }}>
                    <div>
                        <Input
                            value={startNumber}
                            // style={{ width: '175px' }}
                            onChange={(e, data) => setStartNumber(data.value)} />
                    </div>
                    -
                <div>
                        <Input
                            value={endNumber}
    
                            // style={{ width: '175px' }}
                            onChange={(e, data) => setEndNumber(data.value)} />
                    </div>

                </div>
            )
        } if (operator === 'gt' || operator === 'lt' || operator === 'eq') {
            return (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <Input
                            value={startNumber}
                            
                            style={{ width: '400px' }}
                            onChange={(e, data) => setStartNumber(data.value)} />
                    </div>
                </div>
            )
        }  if (operator === 'isnull' || operator === 'isnotnull') {

            const tmpdisplay = operator === 'isnull' ? 'Empty (is null)' : 'Not empty (is not null)'
           
            return (
                <div>
                    <Input style={{ width: '400px' }} value={tmpdisplay} />
                </div>
            )
        }
        return null;
    }

    return (
      
        <div style={{ marginBottom: '23px', width: '100%' }}>

    <FilterOperators
        header={fieldnode.header}
        operator={operator}
        filterOption={filterOptions}
        RemoveAction={remove}
        RemoveActionValue={fieldnode.data}
        ChangeOperator={setOperator}
        color={color}
      />
            {DisplayDateControl()}
            {fieldnode.fieldType==='interval' ? duration : null}
        </div>
    )

}

NumberFilter.propTypes = {
    uitype: PropTypes.number.isRequired,
    UpdateFilter: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    fieldnode: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    filter: PropTypes.object.isRequired,
    remove: PropTypes.func.isRequired,
}

export default NumberFilter;