/* eslint-disable */
import React from 'react';
import { toast } from 'react-toastify';
import NativeListener from 'react-native-listener';
import { BaseEditorComponent } from '@handsontable/react';
import { Editor } from '@tinymce/tinymce-react';
import { mdiClose } from '@mdi/js';
import DOMPurify from 'dompurify';
import { getUsersAPI } from '../../../../api/users';
import { uploadAnonymousFiles } from '../../../../api/images';
import { AccountContext } from '../../../../providers/AccountProvider';
import TextButton from 'src/components/TextButton/TextButton';
import Global from '../../../../clients/global';
import { generateText } from '../../../../api/ai';
import findUnmatchedTokens from '../common/findUnmatchedTokens';

class RFTEditor extends BaseEditorComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      width: '125px',
      top: 30,
      left: 0,
      display: 'none',
      expandDown: false,
      users: null,
      header: '',
      isChanged: false,
    };

    this.mainElementRef = React.createRef();
    this.editorRef = React.createRef();
  }
  static contextType = AccountContext;

  componentDidMount() { }

  setValue(value, callback) {
    this.setState((state, props) => {
      return { value };
    }, callback);
  }

  fetchUsers = async () => {
    const usr = await getUsersAPI([], false);
    let userMentions = [];
    usr.map((u) => {
      userMentions.push({
        key: u.userid,
        value: `${u.email}`,
        text: `${u.email} (${u.firstname} ${u.lastname})`,
        url: '#',
      });
    });
    this.setState({ users: userMentions });
  };

  getValue() {
    let v='';
    if (this.editorRef.current) {
      v = this.editorRef.current.getContent()
      // console.log(v)
    }
    return v;
  }

  stopEvent = (event) => {
    event.stopImmediatePropagation();
    // event.preventDefault();
  };

  open() {
    this.setState({ display: 'block', isExpanded: false, isChanged: false });
    document.documentElement.addEventListener('mousedown', this.stopEvent, true);
  }

  close() {
    document.documentElement.removeEventListener('mousedown', this.stopEvent, true);
    this.setState({ display: 'none', isExpanded: false });
    this.finishEditing();
  }

  hidePanel = () => {
    
    /**********************
     * RTF CURLY BRACE CHECK
     *  11-15-24 Added logic to check if content has broken tokens {{}}, it won't let them
        save unto the open/closing curly braces match.
     */

    if (this.editorRef.current) {
      const v = this.editorRef.current.getContent()
      const test  = findUnmatchedTokens(v);
      if(test>0) {
        toast.info(<div style={{margin:'10px'}}>There are {test} broken tokens. &#123;&#123;field token&#125;&#125; Please ensure you have the same number of curly brackets on the left and right of the element name.</div>, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose:7000,
        });        
        return false;
      } else {
        this.close();
      }
    }

    
  };

  createMarkup = (html) => {
    DOMPurify.setConfig({ ADD_ATTR: ['target'] });
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  prepare(row, col, prop, td, originalValue, cellProperties) {
    // We'll need to call the `prepare` method from
    // the `BaseEditorComponent` class, as it provides
    // the component with the information needed to use the editor
    // (hotInstance, row, col, prop, TD, originalValue, cellProperties)
    super.prepare(row, col, prop, td, originalValue, cellProperties);

    const datarow = this.props.GetRowData(row);
    let recordname = '';
    let tableRecordName = '';

    if (datarow !== null) {
      recordname = datarow.recordname;
      tableRecordName = datarow.tableRecordName;
    }

    const tdPosition = td.getBoundingClientRect();
    const halfHeight = window.innerHeight * 0.5;
    const expandDown = tdPosition.y < halfHeight;

    this.setState({
      width: cellProperties.width,
      value: originalValue,
      expandDown,
      top: `${tdPosition.top + 2}`,
      left: `${tdPosition.left + 1}px`,
      header: cellProperties.header,
      recordname,
      tableRecordName,
    });

    if (this.state.users == null) {
      this.fetchUsers();
    }
  }


  uploadCallback = (file) => {
    return new Promise((resolve, reject) => {
      let url = '';
      const getimage = async () => {
        const schema = `schema_${Global.zoneid}`;
        const fileUrl = await uploadAnonymousFiles(schema, [file]);
        url = fileUrl[0].gcsurl;
        resolve({ data: { link: url } });
      };
      getimage();
    });
  };

  // eslint-disable-next-line class-methods-use-this
  stopMousedownPropagation(e) {
    e.stopPropagation();
  }

  render() {
    const {
      value,
      width,
      header,
      recordname,
      tableRecordName,
      isChanged,
    } = this.state;
    let renderResult = null;

    if (this.props.isEditor  && this.state.display==='block') {
      renderResult = (
        <NativeListener
          onMouseDown={this.stopMousedownPropagation}
          //   onKeyDown={this.stopMousedownPropagation}
        >
          <div
            style={{
              overflow: 'auto',
              display: this.state.display,
              position: 'absolute',
              left: window.innerWidth / 2 - 400,
              top: window.innerHeight / 2 - 270,
              //   left: this.state.left,
              //   top: !this.state.expandDown ? `${this.state.top - 200}px` : `${this.state.top}px`,
              // background: '#fff',
              padding: '0px',
              zIndex: 999,
              width: '900px',
              minHeight: '500px',
            }}
            id="editorElement"
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: '40px',
                  padding: '5px',
                  backgroundColor: this.props.color,
                  width: '100%',
                  color: 'white',
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: '10px',
                  }}
                >
                  {header} for {tableRecordName}: {recordname}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItem: 'center',
                    paddingLeft: '10px',
                  }}
                >
                  <TextButton
                    textColor="white"
                    hoverColor="#FFFFFF80"
                    icon={mdiClose}
                    iconPosition="right"
                    text="Save and close"
                    Action={this.hidePanel}
                  />
                </div>
              </div>
              <div
                style={{
                  borderLeft: '1px solid #CCCCCC',
                  borderRight: '1px solid #CCCCCC',
                  borderBottom: '1px solid #CCCCCC',
                  borderBottomLeftRadius: '10px',
                  borderBottomRightRadius: '10px',
                }}
              >
                <Editor
                  onInit={(evt, editor) => {
                    this.editorRef.current = editor;
                    this.editorRef.current.focus();
                  }}
                  initialValue={value}
                  init={{
                    removed_menuitems: "newdocument" ,
                    convert_urls:false,
                    formats: false,
                    min_height: 500,
                    max_height:500,
                    auto_focus:true,
                    menubar: true,
                    statusbar: false,
                    promotion: false,
                    branding: false,
                    content_style: "@import url(https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100..900;1,100..900&family=Bungee+Shade&family=Cabin+Sketch:wght@400;700&family=Cabin:ital,wght@0,400..700;1,400..700&family=Caveat:wght@400..700&family=Charm:wght@400;700&family=Creepster&family=Heebo:wght@100..900&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Indie+Flower&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Miniver&family=Monda:wght@400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Moo+Lah+Lah&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oswald:wght@200..700&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&family=Raleway:ital,wght@0,100..900;1,100..900&family=Rampart+One&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Dirt&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap)",
                    font_family_formats: "Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde;Bitter=Bitter;Book Antiqua=book antiqua,palatino;Bungee Shade=Bungee Shade;Cabin=Cabin;Caveat=Caveat;Charm=Charm;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Creepster=Creepster;Georgia=georgia,palatino;Heebo=Heebo;Helvetica=helvetica;IBM Plex Sans=IBM Plex Sans;Impact=impact,chicago;Indie Flower=Indie Flower;Josefin Sans=Josefin Sans;Lato=Lato;Merriweather=Merriweather;Miniver=Miniver;Monda=Monda;Montserrat=Montserrat;Moo Lah Lah=Moo Lah Lah;Noto Sans=Noto Sans;Nunito=Nunito;Open Sans=Open Sans;Oswald=Oswald;Playfair Display=Playfair Display;Poppins=Poppins;PT Sans=PT Sans;Quicksand=Quicksand;Raleway=Raleway;Rampart One=Rampart One;Roboto=Roboto;Rubik Dirt=Rubik Dirt;Source Code Pro=Source Code Pro;Source Sans 3=Source Sans 3;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Ubuntu=Ubuntu;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats;Work Sans=Work Sans;",
                    plugins:
                      'advlist anchor autolink autoresize charmap code directionality emoticons fullscreen help image quickbars importcss insertdatetime link  lists media  nonbreaking pagebreak preview  save searchreplace table visualblocks visualchars wordcount',
                    toolbar:
                      'undo redo | formatselect fontselect fontsizeselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  link | bullist numlist outdent indent | removeformat | customAIButton',
                  quickbars_selection_toolbar: 'bold italic | h1 h2 h3 h4 formatselect | quicklink ',
                  setup: function (editor) {
                    // Add a custom button to the toolbar
                    editor.ui.registry.addButton('customAIButton', {
                      text: 'AI', // Button label
                      onAction: async function () {
                        // Custom action when the button is clicked
                        let content = editor.getContent();
                        const lastIdx = content.lastIndexOf('</p>');
                        const finalString = content.slice(0, lastIdx) + '. return in html' + content.slice(lastIdx);
                     
                        // Do your processing on the content here
                        // For example, let's add "Processed:" before the content
                        const processedContent = await generateText(finalString,false)
                        editor.setContent(processedContent);
                      },
                    });
                  },
                  }}
                />
              </div>
            </div>
          </div>
        </NativeListener>
      );
    } else if (this.props.isRenderer) {
      let markup = <div></div>;
      renderResult = <div></div>;
      if (this.props.value !== undefined && this.props.value !== null && this.props.value !== '') {
        const TagStyle = {
          width: this.props.cellWidth,
          height: this.props.cellHeight - 7,
          // backgroundColor:'#eee',
          float: 'left',
          zIndex: 0,
          whiteSpace: 'wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          lineHeight: '1',
          marginTop: '5px',
          // paddingTop:'5px',
          marginRight: '5px',
          marginLeft: '2px',
        };

        renderResult = (
          <div style={TagStyle}>
            <div
              style={{ minHeight: '30px', width: '100%' }}
              dangerouslySetInnerHTML={this.createMarkup(this.props.value)}
            />
          </div>
        );
      }
    }
    return <>{renderResult}</>;
  }
}

export default RFTEditor;
