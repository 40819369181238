/* eslint-disable */
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ICONS, COLORS } from './constants';
import {plans} from '../Zone/plans';


const DIRECTION = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const calculateLastSort = (blocks) => {
  if (!blocks.length) {
    return 30000;
  }
  const lastIndex = blocks.length - 1;
  let sort = blocks[lastIndex].sort * 2;
  if (blocks.length > 1) {
    sort = blocks[lastIndex].sort + blocks[lastIndex - 1].sort;
  }
  if (sort === blocks[lastIndex].sort) {
    sort = blocks[lastIndex].sort + 30000;
  }
  if (sort < 30000) {
    sort = 30000;
  }
  return sort;
};

export const calculateSort = (blocks, block, direction) => {
  const currentIndex = blocks.findIndex((b) => b.key === block.key);
 
  if (direction === DIRECTION.LEFT && currentIndex > 0) {
    const directionIndex = currentIndex - 1;
    if (directionIndex === 0) {
      return blocks[directionIndex].sort / 2;
    }
    return (
      blocks[directionIndex].sort -
      (blocks[directionIndex].sort - blocks[directionIndex - 1].sort) / 2
    );
  }
  if (direction === DIRECTION.RIGHT && currentIndex < blocks.length) {
    const directionIndex = currentIndex + 1;
    if (directionIndex === blocks.length - 1) {
      return calculateLastSort(blocks);
    }
    const newblockorder =  blocks[directionIndex].sort +
    (blocks[directionIndex + 1].sort - blocks[directionIndex].sort) / 2
    return newblockorder
  }
  return block.sort;
};

export const createNewItem = (isNew=true) => ({
  key: uuidv4(),
  name: 'New Block',
  icon: ICONS[0].path,
  color: COLORS[0],
  isNew: isNew,
});



export const replaceBlock = (blocks, block) => {
  const index = blocks.findIndex((b) => b.key === block.key);
  const newList = [...blocks];
  newList.splice(index, 1, block);
  return newList;
};

export const deleteBlock = (blocks, block) => {
  const index = blocks.findIndex((b) => b.key === block.key);
  const newList = [...blocks];
  newList.splice(index, 1);
  return newList;
};

export const moveBlockToLeft = (blocks, block) => {
  return { ...block, sort: calculateSort(blocks, block, DIRECTION.LEFT) };
};

export const moveBlockToRight = (blocks, block) => {
  return { ...block, sort: calculateSort(blocks, block, DIRECTION.RIGHT) };
};

export const checkSystemErrors = (zoneDetail, blocks) => {
  // check storage limit: zoneDetail.storage > plans.filter(el=>el.type===zoneDetail.plan)[0].data
  // check if billing issue:  zoneDetail.billingIssue
  // check if max rows exceeded in block: blocks.findIndex(itm=>itm.isMaxRowsExceeded===true) !== -1
  // check if Free trial phone number is to expire in less than 7 days.
  
  let errors = []

  if(zoneDetail.attributes && zoneDetail.attributes.PhoneNumbers !==undefined && zoneDetail.plan==='Free') {
    const idx = zoneDetail.attributes.PhoneNumbers.findIndex(el=>el.subscriptionid==='Free' && el.isActive)
    if(idx !== -1) {
      const today = new Date()
      let createdOn = new Date(zoneDetail.attributes.PhoneNumbers[idx].createdOn)
      const Difference_In_Time = today.getTime() - createdOn.getTime();
      const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
      if(Difference_In_Days > 23) {

        createdOn.setSeconds(createdOn.getSeconds() + 2592000)

        errors.push({message:'Time to upgrade! Your 30-day messaging trial phone number is about to expire.',color:'#E06055',textColor:'white',
        tooltip: <div>You can continue to use the phone number we have procured for you until {createdOn.toLocaleDateString()} at {createdOn.toLocaleTimeString()}. At that time, if the Zone owner has not upgraded to a paid plan, this complementary number will be deactivated. If you upgrade while this phone number is still active, you will not need to pay the monthly fee for this number, but you will still need to purchase funds to send/receive text messages. You can choose to upgrade and purchase a new phone number later if desired, but the complementary number will no longer be available for sending or receiving more messages if you do not upgrade before it expires. Learn more: <a href="https://www.graceblocks.com/support-article/messaging-management-and-pricing" target="_blank">messaging pricing</a>.
      </div>})
      }
    }
  }


  if(zoneDetail.storage > plans.filter(el=>el.type===zoneDetail.plan)[0].data || blocks.findIndex(itm=>itm.isMaxRowsExceeded===true) !== -1){
    errors.push({message:'This Zone is over the current billing plan limits.',color:'#E06055',textColor:'white',
    tooltip: <div>The power to solve is even better with an upgrade! One or more Blocks in the Zone has exceeded this plan's set limits for disk space and/or rows of data. If it has not already happened, soon your users will not be able to add more records or attachments in this Block. 
    <p/><p><b>The solution is easy, upgrade this Zone's billing plan now.</b></p>
  </div>})
  }

  if(zoneDetail.billingIssue) {
    errors.push({message:'This Zone\'s payment is past due.',color:'#E06055',textColor:'white',
    tooltip:<div>We ran into a snag processing this months' payment.  Please visit your Zone settings and review the payment information you have on file.  We will attempt to process this payment soon, and hopefully this issue will be resolved. 
    <p/><p><b>This Zone will be deactivated if this is not solved before the next billing cycle.</b></p>
  </div>})
  }

  if(zoneDetail.attributes?.workflow?.totalUsage > .9 && zoneDetail.attributes?.workflow?.totalUsage <1) {
    errors.push({message:'Workflow action count approaching plan limit.',color:'#FFDF91',textColor:'black',
      tooltip: <div>You are approaching the monthly limit of workflows allowed per plan.
      <p/><p><b> Next Reset Date: {zoneDetail.attributes.workflow.resetDate}</b></p>
    </div>})
  }

  if(zoneDetail.attributes?.workflow?.totalUsage > 1 && zoneDetail.plan==='Free') {
    errors.push({message:'Workflow actions have exceeded this plans limit.',color:'#E06055',textColor:'white',
      tooltip: <div>Workflow actions have exceeded this plan's limit.
      <p/><p>Please upgrade to a paid plan to continue using GraceBlocks Workflows.</p>
    </div>})
  }

  if(zoneDetail.attributes?.workflow?.totalUsage > 1 && zoneDetail.plan !=='Free') {
    errors.push({message:'Workflow limits exceeded. Overage charges will be applied.',color:'#E06055',textColor:'white',
      tooltip: <div>Workflow actions have exceeded this plan's limit. 
      <p/><p>Overage charges will be applied. <b> Next Reset Date: {zoneDetail.attributes.workflow.resetDate}</b> </p>
      <p><a href="https://www.graceblocks.com" target="_newwindow">Click to view Workflow support.</a></p>
    </div>})
  }

  return errors

}
