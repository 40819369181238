 /* eslint-disable */
 const findUnmatchedTokens = (str) => {
    console.log(str)

    let count = 0;
    let openBraces = 0;
  
    for (let i = 0; i < str.length; i++) {
      if (str[i] === '{' && str[i + 1] === '{') {
        openBraces++;
      } else if (str[i] === '}' && str[i + 1] === '}') {
        openBraces--;
      }
  
      // Check if we have a closing brace without a matching opening brace
      if (openBraces < 0) {
        count++;
        openBraces = 0; // Reset to avoid further miscounts
      }
    }
  
    // Add any remaining unmatched opening braces
    count += openBraces; 
  
    return count;

  }

export default findUnmatchedTokens