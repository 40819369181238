/* eslint-disable */
import React, { useState,useRef,useEffect } from 'react';
import AceEditor from 'react-ace';
import ReactDOMServer from 'react-dom/server';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import CustomMode from './CustomMode.js';
import JavaScriptMode from './JavascriptMode.js';

import {fullFunctions,workFlowFunctions} from './functions'

const CodeEditor = ({fields,updateFormula,formula,isReadOnly,mode='text',field}) => {
  const editorRef = useRef(null);
  const [text, setText] = useState(formula ?? '');

  const functions = mode==='text' ? fullFunctions.map(el=>el.field) : workFlowFunctions.map(el=>el.field);

  useEffect(() => {
    
      const customMode = mode=='text' ? new CustomMode(fields) : new JavaScriptMode(fields);
      editorRef.current.editor.getSession().setMode(customMode);
      editorRef.current.editor.focus();
    
    // if(mode==='text') {
    //   const customMode = new CustomMode(fields);
    //   editorRef.current.editor.getSession().setMode(customMode);
    //   editorRef.current.editor.focus();

    // } else {
    //   const customMode = new JavaScriptMode(fields);
    //   editorRef.current.editor.getSession().setMode(customMode);
    //   editorRef.current.editor.focus();
    // }

  }, []);

  const handleTextChange = (newText) => {
    setText(newText);
    if(field===undefined) {
      updateFormula(newText);
    } else {
      updateFormula(field,newText);
    }
  };

  const getToolTip =(word) =>{
    let tip = word;
    const evalFunctions = mode==='text' ? fullFunctions : workFlowFunctions;
    const idx = evalFunctions.findIndex(el=>el.field===word)
    if(idx !==-1) {
      const item = evalFunctions[idx].tooltip;
      tip = (
        <div>
          <div style={{color:'green',whiteSpace:'pre-wrap'}}>{item.fn}</div>
          <div style={{whiteSpace:'pre-wrap'}}>{item.text}</div> 
          <div>Example</div>
          <div style={{color:'green',whiteSpace:'pre-wrap'}}>{item.example}</div>
          <div style={{color:'green',whiteSpace:'pre-wrap',overflow:'auto'}}>{item.result}</div>
        </div>)
    }

    const data =ReactDOMServer.renderToString(<div style={{backgroundColor:'#E5EEFF',border:'1px solid #E5EEFF', color:'black',width:'300px',height:'150px',overflow:'auto'}}>{tip}</div>);
    return data;
  }

  const completions = {
    getCompletions: function (editor, session, pos, prefix, callback) {
      if (prefix.length === 0) {
        callback(null, []);
        return;
      }
      let wrappedFields = structuredClone(fields).map(field => `{${field}}`);
      if(mode==='javascript') {
        wrappedFields = structuredClone(fields).map(field => `{{${field}}}`);
      } 
      const keywords =['DISTINCT','WHEN','THEN','END','ELSE','NULL']
      let matchingFunctions = functions.filter((func) => func.startsWith(prefix.toUpperCase() ));
      // if(mode==='javascript') {
      //   matchingFunctions = wfFunctionsOnly.filter((func) => func.startsWith(prefix.toUpperCase() ));
      // }
       const curly = mode==='text' ? '{' : '{{'
       let matchingFields = wrappedFields.filter((field) => field.toLowerCase().startsWith(`${curly}${prefix.toLowerCase()}`));
      //  if(mode==='javascript') {
      //   matchingFields = wrappedFields.filter((field) => field.toLowerCase().startsWith(`{{${prefix.toLowerCase()}`));
      //  }
      
      const matchingKeywords = keywords.filter((field) => field.startsWith(prefix.toUpperCase()));
   
      const customCompletions = [...matchingFunctions, ...matchingFields,...matchingKeywords].map((match) => ({
        caption: functions.includes(match) && !['CASE','CURRENT_TIME','CURRENT_DATE'].includes(match) ? `${match}()` : match,
        value: functions.includes(match) && !['CASE','CURRENT_TIME','CURRENT_DATE'].includes(match)? `${match}(` : match,
        meta: functions.includes(match) ? 'Function' : keywords.includes(match) ? 'Keyword' : 'Field',
        docHTML: getToolTip(match),
      }));
      
      const  filteredCompletions = customCompletions.filter((completion) => functions.includes(completion.value.slice(0,-1)) || wrappedFields.includes(completion.value) || keywords.includes(completion.value) || functions.includes(completion.value) );
      // if(mode==='text') {
      //   filteredCompletions = customCompletions.filter((completion) => functions.includes(completion.value.slice(0,-1)) || wrappedFields.includes(completion.value) || keywords.includes(completion.value) || functions.includes(completion.value) );
      // } 
      // else {
      //   filteredCompletions = customCompletions.filter((completion) => wfFunctionsOnly.includes(completion.value.slice(0,-1)) || wrappedFields.includes(completion.value)  || keywords.includes(completion.value) || wfFunctionsOnly.includes(completion.value));
      // }

      callback(null, filteredCompletions);
    },
  };

  const langTools = ace.acequire('ace/ext/language_tools');
  langTools.setCompleters([completions]);
  return (
    <AceEditor
      ref={editorRef}
      mode={mode}
      theme="monokai"
      value={text}
      onChange={handleTextChange}
      style={{ height: '200px', width: '100%'}}
      editorProps={{ $blockScrolling: true,debug: true }}
      setOptions={{ useWorker: false,readOnly: isReadOnly,fontSize:'14px'}}
      enableBasicAutocompletion={true}
      enableLiveAutocompletion={true}
      
    />
  );
};

export default CodeEditor;
