/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { mdiArrowLeft, mdiBellOutline,mdiPrinter  } from '@mdi/js';
import PulseLoader from 'react-spinners/PulseLoader';
import ReactToPrint from 'react-to-print';
// const PNL = require('google-libphonenumber');
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
import RenderField from './components/RenderField';
import ProgressBar from './components/progressBar';
import { saveFormSubmission } from '../../../../api/forms';
import { uploadAnonymousFiles, deleteAnonymousFile } from '../../../../api/images';
import GBButton from '../../../../components/GBButton/GBButton';
import TextButton from 'src/components/TextButton/TextButton';
import { toast } from 'react-toastify';
import { getLogoPlacement } from './components/getLogoPlacement';
import Footer from './Footer';
import urlParamProcessor from './components/urlParamProcessor';
import filterFields from './components/filterFields';
import DynamicLabels from './components/dynamicLabels';
import { forEach, isEmpty } from 'lodash';


// https://medium.com/@jalalio/dynamic-og-tags-in-your-statically-firebase-hosted-polymer-app-476f18428b8b

const FormView = ({
  currentForm,
  fInfo,
  searchParams,
  executeAndClose,
  url,
  addedby,
  role,
  hiddenFields = {},
  callingComponent,
  currentValues,
}) => {
  const componentRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [formInfo, setFormInfo] = useState(null);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [fieldValues, setFieldValues] = useState({});
  const [hiddenValues, setHiddenValues] = useState(null);
  const [bannerHeight, setBannerHeight] = useState(0);
  const [logoHeight, setLogoHeight] = useState(0);
  const [logoPlacement, setLogoPlacment] = useState(0);
  const [isComplete, setIsComplete] = useState(0);
  const [errors, setErrors] = useState([]);
  const [logoCoords, setLogoCoords] = useState({});
  const [opacity, setOpacity] = useState(1);
  const [nestedLevels, setNestedLevels] = useState({});
  const [completedSubForms, setCompletedSubForms] = useState([]);
  const [rowData, setRowData] = useState(null); // 11-29-23 added to support update of forms. We fetch full row and store all non-form fields in this object to be used by filterFields.
  const [currentDate, setCurrentDate] = useState(null);
  const [hasDynamicLabels, setHasDynamicLabels] = useState(false);
  const [mobileWidth,setMobileWidth]=useState(500)

  // 9-6-23 adding support for calling webform from url/formula Url field button. In this mode,
  //users want to see streamlined version, so remove logos, header, footer, etc.
  //if param "internal" is passed in, then this indicates popup form for internal usage.

  useEffect(() => {
    if (!isEmpty(hiddenFields)) {
      setHiddenValues(hiddenFields);
    }

    if (currentValues !== undefined && currentValues !== null) {
      setFieldValues(currentValues);
    }

    const loadForm = async () => {
      const pageCount = currentForm.columnsettings.filter((el) => el.type === 'pb').length + 1;

      setPages(pageCount);
      setFormInfo(fInfo);

      const bheight =
        parseInt(currentForm.settings.imageHeight) !== 0
          ? (window.innerHeight * currentForm.settings.imageHeight) / 100
          : window.innerHeight * 0.25;
      setBannerHeight(bheight);
      setLogoHeight(window.innerHeight * 0.25);

      let coords = {};

      // If form has logo, get the coords for placement on the form.
      if (currentForm.settings.logo) {
        coords = getLogoPlacement(
          currentForm.settings.logo.placement,
          currentForm.settings.logo.height,
          currentForm.settings.formWidth,
        );
        setLogoCoords(coords);
      }

      if (searchParams !== undefined) {
        await urlParamProcessor(fInfo, searchParams, currentForm, urlParamsSetFieldValues);
      }

      //Change Favicon if external form
      if (searchParams !== undefined && searchParams.internal === undefined) {
        const favico = document.getElementById('favicon');
        const color = encodeURIComponent(currentForm.settings.accentColors.attributes.color);
        const svgpath = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' style='background-color:${color}' viewBox='0 0 24 24'%3E%3Cpath d='${currentForm.settings.icon}'/%3E%3C/svg%3E`;
        favico.href = svgpath;
      }

      // 5-7-2021 adding logic to support auto-expanding of textarea as user types.
      document.addEventListener(
        'input',
        function(event) {
          if (event.target.tagName.toLowerCase() !== 'textarea') return;
          autoExpand(event.target);
        },
        false,
      );

      //If searchParams is not passed, this means we are viewing form inside APP. As such
      // we need to set isloading to false. In ezternal usage, setting isloading=false is done after
      // process url parameters.
      if (searchParams === undefined) {
        setIsLoading(false);
      }

      //12-19-23 set whether any fields are using dynamiclabels so we can skip this call if there are none.
      // console.log(currentForm.columnsettings)
      const q =
        currentForm.columnsettings.findIndex(
          (itm) => itm.content?.includes('[[') || itm.label?.includes('[['),
        ) !== -1;
      setHasDynamicLabels(q);
    };

    loadForm()
    
  }, []);

  const autoExpand = function(field) {
    // Reset field height
    field.style.height = 'inherit';

    // Get the computed styles for the element
    var computed = window.getComputedStyle(field);

    // Calculate the height
    var height = field.scrollHeight;
    // var height = parseInt(computed.getPropertyValue('border-top-width'), 10)
    //             + parseInt(computed.getPropertyValue('padding-top'), 10)
    //             + field.scrollHeight
    //             + parseInt(computed.getPropertyValue('padding-bottom'), 10)
    //             + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

    field.style.height = height + 'px';
  };

  const urlParamsSetFieldValues = async (urlfieldValues, urlHiddenValues, tempRowData = null) => {
    //11-29-23 added tempRowData param, which would be called by prefillForm when the form is an update.
    //when it's an updated, we put all other fields not currently on form, including all read only formula and
    //lookup fields into this tempRowData. This can then be used in the filterFields logic to determine if
    // a field should appear.

    //3-27-24 working on Workflow, a feature we will have is to allow an action to auto-fill
    //a form based on previous record. Therefore we'll need to properly allow for allowing any kind
    //of field to be passed in URL and auto-populate.  This is first step in converting date string
    //to date value.  Will need to process other field formats.
    Object.keys(urlfieldValues).map((key) => {
      const idx = currentForm.columnsettings.findIndex((el) => el.data === key);
      if (idx !== -1) {
        if (currentForm.columnsettings[idx].fieldInfo.uitype === 22) {
          const millisecondsSinceEpoch = Date.parse(urlfieldValues[key]);
          const dateObject = new Date(millisecondsSinceEpoch);
          urlfieldValues[key] = dateObject;
        } else if (currentForm.columnsettings[idx].fieldInfo.uitype===9) {
          urlfieldValues[key] = decodeURIComponent(urlfieldValues[key])
        }
      }
    });


    setFieldValues(urlfieldValues);
    setHiddenValues(urlHiddenValues);
    setRowData(tempRowData);

    //check to see if fields reference dyhnamic labels.
    const getDynamicLabels =
      currentForm.columnsettings.findIndex(
        (itm) => itm.content?.includes('[[') || itm.label?.includes('[['),
      ) !== -1;

    //1-29-24 if form has dynamic labels and is update/url fields passed in, check to see if a field is a single relationsal.
    //if yes, call Dynamaic labeels.This is used when using form update logic and the form has a single relatinoal already selected
    //and references dynamic labels that need to be displayed upon loading of form.

    if (getDynamicLabels) {
      for (const col of Object.keys(urlfieldValues)) {
        const idx = currentForm.columnsettings.findIndex((itm) => itm.data === col);
        if (
          idx !== -1 &&
          currentForm.columnsettings[idx].fieldInfo.uitype === 1 &&
          currentForm.columnsettings[idx].fieldInfo.lookup === 'single'
        ) {
          await DynamicLabels(
            currentForm,
            fInfo,
            currentForm.columnsettings[idx].data,
            urlfieldValues[col],
            setCurrentDate,
          );
        }
      }

      //4-23-24 Process hiddenFields for dynamic values. This use-case is when launching
      //the form from the datalist..we pass hidden relation id .
      for (const col of Object.keys(hiddenFields)) {
        const idx = currentForm.columnsettings.findIndex((itm) => itm.data === col);
        if (
          idx !== -1 &&
          currentForm.columnsettings[idx].fieldInfo.uitype === 1 &&
          currentForm.columnsettings[idx].fieldInfo.lookup === 'single'
        ) {
          const tempValue = { id: [hiddenFields[col]], label: ['testlabel'] };
          await DynamicLabels(
            currentForm,
            fInfo,
            currentForm.columnsettings[idx].data,
            tempValue,
            setCurrentDate,
          );
        }
      }
    }

    setIsLoading(false);
  };

  const updateFieldValue = async (field, value) => {
    const temp = { ...fieldValues };
    if (value === '' || value===null || (Array.isArray(value) && !value.length)) {
      delete temp[field];
    } else {
      temp[field] = value;
    }
    setFieldValues(temp);
 
    const idx = errors.findIndex((el) => el.id === field);
    if (idx !== -1) {
      setErrors(errors.filter((el) => el.id !== field));
    }

    if (hasDynamicLabels) {
      await DynamicLabels(currentForm, fInfo, field, value, setCurrentDate);
    }
  };

  const addSubFormData = (field, subFormData, guid, isNew = true) => {
    if (guid !== null && guid !== undefined && !isNew) {
      const idx = completedSubForms.findIndex((el) => el.guid === guid);
      if (idx !== -1) {
        completedSubForms[idx].subFormData = subFormData;
        setCompletedSubForms(completedSubForms);
      }
    } else {
      completedSubForms.push({ field, subFormData, guid });
      setCompletedSubForms(completedSubForms);
    }
  };

  const removeSubFormData = (guid) => {
    const newForms = completedSubForms.filter((itm) => itm.guid !== guid);
    setCompletedSubForms(newForms);
  };

  //9-27-22 Logic to support filtering of form fields by partial nesting field selections.
  //Here, for every nested field selection, we keep track of all  nested values used in those selections.
  //separately, the filter has info related to the nested level to use for comparison reasons.
  const updatedNestedLevels = (field, level) => {
    let tempNested = structuredClone(nestedLevels);
    if (tempNested[field] === undefined) {
      tempNested[field] = [level];
    } else {
      const idx = nestedLevels[field].findIndex((el) => el.field === level.field);
      if (idx === -1) {
        tempNested[field].push(level);
      } else {
        tempNested[field][idx] = level;
      }
    }

    setNestedLevels(tempNested);
  };

  const handleFileUpload = async (fieldData, newFiles, category, showProgress = null) => {
    const schema = `schema_${formInfo.split('|')[0]}`;

    let newItems = [];

    // Check any upload files don't exceed specified maxSize
    let isFileSizeExceeded = false;
    newFiles.map((el) => {
      if (parseFloat(el.size / 1000000) > parseFloat(currentForm.settings.maxFileSize)) {
        isFileSizeExceeded = true;
      }
    });

    if (isFileSizeExceeded) {
      toast.info(`File cannot exceed ${currentForm.settings.maxFileSize} MB`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
      return false;
    }

    // Check max attachment limit hasn't been exceeded
    if (
      fieldValues[fieldData.id] !== undefined &&
      fieldValues[fieldData.id].length + newFiles.length > fieldData.maxAttachments
    ) {
      toast.info(`You've reached the max number of attachments`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
      return false;
    }

    // only upload the maxattachments specified for this field.
    const fileUrls = await uploadAnonymousFiles(
      schema,
      newFiles.slice(0, fieldData.maxAttachments),
      showProgress,
    );

    fileUrls.map((itm) => {
      newItems.push({
        url: itm.url,
        size: itm.size,
        gcsurl: itm.gcsurl,
        filename: itm.filename,
        guidname: itm.guidname,
        category: category,
        uploadtype: 'desktop',
      });
    });

    function isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }

    const currentFiles = fieldValues[fieldData.id];
    if (currentFiles !== undefined) {
      newItems.map((el) => {
        currentFiles.push(el);
      });
      updateFieldValue(fieldData.id, currentFiles);
    } else {
      updateFieldValue(fieldData.id, newItems);
    }
  };

  const deleteFile = async (field, guidname) => {
    const schema = `schema_${formInfo.split('|')[0]}`;
    let result = await deleteAnonymousFile(schema, guidname);

    if (result === 'success') {
      const tempurls = fieldValues[field];
      const idx = tempurls.findIndex((itm) => itm.guidname === guidname);
      if (idx !== -1) {
        tempurls.splice(idx, 1);
        updateFieldValue(field, tempurls);
      }
    }
  };

  const verifyFields = () => {
    if (searchParams && searchParams.mode === 'preview') {
      return true;
    }
    // Check required fields for fields on current page of form.
    const errors = [];
    const requiredFields = getCurrentPageFields().filter(
      (el) => el.type == 'field' && el.required === true,
    );

    requiredFields.forEach((itm) => {
      const isSubFormCompleted = completedSubForms.findIndex((el) => el.field === itm.id) !== -1;
      if (fieldValues[itm.id] === undefined && !isSubFormCompleted) {
        errors.push({ id: itm.id, msg: 'This field is mandatory.' });
      }
    });

    // Check format of email and url fields.
    const email_url_Fields = getCurrentPageFields().filter(
      (el) => el.type == 'field' && (el.fieldInfo.uitype === 10 || el.fieldInfo.uitype === 11),
    );
    if (email_url_Fields.length > 0) {
      email_url_Fields.forEach((el) => {
        // if thre is a value, check it's valid.
        if (fieldValues[el.id] !== undefined) {
          const test = RegExp(el.fieldInfo.validator, 'i');
          const checkField = test.test(fieldValues[el.id]);

          //9-3-24 added check for empty space in value..invalidate email/url if it has empty space.
          if (!checkField || fieldValues[el.id].includes(' ')) {
            errors.push({
              id: el.id,
              msg:
                el.fieldInfo.uitype === 10
                  ? 'Please enter valid email.'
                  : 'Please enter a valid url.',
            });
          } 
        }
      });
    }

    // check phone number fields. Verify they are real numbers.
    const phone_Fields = getCurrentPageFields().filter(
      (el) => el.type == 'field' && el.fieldInfo.uitype === 9,
    );
    if (phone_Fields.length > 0) {
      phone_Fields.forEach((el) => {
        if (fieldValues[el.id] !== undefined) {
          if (fieldValues[el.id].includes('_')) {
            // added 10-17-22 to deal with use case where they enter 1 number.
            errors.push({ id: el.id, msg: 'Please enter a valid phone number.' });
          } else {
            const number = phoneUtil.parse(fieldValues[el.id], '');
            if (!phoneUtil.isValidNumber(number)) {
              errors.push({ id: el.id, msg: 'Please enter a valid phone number.' });
            }
          }
        }
      });
    }

    // Check shorttext fields maxlength and value is within the defined limit.
    const textFields = getCurrentPageFields().filter(
      (el) => el.type == 'field' && el.fieldInfo.uitype === 2,
    );
    if (textFields.length > 0) {
      textFields.forEach((el) => {
        if (
          fieldValues[el.id] !== undefined &&
          fieldValues[el.id].length > parseInt(el.fieldInfo.maxlength)
        ) {
          errors.push({
            id: el.id,
            msg: `Field cannot exceed ${el.fieldInfo.maxlength} characters.`,
          });
        }
      });
    }

    // 11-8-22 Check numeric fields that have min/max value.
    const minMaxFields = getCurrentPageFields().filter(
      (el) =>
        el.type == 'field' &&
        el.fieldInfo.uitype === 23 &&
        (el.fieldInfo.minValue !== undefined || el.fieldInfo.maxValue !== undefined),
    );

    if (minMaxFields.length > 0) {
      minMaxFields.forEach((el) => {
        if (
          fieldValues[el.id] !== undefined &&
          el.fieldInfo.minValue !== undefined &&
          fieldValues[el.id] < parseFloat(el.fieldInfo.minValue)
        ) {
          errors.push({
            id: el.id,
            msg: `Field value cannot be lower than ${el.fieldInfo.minValue}`,
          });
        }
        if (
          fieldValues[el.id] !== undefined &&
          el.fieldInfo.maxValue !== undefined &&
          fieldValues[el.id] > parseFloat(el.fieldInfo.maxValue)
        ) {
          errors.push({
            id: el.id,
            msg: `Field value cannot be larger than ${el.fieldInfo.maxValue}`,
          });
        }
      });
    }

    setErrors(errors);
    if (errors.length > 0) {
      return false;
    }
    return true;
  };

  const nextPage = () => {
    if (verifyFields()) {
      if (screen.width < mobileWidth) {
        window.scrollTo(0, 0);
      } else {
        document.getElementById('topDiv').scrollTo(0, 0);
      }
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const submitForm = async () => {
    //if preview mode, don't submit.
    if (searchParams && searchParams.mode === 'preview') {
      setIsComplete(true);
      return true;
    }
    
    //7-18-24 adding logic to support workflow formtask. We'll set aid =null, but if it's in searchparams,
    //we set this valuie and pass it along to be processed. 
    let aid=null
    if(searchParams && searchParams.aid !==undefined) {
      aid=searchParams.aid;
    }


    // 10-18-23 implementing logic to support calling subforms from Forms, to allow users to add
    // relational items as part of core form submission.
    if (callingComponent === 'form') {
      if (verifyFields()) {
        executeAndClose(fieldValues, completedSubForms);
        return true;
      }
    }

    if (verifyFields()) {
      const isRelationalRecord = !isEmpty(hiddenFields);
      setIsLoading(true);
      const result = await saveFormSubmission(
        currentForm,
        fieldValues,
        hiddenValues,
        formInfo,
        url,
        addedby,
        isRelationalRecord,
        completedSubForms,
        aid
      );
      if (result.status === 'success') {
        setIsLoading(false);
        setIsComplete(true);
        if (executeAndClose !== undefined && !isRelationalRecord) {
          await executeAndClose(result.id, !result.isDuplicate);
        } else if (executeAndClose !== undefined && isRelationalRecord) {
          await executeAndClose(result); //calling datalist function to associate new relational record.
        }

        //Auto reload form if setting has been defined.
        if (currentForm.settings.autoReload && executeAndClose === undefined) {
          setTimeout(() => window.location.reload(), 5000);
        }
      }
    }

    if (screen.width < mobileWidth && searchParams && searchParams.internal === undefined) {
      window.scrollTo(0, 0);
    } else if (screen.width > mobileWidth && searchParams && searchParams.internal === undefined) {
      document.getElementById('topDiv')?.scrollTo(0, 0);
    }

    if (searchParams && searchParams.close !== undefined) {
      // window.close();
      if (window.opener) {
        window.opener.postMessage('closeWindow', '*');
        window.close();
      }
    }
  };

  /* 8-16-22 new logic to add filter conditions on whether a field should appear or not appear.
   */
  const filterFieldsByConditions = (fields) => {
    //we call function which perfroms checking if fields should be displayed. This is done on
    //a per page basis.
    const finalFields = filterFields(fields, fieldValues, nestedLevels, rowData);

    //Now we need to see if a field was removed..if so, remove the value from fieldvalues.

    fields.map((fld) => {
      const idx = finalFields.findIndex((el) => el.data === fld.data && el.type === 'field');
      if (idx == -1) {
        delete fieldValues[fld.data];
      }
    });

    // return filtered fields
    return finalFields;
  };

  const getCurrentPageFields = () => {
    currentForm.columnsettings = currentForm.columnsettings.filter(
      (col) => !Object.keys(hiddenFields).includes(col.data),
    );

    const allIndexes = currentForm.columnsettings
      .map((col, i) => (col.type === 'pb' ? i : -1))
      .filter((index) => index !== -1);

    if (pages === 1 && !isComplete) {
      const index = currentForm.columnsettings.findIndex((el, index) => el.type === 'thankyou');
      return filterFieldsByConditions(currentForm.columnsettings.slice(0, index));
    } else if (currentPage === 1 && !isComplete) {
      return filterFieldsByConditions(currentForm.columnsettings.slice(0, allIndexes[0]));
    } else if (currentPage < pages && !isComplete) {
      return filterFieldsByConditions(
        currentForm.columnsettings.slice(allIndexes[currentPage - 2], allIndexes[currentPage - 1]),
      );
    } else if (currentPage === pages && !isComplete) {
      const startIndex = allIndexes.reverse()[0];
      const endIndex = currentForm.columnsettings.findIndex((el, index) => el.type === 'thankyou');
      return filterFieldsByConditions(currentForm.columnsettings.slice(startIndex, endIndex));
    } else if (isComplete) {
      const index = currentForm.columnsettings.findIndex((el, index) => el.type === 'thankyou');
      const cols = filterFieldsByConditions(
        currentForm.columnsettings.slice(index + 1, currentForm.columnsettings.length),
      );
      if (cols.length > 0) {
        return filterFieldsByConditions(
          currentForm.columnsettings.slice(index, currentForm.columnsettings.length),
        );
      } else {
        return [
          {
            id: 'rtf:7:13:01 PM',
            type: 'rtf',
            label: 'RTF: thankyou',
            header: 'RTF: 7:13:01 PM',
            content: 'Thank you, your submission was successful.',
          },
        ];
      }
    }
  };

  const getCurrentPageContinueText = () => {
    const allIndexes = currentForm.columnsettings
      .map((col, i) => (col.type === 'pb' ? i : -1))
      .filter((index) => index !== -1);

    return currentForm.columnsettings[allIndexes[currentPage - 1]].button;
  };

  const color =
    currentForm && currentForm.settings.accentColors !== undefined
      ? currentForm.settings.accentColors.attributes.color
      : '#eee';

  const btnTextcolor =
    currentForm && currentForm.settings.accentColors !== undefined
      ? currentForm.settings.accentColors.attributes.text
      : '#FFFFFF';

  return !isLoading && formInfo ? (
    <div
      id="topDiv"
      style={{
        height: callingComponent==='form' ? null : '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        // border:'1px solid green',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor:
          searchParams !== undefined && searchParams.internal === undefined
            ? `${currentForm.settings.accentColors.attributes.color}1A`
            : null,
      }}
    >
      <Helmet>
        <title>
          {currentForm.settings.formTitle ? currentForm.settings.formTitle : currentForm.name}
        </title>
        <meta property="og:image" content={currentForm.settings.ogimage} />
        <meta property="og:title" content={currentForm.settings.socialShareTitle} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="1200" />
      </Helmet>

      {/* 4-24-24 PRINT BUTTON */}
      {currentForm.settings.includePrintButton ? (
      <div
        style={{
          backgroundColor: parseInt(currentForm.settings.imageHeight) !== 0 && searchParams && searchParams?.internal===undefined ? color : 'white',
          width:'100%',
          maxWidth: `${currentForm.settings.formWidth}px`,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          padding: '20px',
          // border:'1px solid red'
        }}
      >
        <ReactToPrint trigger={() => 
       <div>
          <TextButton
                    Action={()=>{}}
                    text="Print"
                    icon={mdiPrinter}
                    iconSize='20px'
                    textColor= {searchParams && searchParams?.internal===undefined ? 'white' : currentForm.settings.accentColors.attributes.color}
                    hoverColor= {searchParams && searchParams?.internal===undefined ? '#FFFFFF80' : `${currentForm.settings.accentColors.attributes.color}80`}
                  />
       </div>} content={() => componentRef.current} />
      </div>
      ): null}
      <div
        ref={componentRef}
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          // border:'1px solid red',
          alignItems: 'center',
          backgroundColor: 'white',
          justifyContent: 'flex-start',
          width: '100%',
          // height:'100vh',
          maxWidth: `${currentForm.settings.formWidth}px`,
          // minWidth: '300px',
        }}
      >
        {/*   {/* WE HAVE LOGO WITH SET PLACEMENT AT TOP/MIDDLE */}
        {currentForm &&
        searchParams &&
        searchParams.internal === undefined &&
        currentForm.settings.logo !== undefined &&
        [5, 6, 7, 8, 9, 10].includes(currentForm.settings.logo.placement) &&
        screen.width > mobileWidth ? (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: window.innerHeight * 0.25,
              width: '100%',
            }}
          >
            <div
              style={{
                zIndex: 40,
                position: 'absolute',
                top: logoCoords.top,
                transform: logoCoords.transform,
                left: logoCoords.left,
                right: logoCoords.right,
              }}
            >
              <img
                height={`${currentForm.settings.logo.height}px`}
                src={currentForm.settings.logo.url}
              />
            </div>
          </div>
        ) : null}
        {/* ******************* */}

        <div
          style={{
            zIndex: 0,
            backgroundColor:
              parseInt(currentForm.settings.imageHeight) !== 0 &&
              searchParams &&
              searchParams.internal === undefined
                ? color
                : 'white',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: bannerHeight,
            overflow: 'hidden',
          }}
        >
          {currentForm.settings.banner !== undefined &&
          searchParams &&
          searchParams.internal === undefined &&
          parseInt(currentForm.settings.imageHeight) !== 0 ? (
            <div>
              <img
                // width="100%"
                // height='auto'
                width={window.innerWidth < mobileWidth ? '100%' : null}
                style={{
                  position: window.innerWidth > mobileWidth ? 'absolute' : null,
                  top: currentForm.settings.banner.top,
                  left: currentForm.settings.banner.left,
                  opacity: currentForm.settings.overlayOpacity / 100,
                }}
                src={currentForm.settings.banner.url}
              />
            </div>
          ) : null}
        </div>

        <div
          style={{
            zIndex: 25,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: logoHeight,
            overflow: 'hidden',
          }}
        >
          {/* LOGO PLACMENT ON MOBILE */}
          {currentForm.settings.logo !== undefined && screen.width < mobileWidth ? (
            <img
              style={{
                zIndex: 20,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
              }}
              height={`${currentForm.settings.logo.height}px`}
              src={currentForm.settings.logo.url}
            />
          ) : null}

          {/* ABSOLUTE POSITIONED LOGO */}
          {currentForm.settings.logo !== undefined &&
          searchParams &&
          searchParams.internal === undefined &&
          currentForm.settings.logo.placement === 1 && screen.width > mobileWidth ? (
            <img
              style={{
                zIndex: 20,
                position: 'absolute',
                top: screen.width < mobileWidth ? 10 : currentForm.settings.logo.top,
                left:
                  screen.width < mobileWidth
                    ? screen.width / 2 - currentForm.settings.logo.width / 2
                    : currentForm.settings.logo.left,
                right: currentForm.settings.logo.right,
                bottom: currentForm.settings.logo.bottom,
              }}
              height={`${currentForm.settings.logo.height}px`}
              src={currentForm.settings.logo.url}
            />
          ) : null}

          {/* 9-5-23 BACK BUTTON. WHEN using webform, the back button is top left of overall screnen
          But when using this from detailview to add relational record, we need to put the back button
          on the form itself. See below where we add it in. 
          */}
          {currentPage > 1 && !isComplete && callingComponent !== 'detailview' ? (
            <div style={{ position: 'absolute', top: 10, left: 10, zIndex: 50 }}>
              <GBButton
                Action={prevPage}
                width="85px"
                icon={mdiArrowLeft}
                iconColor="white"
                iconPosition="left"
                text="Back"
                color="#1A1A1A"
                showDropShadow={false}
                hoverBackgroundColor={`#00000080`}
                iconHoverBackgroundColor={`#00000080`}
              />
            </div>
          ) : null}
        </div>

        {/* 4-7-2021 This Div ensures the form starts at 25% even if they set banner height=0. We need that
        to ensure logo is displayed and form starts at this location. */}
        {searchParams && searchParams.internal === undefined ? (
          <div
            style={{
              zIndex: 0,
              position: 'relative',
              width: `${currentForm.settings.formWidth}px`,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              // height: window.innerHeight*.1,
              marginTop: logoHeight,
              marginRight: '20px',
            }}
          ></div>
        ) : null}

        <div
          style={{
            position: 'relative',
            zIndex: 40,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            // border:'1px solid red',
            width: '100%',
            // height:'100%',
            marginRight: '0px',
            paddingTop: '20px',
            maxWidth:
              currentForm.settings.innerFormWidth !== undefined
                ? `${currentForm.settings.innerFormWidth}px`
                : '600px',
            // minHeight:'55%',
            // minWidth: screen.width-'50px',
            paddingLeft: screen.width > mobileWidth ? '80px' : '20px',
            paddingRight: screen.width > mobileWidth ? '80px' : '20px',
            backgroundColor:
              currentForm.settings.formBackgroundColor !== undefined
                ? currentForm.settings.formBackgroundColor.attributes.color
                : 'white',
          }}
        >
          {/* 9-5-23 Back button if comingn from details page. Need to keep on maine form */}
          {currentPage > 1 && !isComplete && callingComponent === 'detailview' ? (
            <div style={{ position: 'absolute', top: 10, left: 10, zIndex: 50 }}>
              <GBButton
                Action={prevPage}
                width="85px"
                icon={mdiArrowLeft}
                iconColor="white"
                iconPosition="left"
                text="Back"
                color="#1A1A1A"
                showDropShadow={false}
                hoverBackgroundColor={`#00000080`}
                iconHoverBackgroundColor={`#00000080`}
              />
            </div>
          ) : null}

          {/* LOGO AT BOTTOM PLACEMENT LOCATION */}
          {currentForm &&
          currentForm.settings.logo !== undefined &&
          [2, 3, 4].includes(currentForm.settings.logo.placement) &&
          screen.width > mobileWidth ? (
            <div
              style={{
                zIndex: 40,
                position: 'absolute',
                top: logoCoords.top,
                left: logoCoords.left,
                transform: currentForm.settings.logo.placement === 3 ? 'translate(-50%)' : null, //middle
                right: logoCoords.right,
              }}
            >
              <img
                height={`${currentForm.settings.logo.height}px`}
                src={currentForm.settings.logo.url}
              />
            </div>
          ) : null}
          {/* LOGO BOTTOM PLACEMENT LOCATION */}

          {/* SHOW PROGRESS BAR */}
          {currentForm.settings.includeProgressBar && pages > 1 && !isComplete ? (
            <>
              <div
                style={{
                  // border:'1px solid red',
                  position: 'absolute',
                  top:
                    currentForm.settings.logo &&
                    [2, 3, 4].includes(currentForm.settings.logo.placement)
                      ? currentForm.settings.logo.height / 2
                      : 30,
                  right: 10,
                }}
              >
                <ProgressBar currentPage={currentPage} pages={pages} color={color} />
              </div>

              <div style={{ height: '20px', paddingTop: '30px' }} />
            </>
          ) : null}

          {/* dealing with logo at bottom */}
          {currentForm.settings.logo !== undefined ? (
            <div
              style={{
                height: '20px',
                paddingTop:
                  currentForm.settings.logo &&
                  [2, 3, 4].includes(currentForm.settings.logo.placement) &&
                  screen.width > mobileWidth
                    ? `${currentForm.settings.logo.height / 2}px`
                    : '30px',
              }}
            />
          ) : null}

          {/* NO PROGRESS BAR BUT LOGO EXISTS AND IN BOTTOM PLACEMENT */}
          {/* {!currentForm.settings.includeProgressBar && currentForm.settings.logo !==undefined ? (
            <div style={{ height: '20px', paddingTop:
                  currentForm.settings.logo &&
                  [2, 3, 4].includes(currentForm.settings.logo.placement) && screen.width>500
                    ? `${currentForm.settings.logo.height / 2}px`
                    : '30px', }} />
          ) : null} */}

          {/*---------------------- BEGINGING OF FIELD OUTPUT----------------------- */}

          {/* Insert First Tracking pixel(s) if not in preview mode and has value */}
          {searchParams &&
          searchParams.mode === undefined &&
          currentForm.settings.firstTrackingPixel !== undefined
            ? currentForm.settings.firstTrackingPixel.map((image) => (
                <img src={image} width="1" height="1" border="0" />
              ))
            : null}

          <TransitionGroup>
            {/* <div style={{height:'600px'}}> */}
            {getCurrentPageFields().map((itm, i) => (
              <CSSTransition key={itm.id} timeout={500} classNames="formitem">
                <div
                  key={itm.id}
                  style={{
                    maxWidth:
                      currentForm.settings.innerFormWidth !== undefined
                        ? `${currentForm.settings.innerFormWidth}px`
                        : '600px',
                    minWidth: '250px',
                    display: 'flex',
                    flexDirection: 'column',
                    // backgroundColor: 'white',
                    backgroundColor:
                      currentForm.settings.formBackgroundColor !== undefined
                        ? currentForm.settings.formBackgroundColor.attributes.color
                        : 'white',
                  }}
                >
                  {itm.type === 'field' ? (
                    <>
                      <RenderField
                        id={itm.id}
                        value={fieldValues[itm.id]}
                        // urlvalue={searchParams && searchParams[itm.id]}
                        labelsInCaps={currentForm.settings.labelsInCaps}
                        formInfo={fInfo}
                        tableid={currentForm.tableid}
                        blockid={currentForm.blockid}
                        error={
                          errors.findIndex((el) => el.id === itm.id) !== -1
                            ? errors.filter((el) => el.id === itm.id)[0].msg
                            : ''
                        }
                        uploadDataFiles={handleFileUpload}
                        deleteFile={deleteFile}
                        isEditMode={false}
                        fieldData={itm}
                        updateFieldValue={updateFieldValue}
                        updatedNestedLevels={updatedNestedLevels}
                        color={color}
                        textColor={
                          currentForm.settings.textColor !== undefined
                            ? currentForm.settings.textColor.attributes.color
                            : '#1A1A1A'
                        }
                        fontSize={currentForm.settings.fontSize}
                        fontFamily={currentForm.settings.font}
                        role={role}
                        addedby={addedby}
                        htmlMode={callingComponent === 'Grid' || callingComponent === 'form'}
                        index={i}
                        addSubFormData={addSubFormData}
                        removeSubFormData={removeSubFormData}
                        completedSubForms={completedSubForms.filter(
                          (el) => el.field === itm.fieldInfo.data,
                        )}
                      />
                    </>
                  ) : null}

                  {itm.type === 'h1' || itm.type === 'h2' || itm.type === 'h3' ? (
                    <>
                      {itm.type === 'h1' ? (
                        <h1
                          style={{
                            fontFamily: currentForm.settings.font,
                            color:
                              currentForm.settings.textColor !== undefined
                                ? currentForm.settings.textColor.attributes.color
                                : '#1A1A1A',
                          }}
                        >
                          {itm.label}
                        </h1>
                      ) : null}
                      {itm.type === 'h2' ? (
                        <h2
                          style={{
                            fontFamily: currentForm.settings.font,
                            color:
                              currentForm.settings.textColor !== undefined
                                ? currentForm.settings.textColor.attributes.color
                                : '#1A1A1A',
                          }}
                        >
                          {itm.label}
                        </h2>
                      ) : null}
                      {itm.type === 'h3' ? (
                        <h3
                          style={{
                            fontFamily: currentForm.settings.font,
                            color:
                              currentForm.settings.textColor !== undefined
                                ? currentForm.settings.textColor.attributes.color
                                : '#1A1A1A',
                          }}
                        >
                          {itm.label}
                        </h3>
                      ) : null}
                    </>
                  ) : null}

                  {itm.type === 'rtf' ? (
                    <div
                      style={{
                        color:
                          currentForm.settings.textColor !== undefined
                            ? currentForm.settings.textColor.attributes.color
                            : '#1A1A1A',
                      }}
                      dangerouslySetInnerHTML={{ __html: itm.content }}
                    />
                  ) : null}
                </div>
              </CSSTransition>
              // --------------------------END OF FIELD OUTPUT -------------------------------------
            ))}

            {/* </div> */}
          </TransitionGroup>
          {currentPage < pages ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                marginBottom: '20px',
              }}
            >
              <GBButton
                width={screen.width > mobileWidth ? '400px' : '90%'}
                height="35px"
                fontSize="17px"
                Action={nextPage}
                text={getCurrentPageContinueText()}
                color={color}
                textColor={btnTextcolor}
                textHoverColor={btnTextcolor === 'white' ? '#FFFFFF80' : '#00000080'}
              />
              {searchParams && searchParams.mode !== undefined ? (
                <div style={{ color: '#E06055', fontWeight: 'bold' }}>
                  {' '}
                  🔔 You are viewing this form in Preview mode. This form can not be submitted. You
                  can remove "&mode=preview" from the URL address to access the live form. 🔔
                </div>
              ) : null}
            </div>
          ) : null}
          {currentPage === pages && !isComplete && !currentForm?.settings?.hideFinishButton ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                marginBottom: '20px',
              }}
            >
              <GBButton
                height="35px"
                width={screen.width > mobileWidth ? '400px' : '90%'}
                fontSize="17px"
                Action={submitForm}
                text={currentForm.columnsettings.filter((el) => el.type === 'thankyou')[0].button}
                color={color}
                textColor={btnTextcolor}
                textHoverColor={btnTextcolor === 'white' ? '#FFFFFF80' : '#00000080'}
              />

              {searchParams && searchParams.mode !== undefined ? (
                <div style={{ color: '#E06055', fontWeight: 'bold' }}>
                  {' '}
                  🔔 You are viewing this form in Preview mode. This form can not be submitted. You
                  can remove "&mode=preview" from the URL address to access the live form. 🔔
                </div>
              ) : null}
            </div>
          ) : null}

          {isComplete ? (
            <div style={{ paddingBottom: '500px', backgroundColor: 'white' }}>
              {/* Insert Last Tracking pixel(s) if not in preview mode and has value */}
              {searchParams &&
              searchParams.mode === undefined &&
              currentForm.settings.lastTrackingPixel !== undefined
                ? currentForm.settings.lastTrackingPixel.map((image) => (
                    <img src={image} width="1" height="1" border="0" />
                  ))
                : null}

              {/* <button>Close</button> */}
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              {callingComponent === 'detailview' || callingComponent==='SingleDetail' ? (
                <div>
                  <TextButton
                    Action={executeAndClose}
                    text="Cancel"
                    textColor="black"
                    hoverColor="#00000080"
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>

      {/* This div provides the extra whitespace after conrols to bottom of page. This way, even with 1 or 2 controls, whitespace is full */}
      {callingComponent !== 'detailview' && searchParams && searchParams.internal === undefined ? (
        <div
          style={{
            display: 'flex',
            height: '100%',
            backgroundColor: 'white',
            width: screen.width < mobileWidth ? '100%' : `${currentForm.settings.formWidth}px`,
          }}
        ></div>
      ) : null}

      {(currentForm.settings.includeGraceBlocksLogo || currentForm.settings.footerText) &&
      searchParams &&
      searchParams.internal === undefined ? (
        <Footer
          footerColors={currentForm.settings.footerColors}
          footerText={currentForm.settings.footerText}
          includeLogo={currentForm.settings.includeGraceBlocksLogo}
          width={currentForm.settings.formWidth}
          login={currentForm.settings.login}
        />
      ) : null}
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <PulseLoader />
    </div>
  );
};

export default FormView;
